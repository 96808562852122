mat-icon.mat-icon {
    flex: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    min-width: unset;
    height: 2.4rem;
    min-height: unset;
    line-height: unset;

    svg {
        width: 100%;
        min-width: unset;
        height: 100%;
        min-height: unset;
    }
}