#main {
    background-color: #E8EDF6 !important;
}

body.activities content {
    background: none;
}

body.activities #container-3 > content {
    overflow: auto;
    flex: 1;
}