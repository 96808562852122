.achievement-dialog,
.openbadge-dialog, {

    .mat-mdc-dialog-surface {

        .mat-mdc-dialog-title {
            padding: 10px 20px;
            margin: 0;

            button[mat-dialog-close] {
                @include button(
                    $type: secondary,
                    $button-bordered: true,
                );
            }
        }

        .mat-mdc-dialog-content {
            padding: 20px;
        }

        .mat-mdc-dialog-actions {
            margin: 0;
        }
    }
}