app-lesson-card-breadcrumb {

    .lesson-card-breadcrump__nav {
        background-color: $primary-c;
        color: $default-font-contrast-color;
        font-weight: 700;

        .lesson-card-breadcrump__nav__list {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin: unset; // Unset browser default list style
            padding: 1.5rem 2rem;
            list-style-type: none; // Remove browser default list style

            .lesson-card-breadcrump__nav__item {
                display: flex;
                align-items: center;
                gap: 0.5rem;

                .lesson-card-breadcrump__nav__item__separator {}

                .lesson-card-breadcrump__nav__link {
                    color: inherit;
                    text-decoration: underline;
                }
            }
        }
    }
}