app-home-page {

    .home-page-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch !important; // Override Bootstrap style
        gap: 2rem;
        padding: 3rem 4rem;
        color: #364F6B;

        .home-page__header {
            order: 2;
            overflow: hidden;
            width: unset; // Unset default theme style
            max-width: 120rem;
            margin: unset; // Unset default theme style
            border-radius: 8px;

            .home-page__header-banner,
            .home-page__header-content {
                height: unset; // Unset default theme style
                max-height: unset; // Unset default theme style
                margin: unset; // Unset default theme style
                border-radius: unset; // Unset default theme style
            }

            .home-page__header-banner {
                position: absolute;
                inset: 0;
                background-position: 70% 50%;
            }

            .home-page__header-content {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                padding: 2rem 35% 2rem 3rem;
                background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%); 

                .home-page__header-title {
                    display: unset; // Unset default theme style
                    color: white;
                    font-size: 2.2rem;
                    text-align: unset; // Unset default theme style
                    letter-spacing: unset; // Unset default theme style

                    span:first-child {
                        display: inline-block;

                        &::first-letter {
                            text-transform: capitalize;
                        }
                    }

                    &::after {
                        content: ' !';
                    }
                }

                .home-page__header-text {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    p {
                        margin: unset; // Unset default theme style
                        color: white;
                        font-size: 1.4rem;
                        font-weight: 400;
                        line-height: 1.2;
                        letter-spacing: unset; // Unset default theme style
                    }
                }
            }
        }

        .home-page__collection-title {
            order: 1;
            margin: unset;
            font-size: 2.2rem;
            font-weight: 700;
        }

        .home-page__collections {
            order: 3;
            display: grid !important;
            grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
            gap: 2rem;
            width: unset; // Unset default theme style
            height: unset; // Unset default theme style
            margin-top: 1rem;

            @include media-breakpoint(gt-xs) {
                grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));
            }

            @include media-breakpoint(gt-sm) {
                grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
            }

            @include media-breakpoint(gt-md) {
                grid-template-columns: repeat(auto-fill, minmax(45rem, 1fr));
            }

            .home-page__collection-card-wrapper {
                display: contents;

                .home-page__collection-card {
                    height: unset; // Unset default theme style
                    background-color: $off-white;
                    color: #364F6B;
                    border-radius: 4px;
                    box-shadow: 0px 12px 40px -25px rgba(0, 0, 0, 0.5);

                    .home-page__collection-card-header {
                        display: flex;
                        align-items: center;
                        gap: 1.5rem;
                        padding: 0 1.5rem;
                        background-color: #364F6B;
                        border-top-left-radius: inherit;
                        border-top-right-radius: inherit;

                        .mat-mdc-card-header-text {
                            display: contents;

                            .mat-mdc-card-title {
                                display: contents;

                                .home-page__collection-card-image {
                                    position: relative;
                                    bottom: 1rem;
                                    width: 5rem;
                                    height: auto;
                                    aspect-ratio: 1;
                                    margin: unset; // Unset default theme style
                                    border: 2px solid #364F6B;
                                }

                                .home-page__collection-card-title {
                                    margin: unset; // Unset default theme style
                                    padding: 1.5rem 0;
                                    font-size: 1.6rem;
                                    font-weight: 700;
                                }
                            }
                        }
                    }

                    .home-page__collection-card-content-wrapper {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start !important; // Override Bootstrap style
                        align-items: stretch !important; // Override Bootstrap style
                        gap: 1.5rem;
                        padding: 1.5rem;

                        .home-page__collection-card-content {
                            font-size: 1.4rem;
                            font-weight: 400;
                            line-height: 1.2;
                        }

                        .home-page__collection-card-actions {
                            display: flex;
                            justify-content: flex-start !important; // Override Bootstrap style
                            min-height: unset; // Unset Material style

                            .home-page__collection-card-button {
                                @include button(
                                    $type: tertiary,
                                    $button-bordered: true,
                                );

                                span {
                                    display: inline-block;

                                    &::first-letter {
                                        text-transform: capitalize;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}