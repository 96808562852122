@use '@angular/material' as mat;

// --------------------------------------------------
// STYLE
// --------------------------------------------------

html {
    font-size: $root-em; // Root element font-size
}

body {
    color: $default-font-color;
    font: mat.get-theme-typography($theme, body-1); // Emit all CSS styles of the body-1 typography level from Material theme
}

button {
    font: mat.get-theme-typography($theme, button); // Emit all CSS styles of the button typography level from Material theme
    cursor: pointer;
}

// --------------------------------------------------
// LAYOUT
// --------------------------------------------------

content,
.content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

router-outlet + *:not(router-outlet) { // Target dynamically loaded components
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    overflow: auto;
}