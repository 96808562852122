// --------------------------------------------------
// DEFAULT STYLE
// --------------------------------------------------

.mat-mdc-tab-header {

    .mat-mdc-tab-link-container,
    .mat-mdc-tab-label-container {

        .mat-mdc-tab-list {

            .mat-mdc-tab-links,
            .mat-mdc-tab-labels {
                gap: 6rem;

                .mdc-tab {
                    flex: none;
                    min-width: unset; // Unset Material style
                    height: unset; // Unset Material style
                    padding: 0.5rem;

                    &:hover,
                    &:active {
                        text-decoration: none;
                    }

                    .mdc-tab__content {                        

                        .mdc-tab__text-label {
                            color: $primary-c;
                            font-size: 1.8rem;
                            font-weight: 700;
                            line-height: $default-line-height;
                            letter-spacing: normal;
                            text-transform: uppercase;
                        }
                    }

                    .mdc-tab-indicator {                        

                        .mdc-tab-indicator__content--underline {
                            border-top: 4px solid $accent-c;
                        }
                    }
                }
            }
        }
    }
}

// --------------------------------------------------
// CONTEST MODULE TAB GROUP MIXIN
// --------------------------------------------------

@mixin contest-tab-group {

    .mat-mdc-tab-header {
        overflow: visible;
        
        .mat-mdc-tab-link-container,
        .mat-mdc-tab-label-container {
            overflow: inherit;

            .mat-mdc-tab-list {

                .mat-mdc-tab-links,
                .mat-mdc-tab-labels {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 2rem;
                    
                    .mdc-tab {
                        padding: 0.5rem 2rem;
                        color: $default-font-contrast-color;
                        background-color: $contest-tabs__link--background-color;
                        opacity: 1;
                        border: 1px solid rgba(0, 0, 0, 0.10);
                        border-radius: 8px;
                        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25) !important;
                        transform: skewX(-10deg); 

                        &.mdc-tab--active {
                            background-color: $contest-tabs__link--background-color--active;
                            border: 3px solid $contest-tabs__link--border-color--active;
                            box-shadow: none !important;
                        }

                        .mdc-tab__content {                        

                            .mdc-tab__text-label {
                                color: $default-font-contrast-color;
                                font-size: 2.7rem;
                                font-weight: 900;
                                line-height: normal;
                                text-transform: none;
                            }
                        }

                        .mdc-tab-indicator {                        

                            .mdc-tab-indicator__content--underline {
                                border-top: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}