// --------------------------------------------------
// MIXINS
// --------------------------------------------------

@mixin contest-groups__progress-bar {
    appearance: none;
    height: 12px;
    background-color: transparent;
    border: 3px solid $contest--primary-color;
    border-radius: 100px;

    &::-webkit-progress-bar, { // Chrome & Safari styling (non-standard feature)
        background-color: transparent;
    }

    &::-moz-progress-bar { // Firefox styling (non-standard feature)
        background-color: $contest--primary-color;
    }

    &::-webkit-progress-value { // Chrome & Safari styling (non-standard feature)
        background-color: $contest--primary-color;
    }
}

@mixin contest-groups__progress-count {
    font-size: 1.6rem;
    font-weight: 700;
    text-align: right;
}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-contest-groups {

    .contest-groups__wrapper {
        display: flex;
        flex-direction: column;
        padding: 10px 20px 0 20px;
        background-image: $contest__content--background-image;
        background-position: 0% 30%;
        background-size: 300%;
        border-radius: 10px;
        box-shadow: $contest__wrapper--box-shadow;

        @include media-breakpoint(gt-xs) {
            gap: 10px;
            padding: 20px 40px 0 40px;
        }

        .contest-groups__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .contest-groups__title {
                margin: 0;
                color: $default-font-contrast-color;
                font-weight: 700;
            }

            .contest-groups__add-remove-button {
                @include button(
                    $type: secondary,
                    $button-bordered: true,
                );
            }
        }

        .contest-groups__table {
            width: 100%;
            margin: 0;
            border-collapse: separate;
            border-spacing: 0 10px;
            background-color: transparent;
            box-shadow: none;

            tbody {

                &:before {
                    content: none;
                }
            }

            .contest-groups__table-row {
                position: relative;
                background: unset;
                border-radius: 5px;

                &.contest-groups__table-row--expanded {
                    
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: 3px solid $contest--primary-accent-color;
                        border-radius: 5px;
                    }

                    .contest-groups__name-cell {

                        mat-icon {
                            transform: rotate(180deg);
                        }
                    }
                }

                .contest-groups__table-cell {
                    padding: 15px 10px;
                    color: $contest--font-color;
                    background-color: $contest--background-color;
                    line-height: normal;

                    &:first-child {
                        padding-left: 20px;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }

                    &:last-child {
                        padding-right: 20px;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }

                    &.contest-groups__name-cell {

                        mat-icon {
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            vertical-align: middle;
                            margin-right: 20px;
                            color: $contest--primary-accent-color;
                            transition: transform 200ms ease-in-out;
                        }

                        span {
                            vertical-align: middle;
                        }
                    }

                    &.contest-groups__progress-bar-cell {
                        width: 40%;

                        .contest-groups__progress-bar {
                            @include contest-groups__progress-bar;
                            width: 100%;
                        }
                    }

                    &.contest-groups__progress-count-cell {
                        @include contest-groups__progress-count;
                    }

                    &:not(.contest-groups__progress-bar-cell) {
                        width: 20%;
                    }
                }
            }

            .contest-groups__detail-row {
                height: 0;
                padding: 0;
                transform: translateY(-10px);
                cursor: auto;

                .contest-groups__detail-cell {
                    padding: 0 20px;

                    .contest-groups__detail-wrapper {
                        overflow: hidden;
                        background-color: $contest--primary-color;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;

                        .contest-groups__detail-list {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            width: 100%;
                            padding: 20px;

                            .contest-groups__detail-list-item {
                                width: 100%;
                                height: unset;
                                padding: 10px 20px;
                                color: $contest--font-color;
                                background-color: $contest--background-color;
                                border-radius: 5px;
                                font-size: 1.4rem;
                                line-height: normal;

                                .mdc-list-item__content {

                                    .mdc-list-item__primary-text {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        gap: 20px;
                                        width: 100%;
                                        color: inherit;
                                        font-size: inherit;

                                        .contest-groups__learner-label,
                                        .contest-groups__learner-progress-count {
                                            width: 25%;
                                        }

                                        .contest-groups__learner-progress-bar {
                                            @include contest-groups__progress-bar;
                                            width: 50%;
                                        }

                                        .contest-groups__learner-progress-count {
                                            @include contest-groups__progress-count;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }             
            }
        }
    }  
}