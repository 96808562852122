app-contest-list {
    color: $contest--font-color;
    background-color: $contest--background-color;

    .contest-list__wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        max-width: $contest__wrapper--max-width;
        margin: 0 auto;
        padding: 20px;

        @include media-breakpoint(gt-xs) {
            gap: 40px;
            padding: 40px;
        }

        .contest-list__title {
            margin: 0;
            font-weight: 700;
        }
    }
}