app-button-help {
    display: contents;

    .help-button {

        &:is(.mat-mdc-raised-button) { // Increase specificty to override Material style
            @include button(
                $type: secondary,
                $size: s,
                $button-bordered: true,
            );
            flex: none;
            height: unset;
        }

        &::after {
            content: '?';
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.5rem;
            height: auto;
            aspect-ratio: 1;
            border-radius: 50%;
            background-color: $white;
            color: $secondary-c;
        }
    }
}