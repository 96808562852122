app-open-badges-list {
    display: contents;

    .open-badges {
        @include my-profile__child-section(
            $my-profile__child-title: '.open-badges__title',
            $my-profile__child-list: '.open-badges__list',
            $my-profile__child-card: '.open-badges__card',
            $my-profile__child-card--locked: '.open-badges__card--locked',
            $my-profile__child-locked-card-icon: '.open-badges__locked-card-icon',
        );

        .open-badges__title {}

        .open-badges__list {
            grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
            gap: 2rem;

            .open-badges__card {
                max-width: 18rem;
                aspect-ratio: 1;
                background-size: cover;
                background-position: center;
                border-radius: 12px;

                &.open-badges__card--locked {

                    &::before {
                        backdrop-filter: blur(1.5px);
                    }

                    .open-badges__locked-card-icon {
                        width: 6rem;
                    }
                }
            }
        }
    }
}