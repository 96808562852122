.mat-mdc-menu-panel {
    margin-top: 5px;
    background-color: $primary-c;
    border-radius: 5px !important; // Override Material style
    box-shadow: 0px 4px 10px 0px rgba($black, 0.25);

    .mat-mdc-menu-content {
        padding: unset !important; // Override default theme style

        .mat-mdc-menu-item {
            @include button(
                $type: primary,
                $button-bordered: true,
            );
            justify-content: space-between;
            width: 100%;
            border-radius: unset;
            box-shadow: unset;
            transform: none !important;

            &:first-child {
                border-top-left-radius: inherit;
                border-top-right-radius: inherit;
            }

            &:last-child {
                border-bottom-right-radius: inherit;
                border-bottom-left-radius: inherit;
            }

            .mat-icon {
                order: 2;
                margin: unset;
            }

            .mat-mdc-menu-item-text {
                order: 1;
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
            }
        }
    }
}