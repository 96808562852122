.app-demo-choice {
    flex-direction: column;
}
.app-demo-choice {
    &__header {
        flex: 1;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 3% 0;
        text-align: center;
        font-style: italic;

        &__title {
            font-size: 2em;
            font-weight: bold;
            font-style: italic;
            margin: 0 0 1rem;
            width: 60%;
        }

        &__sub-title {
            font-size: 1.2em;
            margin: 0 0 1rem;
            width: 60%;
            max-width: 520px;
        }
    }

    &__content {
        flex: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5%;

        .grid {
            @include grid(
                $xs-col: 1,
                $xs-row: 1,
                $sm-row: 1,
                $lg-row: 1,
                $xl-col: 3,
                $xl-row: 1,
                $gap: 22);
        }
    }

    &__article-junior,
    &__article-college,
    &__article-python {
        position: relative;
        flex: 1;
        max-width: 280px;
        min-width: 150px;
        text-align: center;
        margin: 10px 10px 40px 10px;
        min-height: 30vh;
        padding: 0 15px 50px 15px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 4.419px 8.838px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        align-items: center;

        &:before {
            content: "";
            display: block;
            width: calc(100% + 20px);
            aspect-ratio: 1.2;
        }

        @include media-breakpoint-up("lg") { // > 1280
            //padding-top: calc(100vw / 6);
        }

        &:before {
            content: "";
            display: block;
            width: calc(100% + 20px);
            aspect-ratio: 1.2;
        }

        @include media-breakpoint-up("lg") { // > 1280
            //padding-top: calc(100vw / 6);
        }

        mat-icon {
            position: absolute;
            width: calc(100% + 20px);
            height: auto;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
            background-size: cover;
        }

        h1 {
            font-size: 1.5em;
            margin-top: 60px;
            margin-bottom: 10px;
        }

        p.app-demo-choice__article__description {
            margin: 0 0 10px 0;
            color: var(--Typo-fonc, #3A4354);
            font-size: 1.4rem;
            font-weight: 700;
        }

        p.app-demo-choice__article__suggestedAge {
            display: inline-block;
            color: white;
            font-weight: bold;
            padding: 7px 14px;
            border-radius: 2em;
            background: var(--blue, #3FC1C9);
            margin: 0 0 10px 0;
        }

        button {
            @include button('tertiary');
            position: absolute;
            bottom: -20px;
            left: 50% !important;
            transform: translateX(-50%) !important;
            width: max-content;
            border-radius: 2rem;
            font-size: 1.8rem;

        }
    }
}