// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------

/**
 * For smaller components, there is the components/ folder. While layout/ is macro (defining the global wireframe), components/ is more focused on widgets. It contains all kind of specific modules like a slider, a loader, a widget, and basically anything along those lines. There are usually a lot of files in components/ since the whole site/application should be mostly composed of tiny modules.
*/

@import "components/contest-card";
@import "components/contest-groups";
@import "components/contest-header";
@import "components/contest-ranking";
@import "components/contest-tabs";
@import "components/contest-timer";
@import "components/registration-dialog";

// --------------------------------------------------
// PAGES
// --------------------------------------------------

/**
 * If you have page-specific styles, it is better to put them in a pages/ folder, in a file named after the page. For instance, it’s not uncommon to have very specific styles for the home page hence the need for a _home.scss file in pages/.
*/

@import "pages/contest-detail";
@import "pages/contest-lessons";
@import "pages/contest-list";