app-card {

    &.card-split-media-info {

        // --------------------------------------------------
        // COMMON STYLE
        // --------------------------------------------------

        .mat-mdc-card {
            height: 100%; // Override default theme style
            min-height: unset; // Unset default theme style

            &::after {
                content: none; // Remove pseudo-element declared in default theme
            }

            .mat-mdc-card-header {
                z-index: unset; // Unset default theme style
                min-height: unset; // Unset default theme style

                .mat-mdc-card-header-text {
                    display: contents;

                    .mat-mdc-card-title {
                        display: contents;

                        h3 {
                            margin: unset; // Unset browser default style
                        }
                    }
                }
            }

            .mat-mdc-card-image {
                position: unset; // Unset default theme style
            }

            .mat-mdc-card-content {
                min-height: unset; // Unset default theme style
                margin: unset; // Unset default theme style

                .dl-list {
                    flex: unset !important; // Unset inline style
                    margin: unset; // Unset browser default style

                    dt,
                    dd {

                        &::before,
                        &::after {
                            content: none; // Remove <dt> and <dd> elements declared in default theme
                        }
                    }
                }
            }

            // --------------------------------------------------
            // LEARNER STYLE
            // --------------------------------------------------

            &.card--learner-user {
                position: relative;
                overflow: visible; // Override default theme style
                display: grid;
                grid-template-columns: 1fr 2fr;
                grid-template-rows: auto 1fr auto;
                align-items: start;
                column-gap: 4rem;
                max-width: 100rem;
                padding: 2rem;
                background-color: $primary-c;
                color: $default-font-contrast-color;
                border-radius: 18px;
                box-shadow: unset;

                @include media-breakpoint(gt-sm) { // Greater than 960px
                    grid-template-columns: 1fr 3fr;
                }

                .card__assignation {
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 0.5rem;
                    padding: 0.5rem 1rem;
                    background-color: #FBED21;
                    color: $primary-c;
                    border: 4px solid $primary-c;
                    border-radius: 8px;
                    transform: translate(-25%, -50%);

                    .card__assignation-icon {
                        width: 4rem;
                        height: auto;
                        aspect-ratio: 1;
                    }

                    .card__assignation-label {
                        font-size: 1.6rem;
                        font-weight: 800;
                        line-height: 1;
                        text-transform: lowercase;
                    }
                }

                app-lesson-card-breadcrumb {
                    display: none;
                }

                .mat-mdc-card-header {
                    grid-column: 2 / span 1;
                    grid-row: 1 / span 1;
                    display: unset; // Unset default theme style
                    padding: unset; // Unset default theme style
                    background-color: inherit;
                    color: inherit;

                    .mat-mdc-card-header-text {

                        .mat-mdc-card-title {

                            h3 {
                                margin: 1rem 7.5rem 0 0;
                                font-size: 2rem;
                                font-weight: 900;
                            }
                        }
                    }
                }

                .mat-mdc-card-image {
                    grid-column: 1 / span 1;
                    grid-row: 1 / span 3;
                    width: 100%;
                    height: auto;
                    border-radius: 10px;
                }

                .mat-mdc-card-content {
                    display: contents !important; // Override inline style
                    background-color: inherit;
                    color: inherit;

                    .content-list {
                        grid-column: 2 / span 1;
                        grid-row: 2 / span 1;
                        margin: 1rem 7.5rem 0 0;

                        .description-field {

                            .field-title {
                                @include visually-hidden;
                            }
                        }
                    }

                    .keywords-list {
                        overflow: hidden;
                        grid-column: 1 / span 2;
                        grid-row: 3 / span 1;
                        margin: 0 -2rem;
                        padding: 1rem 0 1rem 1rem;
                        background-color: inherit;
                        color: inherit;
                        border-bottom-right-radius: 18px;
                        border-bottom-left-radius: 18px;
                        transform: translateY(2rem);

                        .keywords {
                            display: flex;
                            gap: 1rem;

                            dt {
                                padding: 1rem;
                                background-color: rgba($white, 0.15);
                                border-radius: 18px;

                                a {
                                    display: inline-block;
                                    width: max-content;
                                    color: inherit;
                                    font-weight: 900;
                                    text-transform: uppercase;
                                    pointer-events: none;
                                }
                            }
                        }
                    }

                    .card__progress-label {
                        z-index: 1;
                        grid-column: 1 / span 1;
                        grid-row: 1 / span 3;
                        justify-self: end;
                        margin: 0.5rem 0.5rem 0 2rem;
                        padding: 0.5rem;
                        background-color: $secondary-c;
                        border: 4px solid $primary-c;
                        border-radius: 8px;
                        font-size: 1.6rem;
                        font-weight: 800;
                        text-align: end;
                    }
                }

                .mat-mdc-card-actions {
                    display: contents;

                    button {

                        &.play-preview,
                        &.button-play {
                            @include button(
                                $type: tertiary,
                                $button-bordered: true,
                            );
                            position: absolute;
                            z-index: 1;
                            top: 50%;
                            right: 0;
                            justify-content: center;
                            width: 10rem;
                            height: 8rem;
                            padding: unset;
                            border: 4px solid $primary-c !important;
                            border-radius: 1000px;
                            box-shadow: 0px 4px 0px 0px $primary-c !important;
                            transform: translate(25%, -50%);

                            &:active {
                                transform: translate(25%, calc(-50% + 1px));
                            }

                            mat-icon.mat-icon { // Increase specificity
                                width: auto;
                                height: 45%;
                                aspect-ratio: 1;
                            }
                        }
                    }
                }

                &.card--locked-lesson {
                    pointer-events: none;

                    .lessonLocked {
                        position: absolute;
                        z-index: 1; // Override default theme style
                        inset: 0;
                        display: unset; // Unset default theme style
                        width: unset; // Unset default theme style
                        height: unset; // Unset default theme style
                        backdrop-filter: unset; // Unset default theme style
                        background-color: rgba($primary-c, 0.25); // Override default theme style
                        color: inherit; // Override default theme style
                        border-radius: inherit;

                        .mat-icon {
                            position: absolute;
                            top: 50%;
                            right: 0;
                            width: 10rem;
                            height: 8rem;
                            background-color: #617183;
                            border: 4px solid $primary-c;
                            border-radius: 1000px;
                            box-shadow: 0px 4px 0px 0px $primary-c;
                            transform: translate(25%, -50%);

                            &::before {
                                content: none; // Remove pseudo-element decalred in default theme
                            }

                            svg {
                                width: auto;
                                height: 70%;
                                aspect-ratio: 1;
                            }
                        }
                    }

                    .mat-mdc-card-image {
                        filter: sepia(100%) hue-rotate(170deg) brightness(80%) grayscale(40%);
                    }

                    .mat-mdc-card-content {

                        .keywords-list {
                            display: none;
                        }

                        .card__progress-label {
                            background-color: #617183;
                        }
                    }

                    .mat-mdc-card-actions {
                        display: none;
                    }
                }
            }

            // --------------------------------------------------
            // NON-LEARNER STYLE
            // --------------------------------------------------

            &:not(.card--learner-user) {
                overflow: hidden;
                display: grid;
                grid-template-columns: 1fr 2fr;
                grid-template-rows: auto 1fr auto auto;
                background-color: $off-white;
                color: $default-font-color;
                border-top: 1px solid rgba($primary-c, 0.2);
                border-radius: 5px;
                box-shadow: 0px 12px 40px -25px rgba(0, 0, 0, 0.5);

                app-lesson-card-breadcrumb {
                    grid-column: 1 / span 2;
                    grid-row: 1 / span 1;
                }

                .mat-mdc-card-header {
                    grid-column: 1 / span 2;
                    grid-row: 2 / span 1;
                    padding: 1rem 2rem;
                    background-color: $white;
                    color: $default-font-color;
                    box-shadow: 0px 6px 15px -7px rgba(0, 0, 0, 0.14);

                    .mat-mdc-card-header-text {

                        .mat-mdc-card-title {

                            h3 {
                                font-size: 2rem;
                                font-weight: 700;
                            }
                        }
                    }
                }

                .mat-mdc-card-image {
                    grid-column: 1 / span 1;
                    grid-row: 3 / span 1;
                    width: 100%;
                    height: auto;
                    aspect-ratio: 1;
                }

                .mat-mdc-card-content {
                    grid-column: 2 / span 1;
                    grid-row: 3 / span 1;
                    position: absolute; // Needed for the grid row height to be based on mat-card-image height and not on mat-card-content height
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: 1.5rem;
                    height: 100%;
                    padding: 1rem 2rem;
                    background-color: transparent;

                    .dl-list {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5rem;

                        > div {

                            &:empty {
                                display: none;
                            }

                            .field-title {
                                font-weight: 700;

                                &::after {
                                    content: ' : ';
                                }
                            }

                            .field-content {}
                        }

                        &.keywords-list {

                            .keywords {
                                display: flex;
                                flex-wrap: wrap;
                                gap: 0.5rem;

                                dt {
                                    padding: 0.5rem 1rem;
                                    background-color: $secondary-c;
                                    border-radius: 20px;

                                    a {
                                        color: $default-font-contrast-color;
                                    }
                                }
                            }
                        }
                    }

                    .card__progress-label {
                        display: none;
                    }
                }

                .mat-mdc-card-actions {
                    gap: 1rem;
                    grid-column: 1 / span 2;
                    grid-row: 4 / span 1;
                    margin: unset; // Unset default theme style
                    padding: 1rem !important; // Override default theme style
                    background-color: $white;
                    border-top: 1px solid rgba($primary-c, 0.2) !important; // Override default theme style
                    border-top-right-radius: 5px;

                    button {
                        flex-direction: row-reverse;
                        margin: unset; // Remove Material default style

                        &.mat-mdc-card-actions__btn-menu-trigger {
                            @include button(
                                $type: quaternary,
                                $button-bordered: true,
                            );
                        }

                        &.mat-mdc-card-actions__btn-play-lesson {
                            @include button(
                                $type: tertiary,
                                $button-bordered: true,
                            );

                            .mat-icon {
                                transform: scale(0.8);
                            }
                        }
                    }
                }
            }
        }
    }

    app-assignation-groups-detail & {

        &.card-split-media-info {

            .mat-mdc-card {                

                &.card--learner-user {

                    .card__assignation {
                        display: none;
                    }
                }
            }
        }
    }

    app-assignation-groups-detail .lessons-tab__cards--junior & {

        .mat-mdc-card {                

            &.card--learner-user {

                .mat-mdc-card-content {

                    .card__progress-label {
                        background-color: #FED848;
                        color: #007DE1;
                        border-color: #007DE1;
                    }
                }
            }
        }
    }

    app-assignation-groups-detail .lessons-tab__cards--college & {
        
        .mat-mdc-card {                

            &.card--learner-user {

                .mat-mdc-card-content {

                    .card__progress-label {
                        background-color: #5DE4BA;
                        color: #752994;
                        border-color: #752994;
                    }
                }
            }
        }
    }

    app-assignation-groups-detail .lessons-tab__cards--python & {
        
        .mat-mdc-card {                

            &.card--learner-user {

                .mat-mdc-card-content {

                    .card__progress-label {
                        background-color: #85FF00;
                        color: #2C4259;
                        border-color: #2C4259;
                    }
                }
            }
        }
    }
}