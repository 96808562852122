// --------------------------------------------------
// MIXINS
// --------------------------------------------------

@mixin contest-lessons__item($grid-columns) {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;

    &:before {
        content:"";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 40px);
        height: 5px;
        background-color: $contest--primary-accent-color;
    }

    &:first-child { // First child

        &:after {
            content:"";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(calc(-40px + 50%), -50%);
            width: 20px;
            height: 20px;
            background-color: $contest--primary-accent-color;
            border-radius: 50%;
        }

        &:nth-child(#{$grid-columns}n), // First child and last item in row
        &:last-child { // Or first child and last child

            &:before {
                background-color: unset;
                background: linear-gradient(90deg, $contest--primary-accent-color 80%, transparent 100%);
            }
        }
    }

    &:nth-child(#{$grid-columns}n+1):not(:first-child) { // First item in row and not first child

        &:before {
            background-color: unset;
            background: linear-gradient(90deg, transparent 0%, $contest--primary-accent-color 20%);
        }
    }


    &:nth-child(#{$grid-columns}n):not(:first-child), // Last item in row and not first child
    &:last-child:not(:first-child) { // Or last child and not first child

        &:before {
            transform: translate(calc(-50% - 10px), -50%);
            width: calc(100% + 30px);
        }

        &:after {
            content:"";
            position: absolute;
            top: 50%;
            right: 0;
            transform-origin: left top;
            transform: translate(calc(30px + 5px), -50%) rotate(45deg);
            width: 30px;
            height: 5px;
            background-color: unset;
            background: linear-gradient(90deg, $contest--primary-accent-color 10%, transparent 100%);
        }
    }
}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-contest-lessons {

    .contest-lessons__wrapper {
        box-shadow: $contest__wrapper--box-shadow;
        border-radius: 10px;
        
        .contest-lessons__content {
            padding: 10px 20px;
            background-image: $contest__content--background-image;
            background-position: 0% 30%;
            background-size: 300%;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            &.contest-lessons__content--border-radius {
                border-radius: 10px;
            }

            @include media-breakpoint(gt-xs) {
                padding: 40px;
            }

            .contest-lessons__tab-group {
                display: flex;
                flex-direction: column;
                gap: 20px;
                @include contest-tab-group;

                .mat-mdc-tab-body-wrapper {

                    .mat-mdc-tab-body {

                        .mat-mdc-tab-body-content {
                            overflow: hidden; // Override Material style
                        }
                    }
                }

                .contest-lessons__list {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    justify-content: center;
                    gap: 40px;
                    padding: 0 40px;
                    margin: 0;
                    list-style: none;

                    @include media-breakpoint(sm) {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @include media-breakpoint(md) {
                        grid-template-columns: repeat(3, 1fr);
                    }

                    @include media-breakpoint(lg) {
                        grid-template-columns: repeat(4, 1fr);
                    }

                    @include media-breakpoint(gt-lg) {
                        grid-template-columns: repeat(5, 1fr);
                    }

                    .contest-lessons__item {

                        @include media-breakpoint(xs) {
                            @include contest-lessons__item(1);
                        }

                        @include media-breakpoint(sm) {
                            @include contest-lessons__item(2);
                        }

                        @include media-breakpoint(md) {
                            @include contest-lessons__item(3);
                        }

                        @include media-breakpoint(lg) {
                            @include contest-lessons__item(4);
                        }

                        @include media-breakpoint(gt-lg) {
                            @include contest-lessons__item(5);
                        }

                        .contest-lessons__card {
                            
                            .mat-mdc-card {
                                padding: 0 0 2.2rem;

                                .mat-mdc-card-actions {

                                    button {
                                        bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .contest-lessons__spinner-wrapper {
        display: flex;
        justify-content: center;
    }
}