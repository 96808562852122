.navbar-fuse-sidebar {
    width: 255px;
    min-width: 255px;
    max-width: 255px;
}

navbar {

    &.navbar-background-color {
        background: $primary-c;
    }

}


navbar navbar-vertical-style-2 {

    .mat-icon {
        color: $nav-icon-color;
    }

    div.navbar-header {
        gap: 2rem;
        height: unset;
        min-height: unset;
        padding: 2rem 3rem;
        background-color: transparent;
        box-shadow: none;

        .folded:not(.unfolded) & {
            flex-direction: column;
            gap: 1rem;
            padding: 1rem 0.5rem !important;
        }

        .logo {

            .link {

                .logo-icon-png {
                    width: unset;
                    height: unset;
                }
            }
        }

        .toggle-sidebar-folded,
        .toggle-sidebar-opened {
            flex: none;
            width: 3.5rem;
            height: 3.5rem;
            padding: unset; // Unset Material style
            line-height: unset;

            .mat-icon {
                width: 100%;
                height: 100%;

                svg {
                    width: 100%;
                    min-width: unset;
                    height: 100%;
                    min-height: unset;
                }
            }
        }

        .toggle-sidebar-opened {
            transform: scale(0.9);
        }
    }

    mat-icon {
        &.logo-icon-svg {
            color: $logo-icon-svg-color;
            width: $logo-icon-svg-width;
            height: $logo-icon-svg-height;

            .folded:not(.unfolded) & { // mini sidebar // = .folded [...] mat-icon.logo-icon-svg
                width: 100%;
                min-width: 40px;
                max-width: 152px;
                height: 55px;

                .brand-text {
                    opacity: 0;
                    transition-property: all;
                    transition-duration: 100ms;
                    transition-timing-function: ease;
                }

                .brand-background, .brand-text-ia {
                    transform: translate(-327px, -58px) scale(2);
                    transition-property: all;
                    transition-duration: 200ms;
                    transition-timing-function: ease;
                }
            }

            &[svgicon="menu"] {
                width: $navbar-header-icon-dimensions;
                height: $navbar-header-icon-dimensions;
                color: $navbar-header-icon-svg-color;
            }
        }
    }

    .nav .nav-item {

        .nav-link {
            gap: 2rem;
            height: unset;
            padding: 1rem 2rem;
            font-weight: 700;

            .nav-link-title {
                color: $nav-link-title-color;
                font-size: $nav-link-title-font-size;

                .folded:not(.unfolded) & {
                    @include visually-hidden;
                }
            }

            .nav-link-icon,
            .collapsable-arrow {
                font-size: unset;
                width: 2.5rem;
                min-width: unset;
                height: auto;
                min-height: unset;
                aspect-ratio: 1;
                line-height: unset;
            }

            .nav-link-icon {
                margin: unset;
                opacity: 1;
            }

            .collapsable-arrow {

                .folded:not(.unfolded) & {
                    @include visually-hidden;
                }
            }

            &.active {
                background-color: $secondary-c !important;

                .nav-link-title {
                    color: $nav-link-title-color-active;
                }

                .nav-link-icon,
                .collapsable-arrow {
                    color: $nav-link-icon-color-active;
                }
            }

            &:hover {
                background-color: $secondary-c;

                .nav-link-title {
                    color: $nav-link-icon-color-hover;
                }

                .nav-link-icon,
                .collapsable-arrow {
                    opacity: 1;
                    color: $nav-link-icon-color-active;

                    svg {
                        color: $nav-link-icon-color-active;
                    }
                }
            }
        }

        &.nav-collapsable.open {
            background-color: darken($primary-c, 10%);

            &.nav-item > .nav-link {
                background-color: $secondary-c;

                &::before {
                    display: none;
                }
            }

            .children {

                .nav-link {
                    gap: 2rem;
                    position: relative;
                    padding: 1rem 2rem 1rem 6.5rem;
                    background-color: transparent !important;

                    &::before {
                        content: '';
                        width: 0.5rem;
                        height: auto;
                        aspect-ratio: 1;
                        background-color: transparent;
                        border-radius: 50%;
                        transition: background-color 50ms ease-in-out;
                    }

                    &:hover,
                    &.active.mat-accent-bg {

                        &::before {
                            background-color: $white;
                        }
                    }
                }

                .xnav-link {
                    position: relative;
                    padding-left: 90px;

                    &.active.mat-accent-bg, &:hover {
                        background: transparent !important;

                        &::before {
                            content: "";
                            width: 25px;
                            height: 25px;
                            display: block;
                            color: white;
                            position: absolute;
                            left: 30px;
                            background: icon('sub-menu-nav-link-icon.svg') no-repeat;
                        }

                    }
                }
            }


            .nav-link:first-child {
                .nav-link-icon, .collapsable-arrow {
                    opacity: 1;
                    color: $nav-link-icon-color-active;

                    svg {
                        color: $nav-link-icon-color-active;
                    }
                }
            }


        }


    }

    .nav > .navbar-content {
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;

        > fuse-nav-vertical-item.nav-item {
            width: 100%;
        }

        .wrapper-menu-item-access-future-engineer {
            padding: 2rem 1rem;

            .nav-link {
                @include button(
                    $type: secondary,
                    $size: s,
                    $button-bordered: true,
                );
                gap: 2rem;

                .mat-icon {
                    width: 2.5rem;
                }
            }
        }

        .wrapper-menu-item-contact-us {
            margin-top: auto;

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 12rem;
                padding: 2rem;
                background-image: image('logos/amazon-future-engineer.png');
                background-size: contain;
                background-origin: content-box;
                background-position: left;
                background-repeat: no-repeat;
                transform-origin: left;
                transition: transform 100ms ease-in-out;

                .folded:not(.unfolded) & {
                    content: none;
                }
            }
        }

        .wrapper-menu-item-contact-us,
        .wrapper-menu-item-mentions-legales,
        .wrapper-menu-item-cgurgpd,
        .wrapper-menu-item-politique-confidentialite,
        .wrapper-menu-item-accessibility {

            .nav-link {
                gap: 1rem;
                padding-block: 0.5rem;

                .nav-link-icon {
                    font-size: unset;
                    width: 2rem;
                    min-width: unset;
                    height: auto;
                    min-height: unset;
                    aspect-ratio: 1;
                    line-height: unset;
                }

                .nav-link-title {
                    font-size: 1.4rem;
                    font-weight: 400;
                }
            }
        }
    }

    .menu-link {
        position: absolute;
        bottom: 140px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: currentColor;
        width: 100%;
        height: 48px;
        margin: 0;
        cursor: pointer;
        opacity: 1;
        text-decoration: none !important;
        user-select: none;

        .nav-link-title {
            flex: 1;
            width: 100%;
            padding: 0 24px 0 59px;
            color: $white;
            white-space: normal;
            text-decoration: none !important;

            &::before {
                color: $white;
                position: absolute;
                left: 22px;
                content: '';
                display: block;
                width: 26px;
                height: 26px;
                min-width: 26px;
                min-height: 26px;
                font-size: 26px;
                line-height: 26px;

                background-color: $white;
                -webkit-mask-image: icon('contact.svg');
                mask-image: icon('contact.svg');

                background-size: 25px 25px;
            }
        }
    }

    .menu-link-block {
        position: relative;
    }

}