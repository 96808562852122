@use '@angular/material' as mat;
@use '@angular/cdk';

fuse-app-lessons {

    #lessons app-stepper-wrapper {
        flex: 1;
        display: flex;
        background-color: rgba($primary-c, 0.6);

        button {
            &.mat-mdc-fab.mat-accent,
            &.mat-mdc-mini-fab {
                background-color: inherit;
                box-shadow: none;
            }
        }

        > .page-layout {
            //background-color: map_get($md-bluedark, 500);
            background-color: rgba(0, 65, 87, 0.5);

            > .center {
                height: 100%;
                width: 100%;
                max-width: 100%;
                background-color: #004157;
            }

            .fuse-app-lessons-header {
                display: none;
                background-color: inherit;
                padding-bottom: 0;
                height: 55px;
                padding: 5px;

                .header-top {
                    padding: 5px;
                    flex-direction: row-reverse;
                }
            }

            .outlet-player-navigation {
                flex: 1;
                display: flex;
                flex-direction: column;

                > .previous-wrapper, > .next-wrapper {
                    min-width: 60px;
                    display: none;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                    align-content: stretch;
                    flex: none;

                    .previous, .next {
                        padding: 0;
                        width: 32px;
                        height: 32px;
                        background-color: mat.get-color-from-palette($accent);
                    }
                }

                > *:not(router-outlet) { // Enlarge any child component except router-outlet
                    flex: 1;
                }

                .activities-card {
                    flex: 1;
                    overflow-y: hidden;

                    .mat-mdc-card-content {
                        flex: 1;
                    }
                }

                app-multi {
                    flex: 1;
                }
            }

            app-external {

                mat-card.activities-card {
                    overflow-x: hidden;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    background-color: transparent;


                    .mat-mdc-card-header {
                        display: none;
                        flex: none;
                    }

                    .mat-mdc-card-content {
                        $main-top-toolbar-height: 64px;
                        $fuse-app-lessons-header-height: 34px;
                        overflow: hidden;
                    }
                }

                .return-to-lessons {
                    position: absolute;
                    right: 70px;
                    transform: translateX(calc(100% - 45px));
                    transition: transform 0.5s;
                    bottom: 20%;
                    background-color: $accent-c;
                    line-height: 1em;
                    max-width: 45%;
                    min-width: 78px;
                    padding: 5px 20px 5px 15px;
                    min-height: 40px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                    border-radius: 33px 0 0 33px;
                    color: #FFFFFF;

                    @media (max-width: 1050px), (min-width: 1277px) and (max-width: 1329px) {
                        right: 0;
                        bottom: 140px;
                        mat-icon {
                            transform: translateX(-5px);
                        }
                    }
                }

                @media (max-width: 1050px), (min-width: 1277px) and (max-width: 1329px) {
                    .return-to-lessons:hover {
                        transform: translateX(1%);
                        transition: transform 0.5s;
                    }
                }
            }


            .multi-side-nav-previous, .multi-side-nav-next {
                display: none !important;
            }

            .multi-content {
                padding-top: 0;
                padding-bottom: 0;
                margin-top: 0;
                margin-bottom: 0;

                mat-card.activities-card {
                    background-color: inherit;
                    color: white;
                    padding-top: 0;
                    padding-bottom: 0;

                    .mat-mdc-card-title {
                        margin: 0;
                    }

                    .medias {
                        padding: 0;

                        > div {
                            width: 100%;

                            .media-wrapper {
                                height: 50vh;
                                width: 100%;
                                text-align: center;

                                video, img {
                                    height: 100%;
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }

            app-multimedia {
                padding-top: 0;

                .mat-mdc-card {
                    background-color: transparent;
                    color: white;

                    .instruction {
                        color: white;
                    }

                    mat-card-content.mat-mdc-card-content {
                        justify-content: flex-start !important; // overide html flex
                        .media-wrapper {
                            justify-content: flex-start !important; // overide html flex
                            text-align: left;
                        }
                    }

                }
            }

        }
    }
}

fuse-app-lessons-tab {

    .spinner-for-launch-lesson {
        position: absolute;
        z-index: 2;
        left: calc(50% - 50px);
        top: 15%;
    }

}

app-lesson-editor {
    mat-spinner {
        margin: 25% auto;
    }
}

app-lesson-editor {

    div.page-layout.simple.fullwidth > .header {
        &.lesson-title {
            background-color: $secondary-c;
            font-family: Quicksand;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 44px;
            background-image: none;
            justify-content: flex-start;
            height: 70px;

            h2 {
                font-size: 30px;
                color: #FFFFFF;
            }
        }
    }

    mat-spinner {
        margin: 25% auto;
    }

    .content {
        padding-top: 35px;
        padding-right: 50px;
        margin-left: 25px;

        mat-spinner {
            margin: auto;
        }

        .lesson-editor-helper {
            margin-bottom: 50px;
            color: map_get($mat-black, 500);
            font-family: Quicksand;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 129%;
        }

        app-generic-activity {

            button.mat-mdc-fab:not([class*=mat-elevation-z]) {
                box-shadow: none;
            }

            button.add-page.mat-mdc-fab.mat-accent {
                background: #FFB08F !important;

                .mat-icon {
                    transform: scale(2.5);
                }

                path {
                    fill: mat.get-color-from-palette($accent);
                }
            }

            .add-page-label {
                margin-bottom: 20px !important;
            }

            .activity-bar {
                background: #FFFFFF;
                box-shadow: 0px 1px 10px rgba(0, 175, 236, 0.25);
                border-radius: 5px;
                height: 70px;
                display: flex;
                place-content: center;
                align-items: center;
                flex-direction: row;
                padding: 0 15px 0;
                margin-bottom: 15px;

                .left {

                    svg path {
                        fill: $primary-c;
                        stroke: $primary-c;
                        color: $primary-c;
                    }

                    button.mat-mdc-fab:not([class*=mat-elevation-z]) {
                        background-color: transparent;
                    }

                }

                .middle {
                    flex-grow: 1;
                    margin: 0 20px;

                    .inject-middle {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        font-weight: bold;
                        font-size: 20px;

                        span {
                            margin-right: 10px;
                            color: map_get($mat-black, 500);
                            font-family: Quicksand;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 20px;
                            line-height: 29px;
                        }

                        .mat-icon {
                            margin-right: 17px;
                            background-color: $primary-c;
                            border-radius: 2em;
                            color: mat.get-color-from-palette($accent);
                            padding: 9px;
                            display: none; // to remove if this icon will be used
                        }
                    }
                }

                .inject-left {

                    .mat-mdc-fab {
                        height: 50px;
                    }

                    .mat-icon {
                        width: 35px;
                        height: 35px;
                    }
                }

                .right {
                    button { // It's a mat-fab button and so already rounded
                        background-color: $primary-c;
                        width: 32px;
                        height: 32px;

                        .mat-icon {
                            svg path {
                                color: white;
                                fill: none !important;
                                stroke: white !important;
                            }
                        }
                    }


                }

                .left, .middle {
                    mat-icon {
                        width: 30px;
                        height: 30px;
                    }
                }

            }
        }
    }

    .add-step-button {
        flex: 1;
        justify-content: left;
        margin: 10px 30px;
        padding: 10px 35px;
        background: $primary-c !important;
        border-radius: 5px;
        color: white;
        font-family: Quicksand;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.4px;
    }

    .exit-button, .save-button:not(.add-step-button) {
        background-color: mat.get-color-from-palette($accent);
        color: #ffffff;
        font-family: Quicksand;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.75px;
        text-transform: uppercase;
        border-radius: 200px;

        mat-spinner {
            margin: 0;
        }
    }

    button.mat-mdc-fab.mat-accent.add-file-button {
        background-color: #1a1a4e !important;
    }

    /**counter inside row in editor**/
    counter-reset: section; // init counter
    app-one-line-activity {
        .inject-middle::before {
            counter-increment: section; /* increment counter */
            content: counter(section); /* text of counter to show*/
            color: #ffffff;
            background-color: #00afec;
            border-radius: 28px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            margin-right: 17px;
        }
    }
}

#editor-activities {

    .sidebar {
        padding: 13px;
        background-color: map-get($primary, 500);
        color: white;
    }

    .center {
        .filters {
            margin: 0 0 0 25px;
        }

        table {
            p {
                margin: 4px 0;
            }

            tr.mat-mdc-row {

                &.selected, &:hover {
                    background-color: map-get($primary, 500);
                    color: white;

                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;

                    &:after {
                        content: ' ';
                        display: block;
                        position: absolute;
                        right: -2px;
                        width: 34px;
                        height: 56px;
                        background-color: map-get($primary, 500);
                    }

                    td {
                        color: white;

                        &:last-child {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            color: white;
                        }
                    }

                    button.mat-mdc-mini-fab.mat-accent {
                        background-color: map-get($mat-white, 500);

                        .mat-icon {
                            color: map-get($primary, 500);
                        }
                    }
                }

                button.mat-mdc-mini-fab.mat-accent {
                    background-color: map-get($primary, 500);

                    .mat-icon {
                        width: 24px;
                        height: 24px;
                        margin: 5px;
                    }

                    path {
                        fill: none;
                    }
                }
            }


        }
    }
}

.activities-card .mat-mdc-card-actions, .activities-card.mat-mdc-card-actions {
    display: none;
}

app-recommendation {
    > .page-layout > .content {
        display: flex;
        flex-direction: column;
        padding: 16px;

        .mat-mdc-dialog-content {
            flex: 1;
        }

        .spinner-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            min-height: 125px;
        }

    }

    .filters {
        display: flex;

        .mat-toolbar-row {

            .mat-mdc-select {
                flex: 1;
            }

            button {
                margin-left: auto;
            }
        }
    }

    thead {
        mat-icon {
            svg {
                path {
                    fill: map-get($mat-white, 500);
                }
            }

            &.active {
                svg {
                    path {
                        fill: map-get($accent, 900);
                    }
                }
            }
        }
    }

    .see-more {
        margin: auto 45%;
        background-color: map-get($accent, 500);
        color: white;
    }
}

.count-lesson-selected {
    border-radius: 18px;
    padding-right: 4px;

    span {
        color: map-get($mat-white, 500);

        .count-title {
            &:after {
                content: ' : '
            }
        }

        .count {
            color: map-get($accent, 900);
            background: map-get($mat-white, 500);
            border-radius: 20px;
            height: 30px;
            min-width: 30px;
            line-height: 30px;
            text-align: center;
            margin-left: 5px;
        }
    }
}


mat-dialog-container .mat-mdc-dialog-surface app-editor-activities-list .mat-mdc-dialog-content {
    MAX-HEIGHT: 30VH !important; // patch TODO revoir l'imbrication des elements flex
}

app-list-of-activities {
    height: 100%;
    flex: 1 !important;
    flex-direction: column;
    color: $default-font-color;

    > main {
        overflow: auto;

        .no-activities {
            text-align: center;
        }

        .mat-mdc-tab-group {

            .mat-mdc-tab-header {
                padding: 0 3.5rem;
            }

            .mat-mdc-tab-body-wrapper {

                .mat-mdc-tab-body {

                    .mat-mdc-tab-body-content {

                        app-tips {

                            .tips-container {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                gap: 2rem;
                                max-width: max(50rem, 50%);
                                margin: 2rem 3.5rem 1rem;
                                padding: 1rem 0.2rem 1rem 3rem;
                                background-color: $primary-c;
                                color: $white;
                                border: 4px solid $secondary-c;
                                border-radius: 86px;

                                mat-icon {
                                    display: flex;
                                    height: auto;
                                    width: auto;
                                    max-width: 50px;
                                    color: $secondary-c;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    ul, li, .item {
        list-style: none;
        margin: 0;
        padding: 0
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        position: relative;
        padding: 0 45px;
        margin: 0 7% 100px 7%;
        @include media-breakpoint('lt-md') {
            margin: 0 6% 100px 6%;
        }
        background-position-y: 50px;
        background-size: 100%;
        background-position-x: center;

        &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            z-index: 0;
            height: 20px;
            width: calc(100% - 90px);
            background-color: #E8EDF6;
        }
    }

    li, .item {
        position: relative;
        // Mobile card distribution per line
        flex: 0 0 33%; // 3 per line
        @include media-breakpoint('gt-sm') {
            flex: 0 0 23%; // 4 per line
        }
        // Set the space between the cards
        padding: 2% 0 4% 0;
        // Line on the middle
        background-image: url(/assets/amazon_python/icons/lesson_background_line.svg);
        background-position-y: 50%;
        background-repeat: repeat-x;
        // Line on the bottom of card
        box-shadow: inset 0 -20px 0 $secondary-c;
        // Start Line
        &:first-child::after {
            content: "";
            display: block;
            width: 100%;
            height: 20px;
            border-radius: 10px;
            position: absolute;
            top: calc(50% - 10px);
            left: -45px;
            background-color: $secondary-c;
            z-index: -1;
        }
    }

    .item:first-child::after {
        background: none;
    }

    .item {
        background: none;
        box-shadow: none;
    }

    // Circle right
    // Half circle every end of row but not the last row

    @include media-breakpoint('lt-md') {

        li:not(:last-child):nth-child(3n+3):after {
            content: "";
            display: block;
            width: calc(50% + 40px);
            height: calc(50% + 10px);
            border: 20px solid $secondary-c;
            border-radius: 50%;
            position: absolute;
            top: calc(50% - 10px);
            right: 0;
            transform: translateX(50%);
            z-index: -1;
        }
        li:nth-child(3n+4):after {
            content: '';
            display: block;
            width: calc(50% + 40px);
            height: calc(50% + 30px);
            border: 20px solid $secondary-c;
            border-radius: 50%;
            position: absolute;
            left: 0;
            transform: translateX(-50%);
            bottom: calc(50% + -10px);
            z-index: -1;
        }
    }


    @include media-breakpoint('gt-sm') {
        li:not(:last-child):nth-child(4n+4):after {
            content: "";
            display: block;
            width: calc(50% + 40px);
            height: calc(50% + 10px);
            border: 20px solid $secondary-c;
            border-radius: 50%;
            position: absolute;
            top: calc(50% - 10px);
            right: 0;
            transform: translateX(50%);
            z-index: -1;
        }

        // circle left
        // Half circle every start of row
        li:nth-child(4n+5):after {
            content: '';
            display: block;
            width: calc(50% + 40px);
            height: calc(50% + 30px);
            border: 20px solid $secondary-c;
            border-radius: 50%;
            position: absolute;
            left: 0;
            transform: translateX(-50%);
            bottom: calc(50% + -10px);
            z-index: -1;
        }
    }

    // Arrow
    // End of row for the last element
    li:last-child:before {
        z-index: 0;
        content: "";
        display: block;
        position: absolute;
        width: 171px;
        height: 100px;
        background: url(/assets/amazon_python/icons/lesson_background_arrow-single.svg) no-repeat;
        top: 50%;
        transform: translateY(-50%);
        right: -90px;
    }

    header {
        display: flex;
        padding: 2rem 3.5rem;

        h1 {
            //styleName: H1 Bold 24;
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0;
            text-align: left;
        }

        button {
            @include button(
                $type: secondary,
                $button-bordered: true,
            );
            align-self: center;
            margin-left: auto;
        }
    }

    .spinner-wrapper {
        width: 100%;
        height: 100%;
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        place-content: center;
        align-items: center;
    }
}

app-list-of-activities,
app-contest-lessons {

    .mat-mdc-card {
        padding: 1.5rem 1.5rem 4.5rem;
        color: white;
        background: none;

        &:not([class*=mat-elevation-z]) {
            box-shadow: none;
        }
    }

    .mat-mdc-card-title {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 72px;
        margin-bottom: 0;
        background: $primary-c;
        border: 3px solid #85FF00;
        border-radius: 0.5em;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0;


        h2 {
            font-weight: 900;
            text-align: center;
            font-size: 14px;
            padding: 0 40px 0 20px;

            @include media-breakpoint-up('sm') {
                font-size: 20px;
            }
        }

        &:after {
            z-index: 5;
            content: "";
            display: block;
            width: 49px;
            height: 49px;
            background: url(/assets/amazon_python/icons/lesson_star_enabled.svg) no-repeat;
            position: absolute;
            transform: scale(0.75);
            right: -13px;
            top: 14px;
        }

        @include media-breakpoint-up('sm') {

            &:after {
                transform: scale(1);
                right: -7px;
                top: 25px;
            }
        }

    }

    .mat-mdc-card-image {
        height: auto;
        width: calc(86% - 36px) !important;
        margin: 0;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
    }

    .mat-mdc-card-content {
        margin: 0 10%;
        padding: unset; // Unset Material style
        background: radial-gradient(65.09% 65.09% at 50% 50%, #39E07C 0%, #58A5CF 100%);
        border: 5px solid #05A8AA;
        box-shadow: -4px 5px 10px rgb(0, 0, 0, 0.25), inset 0px 0px 52px rgb(0, 0, 0, 0.5);

        &:before {
            content: '';
            display: block;
            padding-top: 100%;
            width: 100%;
        }
    }


    %play-button {
        box-shadow: inset 0 5px 5px rgba(255, 255, 255, 0.05), 0 5px 10px rgba(0, 0, 0, 0.25);
        background-color: $accent-c;
        color: white;
    }

    .mat-mdc-card-actions {
        display: contents;

        button.mat-mdc-raised-button {
            @extend %play-button;
            position: absolute;
            z-index: 6;
            bottom: 1.5rem;
            left: 50%;
            transform: translateX(-50%);
            width: 9.5rem;
            min-width: min-content;
            max-width: calc(80% - 6rem);
            height: unset; // Unset Material style
            padding: 1rem;
            border: 1px solid rgba(0, 0, 0, 0.05);

            .mat-icon {
                width: 2.4rem;
                height: auto;
                aspect-ratio: 1;
                margin: unset; // Unset Material style
            }

            .mdc-button__label {
                @extend .cdk-visually-hidden;
            }

            // Disabled status
            &[disabled].mat-mdc-button-disabled {
                &:not([class*=mat-elevation-z]) {
                    @extend %play-button;
                }

                mat-icon.mat-icon svg path {
                    fill: white;
                }
            }

        }
    }

    .mat-mdc-card.not-done {
        img {
            opacity: 0.75;
        }

        .mat-mdc-card-title {
            border-color: $accent-c;

            &:after {
                background: url(/assets/amazon_python/icons/lesson_star_disabled.svg) no-repeat;
            }
        }
    }

    // Disabled status
    .mat-mdc-card.disabled {

        .mat-mdc-card-title {
            border-color: $accent-c;

            &:after {
                background: url(/assets/amazon_python/icons/lesson_star_disabled.svg) no-repeat;
            }
        }

        .mat-mdc-card-content {
            position: relative;

            &:after {
                content: "";
                display: block;
                width: calc(100% + 10px);
                height: calc(100% + 10px);
                position: absolute;
                background-color: #DDDDDD;
                top: -5px;
                left: -5px;
                z-index: 2;
                opacity: 0.65;
            }
        }

        .mat-mdc-card-actions button[disabled].mat-mdc-button-disabled {
            &:not([class*=mat-elevation-z]) {
                @extend %play-button;
            }

            mat-icon.mat-icon svg path {
                fill: white;
            }
        }
    }
}