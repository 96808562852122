// https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1

#login div#login-form-wrapper #login-form,
div#forgot-password-form,
#register #register-form {
    color: $fuse-authentication-color;
    background: none !important;
    border-radius: #{$fuse-authentication-border-radius};
    box-shadow: none;

    a {
        color: $white;
        text-decoration: underline;
    }


    > .submit-button {
        margin: 4em 0 2em;
    }

    .ent-help {
        margin-left: auto;
        margin-right: auto;
    }

    .title {
        font: #{$fuse-authentication-title-font} !important; // Override default theme style
        letter-spacing: #{$fuse-authentication-title-letter-spacing};
    }

    .mat-mdc-form-field-label {
        color: black;
        font-weight: 700;
        transition: color 0.5s, font-weight 0.5s;
    }

    .mat-mdc-checkbox {
        .mdc-form-field {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;

            .mdc-checkbox { // la case
                flex: none; // ne s'étend pas
            }

            .mdc-label {
                white-space: normal;
                display: flex;
                flex-wrap: wrap; // les enfants vont à la ligne
                flex: 1; // j'occupe tout l'espace dispo
            }
        }
    }

    jhi-re-captcha {
        margin-top: 20px;
    }

    .mdc-checkbox__background {
        border-color: map_get($mat-fusedark, 100);
    }

    .mat-form-field-appearance-fill {



        .mat-mdc-form-field-prefix, .mat-mdc-form-field-suffix {
            align-self: center;
            padding-right: 10px;
            color: #{$primary-c};
        }

        .mat-mdc-input-element, .mat-mdc-select {
            font: #{$fuse-authentication-field-input-font};
            font-size: 18px;
            background-color: transparent !important;
            color: black;

            margin-bottom: 4px;
            margin-top: 6px;
        }



        .mat-mdc-form-field-label {

        }

        .mat-mdc-form-field-underline::before,
        .mat-mdc-form-field-ripple,
        .mat-focused .mat-mdc-form-field-ripple,
        .mat-mdc-form-field-underline::before,
        .mat-mdc-form-field-ripple,
        .mat-focused .mat-mdc-form-field-ripple {
            margin-top: 5px;
            background-color: transparent;
        }
    }

    button.submit-button {
        @include button(
            $type: tertiary,
            $button-bordered: true,
        );
        min-width: fit-content !important; // Override default theme style
        width: auto !important; // Override default theme style
        max-width: 100%;
        margin-top: 40px;
        text-wrap: wrap; // Override Material style
    }
}

#login {
    div#login-form-wrapper { // TODO supprimer de default pour s'en passer ici
        padding: 0 calc(25% - $fuse-authentication-login-width / 2);
        flex-direction: row !important;
        justify-content: center !important;
        @include media-breakpoint-up('md') {
            justify-content: start !important;
        }

        #login-form {
            position: relative;
            width: $fuse-authentication-login-width; // 384px;
            max-width: $fuse-authentication-login-width;
            padding: 18px 32px;

            .logo {
                margin: #{$fuse-authentication-logo-margin};
                width: $fuse-authentication-logo-width;

                .mat-icon {
                    width: inherit;
                    height: inherit;
                    min-width: inherit;
                    min-height: inherit;
                    line-height: inherit;
                }

                img {
                    max-height: 150px;
                    width: auto;
                    @include media-breakpoint-up('md') {
                        max-height: inherit;
                    }
                }
            }

            .title {
                margin: 20px 0 10px 0;
                @include media-breakpoint-up('md') {
                    margin: 40px 0 20px 0;
                }
            }

            .divider {
                margin: 2em 0;
                color: $fuse-authentication-color;
                font-weight: bold;
                display: block;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;

                > span {
                    text-transform: uppercase;
                    position: relative;
                    display: inline-block;

                    &:before, &:after {
                        content: "";
                        position: absolute;
                        top: 45%;
                        width: 9999px;
                        height: 2px;
                        background: $fuse-authentication-color;
                    }

                    &:before {
                        right: 100%;
                        margin-right: 15px;
                    }

                    &:after {
                        left: 100%;
                        margin-left: 15px;
                    }
                }
            }

            app-sessions-list {
                display: flex;
                flex-direction: column;
                gap: 6rem;
                margin: 7.5rem 0 1.5rem;
                
                .mat-mdc-progress-spinner {
                    margin: 0 auto 6rem;

                    circle {
                        stroke: $secondary-c;
                    }
                }

                .session-list__cached-user {
                    display: flex;
                    gap: 1rem;

                    .session-list__user-login-button {
                        position: relative;
                        flex: 1;
                        display: grid;
                        grid-template-columns: 1fr 9rem;
                        gap: 1.5rem;
                        height: 5rem;
                        padding: 1rem 0rem 1rem 1.5rem;
                        border-radius: 6px;
                        background-color: $white;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
                        cursor: pointer;

                        .session-list__user-login-button-label {
                            grid-column: 1 /span 1;
                            align-self: center;
                            overflow: hidden;
                            font-size: 2.7rem;
                            font-weight: 900;
                            font-style: italic;
                            text-transform: capitalize;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .session-list__user-login-button-image {
                            position: absolute;
                            grid-column: 2 /span 1;
                            align-self: end;
                            width: 8.5rem;
                            height: auto;
                            aspect-ratio: 1;
                            margin: 0 0.5rem 0.5rem 0;
                            border-radius: 5px;
                            background-color: $white;
                            box-shadow: 0px 1.5px 3px 0px rgba(255, 255, 255, 0.7) inset, 0px 2.5px 7px 0px rgba(0, 0, 0, 0.15);
                        }
                    }

                    .session-list__remove-user-button {
                        @include button(
                            $type: secondary,
                            $button-bordered: true,
                        );
                    }
                }

                .session-list__new-login-button {
                    color: $white;
                    background-color: unset;
                    border: unset;
                    font-size: 1.4rem;
                    font-weight: 500;
                    text-decoration: underline;
                }

                & + .register {
                    position: relative;
                    bottom: revert;
                    width: auto;
                    justify-content: center;
                }
            }

            .register {
                padding: 0;
                margin: 0;
                position: absolute;
                bottom: 101px;
                width: calc(100% - 64px);
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
            }
        }

    }

}

#register {
    #register-form-wrapper {
        padding: 0;
        flex-direction: row !important;
        justify-content: start !important;
        width: 100%;
        @include media-breakpoint-up('lg') {
            width: 50%;
        }

        #register-form {
            padding: 24px 24px 50px;
            @include media-breakpoint-up('md') {
                padding: 18px 96px;
            }

            .logo {
                width: 100%;
            }

            .title {
                margin-bottom: 18px;
            }

            .mat-mdc-form-field {
                padding: 0 5px;

                &.field-password {
                    max-width: 100% !important;
                }
            }

            .register {
                margin-top: 8px;
            }
        }
    }
}

fuse-login,
fuse-register,
fuse-forgot-password {
    position: relative;
    background: var(--blue, #3FC1C9);

    &::before {
        position: absolute;
        content: '';
        inset: 0;
        z-index: 0;
        opacity: 0.5;
        background: url('/assets/amazon_python/images/backgrounds/background.jpg') lightgray 50% / cover no-repeat;
        mix-blend-mode: multiply;
    }

    &::after {
        position: absolute;
        content: '';
        inset: 0;
        z-index: 1;
        background: linear-gradient(244deg, rgba(0, 0, 0, 0.88) -7.68%, rgba(0, 0, 0, 0.00) 165.96%);
    }

    #login,
    #register,
    #forgot-password {
        position: relative;
        z-index: 2;
        flex: 1;

        &:where(#forgot-password) {
            background-image: unset; // Unset default theme style
            background-size: unset; // Unset default theme style
        }

        &::after {
            position: absolute;
            z-index: 2;
            content: '';
            display: block;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-image: url('/assets/amazon_python/images/backgrounds/ville_seul_1.png') !important;
            background-size: auto 100% !important;
            background-position: 100% center;
            background-repeat: no-repeat !important;
            opacity: 0.3;

            @include media-breakpoint(gt-sm) {
                opacity: 1;
            }
        }

        #login-form-wrapper,
        #register-form-wrapper,
        #forgot-password-form-wrapper {
            z-index: 3;

            #login-form,
            #register-form,
            #forgot-password-form {

                form {

                    .mat-mdc-form-field {

                        .mat-mdc-form-field-wrapper {

                            .mat-mdc-form-field-subscript-wrapper {

                                .mat-error {

                                    &:has(span:empty) {
                                        display: none; // Do not display if empty
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &:where(#forgot-password-form-wrapper) {
                padding: 3rem set-fluid-scale(30px, 180px); // Override default theme style

                @include media-breakpoint(gt-sm) {
                    align-items: flex-start !important; // Override Bootstrap inline style
                }

                #forgot-password-form {
                    width: 100%; // Override default theme style
                    max-width: $fuse-authentication-login-width; // Override default theme style
                    padding: unset; // Unset default theme style
                    box-shadow: unset; // Unset default theme style

                    .logo {
                        width: unset; // Unset default theme style
                        max-width: 45rem;
                        margin: 0 auto; // Override default theme style
                    }

                    .title {
                        margin: 8rem 0 2rem; // Override default theme style
                    }

                    form {

                        .mat-mdc-form-field {
                            display: block;
                            max-width: 40rem;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }

    .btn__goToLoginPage {
        color: white;
    }


    mat-form-field {
        // &.mat-mdc-form-field-type-mat-input
        &.mat-form-field-appearance-fill {
            --mdc-filled-text-field-label-text-weight: #{$form-field-label-font-weight}; // $form-field-label-font-weight: bold;
        }
        // &.mat-primary THEME
        // STATES : ng-untouched ng-pristine ng-invalid
        // &.mat-mdc-form-field-label-always-float LABEL Option : always-float, auto, never
        // &.mat-focused
        &.mat-mdc-form-field { // base du champ par défaut
            min-width: 150px;

            &.mat-mdc-form-field-type-mat-input {} // pour l'instant pas de propriétés liées à ce type
            &.mat-form-field-appearance-fill {} // ou &.mat-form-field-appearance-outline

            div.mat-mdc-text-field-wrapper { // Positionne et anime le label
                &.mdc-text-field { // base du champ par défaut
                    padding: 0;
                }
                &.mdc-text-field--filled { // Style ou &.mdc-text-field--outlined
                    background: transparent;
                    .mdc-floating-label {
                        font-family: var(--mdc-filled-text-field-label-text-font);
                        font-size: var(--mdc-filled-text-field-label-text-size);
                        font-weight: var(--mdc-filled-text-field-label-text-weight);
                        letter-spacing: var(--mdc-filled-text-field-label-text-tracking);
                    }
                    &:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-floating-label,
                    &:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-floating-label--float-above {
                        color: white;
                    }
                }


                div.mat-mdc-form-field-flex {
                    margin-top: 2.5em;
                    border-radius: #{$fuse-authentication-field-infix-border-radius};
                    border: none;

                    div.mat-mdc-form-field-infix {
                        --mat-form-field-container-height: 42px;
                        --mat-form-field-filled-with-label-container-padding-top: 0;
                        --mat-form-field-filled-with-label-container-padding-bottom: 0;

                        padding: #{$fuse-authentication-field-infix-padding};
                        border: none;

                        min-height: var(--mat-form-field-container-height);
                        padding-top: var(--mat-form-field-filled-with-label-container-padding-top);
                        padding-bottom: var(--mat-form-field-filled-with-label-container-padding-bottom);
                        padding-inline: 15px;

                        label.mdc-floating-label--float-above {
                            --mdc-filled-text-field-label-text-size: 2.2rem;
                            top: 0.6rem;
                            left: 0;
                            font-size: var(--mdc-filled-text-field-label-text-size);
                            --mdc-filled-text-field-hover-label-text-color: #{$fuse-authentication__label--float--color};
                            color: var(--mdc-filled-text-field-hover-label-text-color);
                        }

                        .mat-mdc-form-field-required-marker {
                            color: map-get($warn, 500);
                        }
                        .mat-mdc-select-arrow-wrapper {
                            transform: inherit;
                        }
                    }
                }

                div.mdc-line-ripple {
                    display: none;
                }
            }

            .mat-mdc-form-field-subscript-wrapper { //.mat-mdc-form-field-bottom-align

                &.mat-mdc-form-field-bottom-align {
                    &::before {
                        height: 0;
                        display: block;
                    }
                }
                div.mat-mdc-form-field-error-wrapper {
                    position: relative;
                    padding: 0;

                    mat-error.mat-mdc-form-field-error {
                        display: inline-flex;
                        margin-top: .6rem;
                        padding: .6rem 1.2rem;
                        background-color: #f5e2e2;
                        border-radius: 6px;
                        font-size: 1.4rem;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .mdc-label {
        color: $default-font-contrast-color;
    }

}