app-my-city {
    display: contents;

    .my-city {
        flex: auto;
        display: flex;
        flex-direction: column;

        .my-city__iframe {
            flex: auto;
            border-radius: 10px;
        }
    }
}