// --------------------------------------------------
// MIXINS
// --------------------------------------------------

@mixin assignment-state-chip {
    padding: 0.5rem 1.5rem;
    background-color: #FF7A00;
    color: $off-white;
    border: 1px solid rgba($black, 0.1);
    border-radius: 5px;
    font-weight: 600;
    text-shadow: 0px 2px 4px rgba($black, 0.25); 

    &.assignment-state-assigned {
        background-color: #959AA4;
    }

    &.assignment-state-valid {
        background-color: #4FBC95;
    }
}

// --------------------------------------------------
// ASSIGNATION-GROUPS-LIST
// --------------------------------------------------

app-assignation-groups-list {

    .assignation-groups-list {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 3rem;
        color: $default-font-color;

        .assignation-groups-list__container {
            display: contents !important; // Override Bootstrap inline style

            .assignation-groups-list__filter {}

            .assignation-groups-list__content {

                .assignation-groups-list__help-message {
                    text-align: center;
                }

                .assignation-groups-list__table {
                    @include flex-table;

                    thead,
                    tbody {

                        tr.mat-mdc-header-row,
                        tr.mat-mdc-row {

                            th.mat-mdc-header-cell,
                            td.mat-mdc-cell {

                                &.mat-column-lesson {
                                    flex: 3;
                                }

                                &.mat-column-expand {
                                    flex: none;
                                    width: 3.2rem; // Override default theme style
                                }
                            }
                        }
                    }

                    tbody {

                        tr.mat-mdc-row {
                            transition: border 50ms ease-in-out;

                            td.mat-mdc-cell {

                                &.mat-column-questionSet {

                                    span.button {
                                        color: $white;
                                        font-weight: 700;

                                        &:not(:empty) {
                                            padding: 0.5rem 1.5rem;
                                            background-color: $secondary-c;
                                            border-radius: 16px;
                                        }
                                    }
                                }

                                &.mat-column-status {

                                    span.button {
                                        @include assignment-state-chip;
                                    }
                                }

                                &.mat-column-expand {

                                    button {
                                        @include button(
                                            $type: tertiary,
                                            $size: s,
                                            $button-bordered: true,
                                            $text-hide: true,
                                            $icon-full: true
                                        );

                                        mat-icon.mat-icon {
                                            width: 2rem;
                                            height: auto;
                                            aspect-ratio: 1;
                                        }
                                    }
                                }
                            }

                            &.example-expanded-row {
                                border: 3px solid $secondary-c;
                            }

                            &.example-detail-row {
                                padding: unset;
                                background-color: unset;
                                border: unset;

                                td.mat-mdc-cell {
                                    margin-top: -0.5rem;
                                    padding: 0 2rem 0.5rem;

                                    .example-element-detail {
                                        flex: 1;
                                        overflow: hidden;

                                        .mat-mdc-list {
                                            display: flex;
                                            flex-direction: column;
                                            gap: 0.5rem;
                                            padding: 1rem;
                                            background-color: rgba($primary-c, 0.1);
                                            border-radius: 0 0 6px 6px;

                                            .mat-mdc-list-item {
                                                height: unset; // Unset Material style
                                                padding: 0.5rem 4rem;
                                                background-color: $off-white;
                                                color: inherit; // Override Material style
                                                border: 1px solid #CBD1DB;
                                                border-radius: 6px;
                                                font-size: inherit; // Override Material style

                                                .mdc-list-item__content {
                                                    padding: unset; // Unset Material style
                                                    color: inherit; // Override Material style
                                                    font-size: inherit; // Override Material style

                                                    .mdc-list-item__primary-text {

                                                        .mat-line {
                                                            align-items: center;

                                                            span.button {
                                                                @include assignment-state-chip;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .assignation-groups-list__spinner {
                margin: auto;
            }

            .assignation-groups-list__pagination {}
        }
    }
}

// --------------------------------------------------
// APP-USERSAVES-LIST
// --------------------------------------------------

app-usersaves-list {

    .usersaves-list {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 3rem;
        color: $default-font-color;

        .usersaves-list__container {
            display: contents !important; // Override Bootstrap inline style

            .usersaves-list__banner-info {
                margin: unset; // Unset amazon_python theme style

                &:empty,
                &:has(> *:empty) {
                    display: none; // Do not display banner-info component if it is empty or if its first child is empty
                }
            }

            .usersaves-list__filter {}

            .usersaves-list__content {

                .usersaves-list__table {
                    @include flex-table;

                    thead,
                    tbody {

                        tr.mat-mdc-header-row,
                        tr.mat-mdc-row {

                            th.mat-mdc-header-cell,
                            td.mat-mdc-cell {

                                &.mat-column-lesson,
                                &.mat-column-context {
                                    flex: 3;
                                }

                                &.mat-column-questionSet {
                                    flex: 2;
                                }
                            }
                        }
                    }

                    tbody {

                        tr.mat-mdc-row {

                            td.mat-mdc-cell {

                                &.mat-column-questionSet {

                                    span {
                                        color: $white;
                                        font-weight: 700;

                                        &:not(:empty) {
                                            padding: 0.5rem 1.5rem;
                                            background-color: $secondary-c;
                                            border-radius: 16px;
                                        }
                                    }
                                }

                                &.mat-column-context {

                                    span.tag {
                                        padding: 0.5rem 1.5rem;
                                        color: $off-white;
                                        border: 1px solid rgba($black, 0.1);
                                        border-radius: 5px;
                                        font-weight: 600;
                                        text-shadow: 0px 2px 4px rgba($black, 0.25);

                                        &.assignment {
                                            background: $secondary-c;
                                        }

                                        &.auto-assignment {
                                            background: $primary-c;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .usersaves-list__spinner {
                margin: auto;
            }

            .usersaves-list__pagination {}
        }
    }
}

// --------------------------------------------------
// ...
// --------------------------------------------------

fuse-app-assignation {
    color: #3A4354;

    .mat-button-toggle:first-child {
        border-right: 0;
    }

    mat-button-toggle-group {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-bottom: 30px;

        span {
            text-transform: uppercase;
            color: rgba(58, 67, 84, 0.5);
            font-weight: 700;
        }

        .mat-button-toggle-checked{
            background: $accent-c;
            span {
                color: #FFFFFF !important;
            }
        }
    }

    mat-selection-list {
        width: 100%;
    }

    mat-list-option {
        border: 0.5px solid #CBD1DB;
        border-radius: 5px;
        margin: 10px 0;

        text-transform: uppercase;
        font-weight: 700;
        color: #323A49;

        background: #F9FBFF !important;

        .mdc-list-item__primary-text {
            display: flex;
            justify-content: space-between;
            flex-direction: initial !important;
            align-items: center;
        }

        .button-like {
            text-align: center;
            color: #FFFFFF;
            background: #28458C;
            border-radius: 16.5px;
            padding: 4px 12px !important;
            text-transform: initial;
            width: fit-content;
        }
    }
}