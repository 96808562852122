#fuse-splash-screen {
    background-color: $primary-c;

    .center {

        .spinner-wrapper {

            .spinner {

                .inner {

                    .left,
                    .right {

                        .half-circle {
                            border-top-color: $secondary-c;
                        }
                    }

                    .left {

                        .half-circle {
                            border-left-color: $secondary-c;
                        }
                    }

                    .right {

                        .half-circle {
                            border-right-color: $secondary-c;
                        }
                    }
                }
            }
        }
    }
}