#my-ranking {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 10px 10px 30px 10px;
    justify-content: flex-start;
    background-color: $primary-c;
    border-radius: 24px;

    @include media-breakpoint-up('sm') {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    h2 {
        flex: none;
        display: flex;
        align-items: center;
        padding: 0 20px;
        margin: 0;
        color: white;
        font-size: 18px;
        font-weight: normal;
        text-transform: uppercase;

    }

    .my-ranking__list {
        display: flex;
        margin: 0;
        background-color: white;
        border: 5px solid $primary-c;
        box-shadow: 0 0 15px rgb(0, 0, 0, 0.5);
        border-radius: 24px;
        font-weight: 700;
        font-style: italic;
        color: #3A4354;
        flex: 1;

        @include media-breakpoint-up('sm') {
            flex: none;
        }

        dd {
            margin: 0;

            &:before,
            &:after {
                display: none;
            }

            &:first-of-type {
                border-top-left-radius: 24px;
                border-bottom-left-radius: 24px;
            }

            &:last-of-type {
                border-top-right-radius: 24px;
                border-bottom-right-radius: 24px;
                margin-left: auto;
            }
        }
    }

    .my-ranking__list__name {
        font-size: 27px;
        background-color: white;
        padding: 0 2%;
        @include media-breakpoint-up('sm') {
            padding: 0 50px;
        }
    }

    .my-ranking__list__points {
        font-size: 27px;
        padding: 0 3%;
        white-space: nowrap;
        @include media-breakpoint-up('sm') {
            padding: 0 70px;
        }
    }

    .my-ranking__list__ranking {
        flex: none;
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 0 18px;
        font-size: 19px;
        font-style: normal;
        color: white;
        background-color: #fbac35;
        border: 5px solid #fff;
        border-radius: 24px;
        box-shadow: inset 0 0 1px #b87a1d;
    }
}