app-lesson-page {
    color: $default-font-color;

    .content {
        gap: 3rem;
        padding: 2rem 3rem !important; // Override Bootstrap inline style
    }

    .header {
        align-items: center;
        padding: unset !important; // Unset Bootstrap inline style

        button {

            &.inverted {
                @include button(
                    $type: quaternary,
                    $button-bordered: true,
                );
            }
        }

        .breadcrumb {
            font-weight: 700;

            ul {
                padding: 0 1.5rem;
                margin: 0;
            }

            li {
                display: inline-block;

                .lesson-card-breadcrump__nav__item__separator {
                    padding: 0 0.5rem;
                }

                &.concept,
                &.chapter {

                    a {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .lesson-section {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        min-width: fit-content;
        padding: 1.5rem;
        background-color: $off-white;
        border-radius: 8px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);

        .lesson-wrapper {
            display: flex;
            gap: 1.5rem;

            .lesson-thumbnail {
                flex: 0 0 20rem;

                img {
                    border: 1px solid #E8EDF6;
                    border-radius: 8px;
                }
            }

            .lesson-content {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                .lesson-title {

                    > h1 {
                        font-weight: 700;
                        text-transform: uppercase;
                        margin: 0;
                    }
                }

                .lesson-author {
                    width: fit-content;
                    padding: 1rem;
                    background-color: #E8EDF6;
                    border-radius: 5px;

                    > span:last-child {
                        font-weight: 700;
                    }
                }

                .lesson-description {

                    > p {
                        margin: 0;
                    }
                }

                .lesson-tags {

                    mat-chip,
                    mat-chip-option,
                    mat-chip-row {
                        background-color: $secondary-c;

                        a {
                            color: $white;

                            &::first-letter {
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }

            .lesson-actions {
                flex: none;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding-left: 6rem;
                margin-left: auto;

                > div {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    button {
                        justify-content: space-between !important;
                        width: 100% !important;
                    }

                    &:first-child {

                        button {
                            @include button(
                                $type: tertiary,
                                $button-bordered: true,
                            );
                        }
                    }

                    &:last-child {

                        button {
                            @include button(
                                $type: quaternary,
                                $button-bordered: true,
                            );
                        }
                    }
                }
            }
        }

        .lesson-files {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            padding: 1.5rem;
            border-radius: 8px;
            background-color: #E8EDF6;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04) inset;
            box-shadow: 0px 4px 4px 0px rgba(50, 58, 73, 0.02) inset;

            .lesson-files-title {
                display: flex;
                align-items: center;
                gap: 1rem;

                h2 {
                    font-weight: 700;
                    font-size: 1.8rem;
                    margin: 0;
                }
            }

            .file-cards-wrapper {
                display: flex;
                overflow-x: hidden;

                .custom-nav {
                    position: absolute;
                    top: 1rem;
                    right: 1.5rem;
                    display: flex;
                    gap: 1rem;

                    #prev,
                    #next {
                        @include button(
                            $type: quinary,
                            $icon-full: true,
                        );
                    }

                    #prev {

                        mat-icon {
                            rotate: 180deg;
                        }
                    }
                }

                mat-card {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 1.5rem;
                    margin-right: 1.5rem;
                    background-color: $white;
                    color: inherit;
                    border: 1px solid #CBD1DB;
                    border-radius: 5px;
                    overflow-wrap: anywhere;
                    cursor: pointer;

                    mat-card-content {
                        padding: unset; // Unset Material style
                        font-size: 1.4rem;
                        font-weight: 700;
                    }

                    mat-icon {
                        width: 5.5rem;
                        min-width: unset;
                        height: auto;
                        min-height: unset;
                        aspect-ratio: 1;
                        padding: 0.5rem;
                        margin-right: 1rem;
                        color: $accent-c;
                        border: 1px solid $accent-c;
                        border-radius: 8px;
                        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
                    }
                }
            }
        }
    }
}