%GrayBackgroundColor {
    background: var(--fond-gris-leg, linear-gradient(0deg, rgba(111, 168, 255, 0.10) 0%, rgba(111, 168, 255, 0.10) 100%), #F5F5F5);
}

app-basic-page-list-as-cards {
    @extend %GrayBackgroundColor;

    .page-layout {
        @extend %GrayBackgroundColor;
        padding: 35px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
    }

    .card-grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: stretch;
        align-content: flex-start;

        gap: 25px;
    }

    mat-card.mdc-card.mat-mdc-card {
        width: 535px;
        background: var(--blanc, #FFF);
        border-radius: 5px;
    }

    mat-card-content.mat-mdc-card-content {
        &:last-child {
            padding-bottom: 0;
        }

        height: 355px;
        flex-grow: 1;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
        padding: 0;
        width: 535px;

        .image-content {
            width: 222px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            align-content: center;

            background: url('/assets/amazon_python/images/backgrounds/basic-page-as-card-image-background.svg') no-repeat center center / contain;
            background-size: 100% 100%;
        }

        .body-wrapper {
            width: 406px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;
            align-content: center;

            .body-content {
                flex-grow: 1;
                padding: 27px 23px;
                overflow: auto;
            }
        }
    }

    mat-card-header.mat-mdc-card-header {
        padding: 15px 20px;
        background: var(--bleu-amazon, #28458C);
        color: #FFF;
        /* H3 Bold 18 */
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        border-radius: 5px 5px 0px 0px;
    }

    .inside-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        align-content: stretch;

        padding-bottom: 16px;

        button, a {
            padding: 10px 40px;
            background: var(--orange-amazon, #FBAC35);

            color: var(--blanc, #FFF);
            text-align: center;
            /* H4 Bold 16 */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    app-tips {
        display: flex;
        min-height: 53px;

        .tips-container {
            margin: 0 !important;
        }

        margin-bottom: 40px;
    }

}
