toolbar {
    display: contents !important; // Override Bootstrap style

    .toolbar {
        display: flex;
        justify-content: space-between !important; // Override Bootstrap style
        gap: 1.5rem;
        height: unset;
        padding: 1.5rem;
        background-color: darken($secondary-c, 10%) !important;
        background-image: image('backgrounds/toolbar.png') !important;
        background-size: cover !important;
        background-position: right !important;
        background-repeat: no-repeat !important;
        color: $white !important; // Override Material style

        .toolbar__container {
            display: contents !important; // Override Bootstrap style

            .toolbar__left-content,
            .toolbar__right-content {
                display: flex;
                align-items: stretch !important; // Override Bootstrap style
                gap: 1rem;

                .toolbar__separator {
                    display: none;
                }
            }

            .toolbar__left-content {
                flex: auto;
                justify-content: flex-start;

                .toolbar__menu-button {
                    flex: none;
                    align-self: center;
                    width: 3.5rem; // Override Material style
                    min-width: unset; // Unset Material style
                    height: 3.5rem; // Override Material style
                    min-height: unset; // Unset Material style
                    padding: unset; // Unset Material style
                    line-height: unset; // Unset Material style

                    .mat-icon {
                        width: 100%;
                        height: 100%;
                        color: $white;
                    }
                }
            }

            .toolbar__right-content {
                justify-content: flex-end;

                .toolbar__login-button,
                .toolbar__language-button {
                    padding-inline: 0.5rem;
                    color: inherit;
                }

                .toolbar__language-button {
                    order: 3;
                    min-width: unset;
                    height: unset;
                }

                .toolbar__help-button {

                    &:empty {
                        display: none;
                    }
                }

                .toolbar__user-button {
                    @include button(
                        $type: secondary,
                        $size: s,
                        $button-bordered: true,
                    );
                    flex: none;
                    justify-content: space-between;
                    height: unset;

                    @include media-breakpoint(gt-xs) {
                        min-width: 18rem;
                    }

                    .mdc-button__label {
                        display: contents;

                        .toolbar__user-button-container {
                            display: contents !important; // Override Bootstrap style;

                            .toolbar__user-data {
                                display: flex;
                                align-items: center;
                                gap: 1rem;

                                .toolbar__user-avatar {
                                    width: 3rem;
                                    min-width: unset;
                                    height: auto;
                                    min-height: unset;
                                    aspect-ratio: 1;
                                    margin: unset;
                                }

                                .toolbar__user-labels {
                                    margin: unset !important; // Override Fuse style;

                                    .toolbar__user-name {}

                                    .toolbar__user-role {}
                                }
                            }

                            .toolbar__user-button-icon {
                                width: 2.5rem !important; // Override Fuse style;
                                min-width: unset !important; // Override Fuse style;
                                height: auto !important; // Override Fuse style;
                                min-height: unset !important; // Override Fuse style;
                                aspect-ratio: 1;
                            }
                        }
                    }
                }

                .toolbar__notification-button {
                    @include button(
                        $type: secondary,
                        $size: s,
                        $button-bordered: true,
                    );
                    flex: none;

                    .toolbar__notification-icon {
                        width: 2.5rem !important;
                        height: auto !important;
                        aspect-ratio: 1;
                    }

                    &.toolbar__notification-button--unread {
                        background-color: $accent-c;
                    }
                }
            }
        }
    }
}