gamification {
    mat-ink-bar, .mat-mdc-tab-header {
        display: none !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mdc-tab__content {
        display: none !important;
    }

    .credits, .icon {
        z-index: 2;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        line-height: 21px !important;
        letter-spacing: 0.1px !important;
        color: #000000 !important;

        mat-icon {
            height: 38px !important;
            width: 38px !important;
        }
    }

    .credits > .icon {
        background: none !important;
        font-size: 2rem !important;
        font-weight: 700 !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-mdc-tab-labels {
        background-color: #E8EDF6;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-mdc-tab-body-content {
        transform: none;
        padding: 75px 400px 150px 400px;
        background-color: #e8edf6;
    }

    app-my-animals {
        padding: 0;
        background: #F9FBFF;
        border: 6px solid $primary-c;
        box-shadow: inset 0 4px 10px rgba(255, 255, 255, 0.7);
        filter: drop-shadow(0 7px 20px rgba(0, 0, 0, 0.15));
        border-radius: 15px;
    }

    .animal-container {
        border-radius: 8px;
    }

    app-animal-controls {
        height: calc(100% + 15px) !important;

        .inner-container {
            position: absolute;
            top: -20px;
            padding: 10px 30px;
            background-color: $primary-c;
            border: 3px solid $secondary-c;
            border-radius: 8px;
            transform: translateY(-50%);

            .name {
                gap: 1rem;
                margin: unset !important;
                background: $primary-c !important;

                .text {
                    display: flex;
                    align-items: center;
                    margin: unset !important;
                    text-align: center;
                    font-style: normal;
                    font-size: 2rem;
                    font-weight: 700;
                }

                .edit {
                    margin: unset !important;
                }

                input {
                    margin: unset !important;
                    font-size: 2rem;
                    font-weight: 700;
                    line-height: 1.2;
                }
            }
        }

        .xp-bar-container, .set-avatar, .arrow {
            display: none !important;
        }

        .popup-container {
            position: absolute;
            bottom: -90px;
            left: -400px;
        }

        button.download,
        button.help {
            @include button(
                $type: tertiary,
                $button-bordered: true,
            );
            position: absolute !important;

            .mat-icon {
                width: 2.5rem;
                height: auto;
                aspect-ratio: 1;
            }

            &:where(.download) {
                bottom: -90px;
            }

            &:where(.help) {
                bottom: 42px;
            }
        }

        app-accessories-popup .main-container .customize-container .customize {
            background: $accent-c !important;
        }

        app-accessories-popup {

            .main-container {

                .popup-container {

                    > .popup {

                        > .popup-inner {

                            .buttons {
                                gap: 4rem;
                                padding-inline: 4rem;

                                .button {
                                    display: contents;

                                    .inner-button.save,
                                    .inner-button.reset { // Selectors increase specificity to override gamification module default style
                                        @include button(
                                            $type: tertiary,
                                            $size: s,
                                            $button-bordered: true,
                                        );
                                        flex: auto;
                                        justify-content: center;
                                        margin: unset;
                                        border-radius: 80px;
                                        text-transform: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        app-accessories-popup .main-container .popup-container > .popup > .popup-inner > .tabs > .tab {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #3A4354;
            text-align: center;
        }

        app-accessories-popup .main-container .popup-container > .popup > .popup-inner > .tabs {
            background: #FFFFFF;
        }

        app-accessories-popup .main-container .popup-container > .popup > .popup-inner > .tabs > .tab.active {
            color: #FFFFFF;
        }

        app-accessories-popup .main-container .popup-container > .popup > .close {
            @include button(
                $type: tertiary,
                $icon-full: true,
                $button-bordered: true,
            );
            position: absolute;
            z-index: 10;
            top: 0;
            right: 0;
            padding: 0.8rem;
            border-radius: 50%;
            transform: translate(50%, -50%);
            
            .mat-icon {
                width: 2.2rem !important;
                height: auto !important;
                aspect-ratio: 1;
            }

            &:active,
            &[disabled] {
                transform: translate(50%, calc(-50% + 1px));
            }
        }

        .container.selected {
            border-color: $secondary-c !important;
        }

        .pop-up-opened {
            .popup-container {
                position: absolute;
                left: -400px !important;
                top: -220px;

                .tabs {
                    z-index: 2; // solve mistake on menu click when univers are scroll
                }
            }

            app-accessories-popup {
                height: 100%;
                position: fixed;
                left: 15px;

                .ps__rail-y .ps__thumb-y {
                    background-color: $secondary-c !important;
                }

                .items-container {
                    max-height: 70vh !important;

                    app-accessory-thumbnail {
                        .container {
                            box-shadow: 0 16px 23px rgba(0, 0, 0, 0.04), inset 0 7px 12px #FFFFFF;
                        }

                        .locked-icon {
                            height: 30px !important;
                            width: 30px !important;
                        }

                        .unlockable {
                            .unlock {
                                flex-direction: row !important;
                                align-items: center !important;
                                justify-content: space-around !important;
                                background: $primary-c !important;
                                border: 1px solid rgba(10, 0, 0, 0.04) !important;
                                border-radius: 24px !important;
                                height: 20px !important;
                                width: auto !important;
                                padding-left: 5px;

                                mat-icon {
                                    width: 16px !important;
                                    height: auto !important;
                                    margin-left: 3px;
                                }
                            }
                        }
                    }
                }

                .main-container .popup-container > .popup > .popup-inner > .items-container > .items.accessories {
                    display: flex !important;
                    flex-direction: row !important;
                    flex-wrap: wrap !important;
                    width: 400px !important;
                    justify-content: space-between !important;
                }

                .main-container .popup-container > .popup > .popup-inner > .items-container > .items.universes app-accessory-thumbnail {
                    width: 400px !important;
                }
            }
        }

        .pop-up-closed {
            app-accessories-popup {
                height: auto;
                width: 300px;
            }

            .main-container {
                width: 80px;
                height: 80px;
            }

            mat-icon {
                height: 38px !important;
                width: 38px !important;
            }
        }

        app-accessories-popup {
            .popup-container {
                width: auto !important;
                border-radius: 15px;
                left: 0;
                padding-right: 50px;
                background: transparent;
                align-items: flex-start;
                display: block !important;
                top: 0;
                padding-top: 50px;

                .customize {
                    background: $secondary-c !important;
                }

                .popup {
                    width: auto !important;
                    height: auto !important;
                    background: #E8EDF6;

                    .popup-inner > .tabs > .tab.active {
                        background: $secondary-c !important;
                    }
                }

                .popup-inner {
                    border: 3px solid $primary-c !important;
                }
            }

            .customize-container {
                align-items: center !important;
                flex-direction: column !important;
                width: 80px;
                height: 80px;
                background: #FFFFFF;
                border-radius: 15px;
            }
        }
    }

    app-buy-popup {
        .container {
            padding: 0 0 20px 0 !important;
        }

        .close-button-container {
            background: $primary-c;
            color: white;
            border-radius: 10px 10px 0 0;
            padding: 5px 5px 0px 5px;
        }

        .button-container {
            .button {
                background: $secondary-c !important;
                border: 1px solid rgba(0, 0, 0, 0.1) !important;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
                border-radius: 5px !important;
            }
        }
    }
}

@media (max-width: 1300px) {
    gamification {
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-mdc-tab-body-content {
            padding: 75px 15% 150px 15% !important;
        }
        app-animal-controls .popup-container {
            left: -15%;
        }

        app-animal-controls .pop-up-opened .popup-container {
            position: absolute;
            left: -15% !important;
            top: -180px;
        }
    }
}

@media (max-height: 800px) {
    gamification {
        app-accessories-popup .main-container .popup-container > .popup > .popup-inner > .items-container {
            max-height: 50vh !important;
        }
    }
}@media (max-height: 500px) {
    gamification {
        app-accessories-popup .main-container .popup-container > .popup > .popup-inner > .items-container {
            max-height: 40vh !important;
        }
    }
}

// safari only position absolute handling
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    gamification app-animal-controls .pop-up-opened .popup-container {
        position: absolute;
        left: 0px !important;
        top: 100px !important;
    }
}}