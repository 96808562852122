[svgicon="brand"] { // Sidebar

    .brand-background {
        fill: map_get($mat-fusedark, 500);
    }
}

[svgicon="brand_square"] { // Login

    .brand-text-ia {
        color: map_get($mat-fusedark, 500);
    }

    .brand-background {
        fill: map_get($mat-white, 500);
    }
}