@use '@angular/material' as mat;
.mat-mdc-chip.mat-mdc-standard-chip {
    background-color: mat.get-color-from-palette($accent);
    color: mat.get-color-from-palette($mat-white, 500);

    .mat-icon {
        background-color: white;
        opacity: 1;
        color: #fcb731;
        svg {
            transform: scale(0.5);
        }
    }

}