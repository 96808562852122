﻿@import "../variables";

footer {
    .mat-toolbar {
        color: white !important;
        background-color: #364F6B !important;
        height: 40px;
        
        .horizontal-style-1 {
            height: 32px;
            min-height: 32px;
            max-height: 32px;
            
            .nav.horizontal {
                justify-content: center;
                
                & > .nav-item > .nav-link {
                    font-size: 11px;
                    height: 32px;
                    padding: 0 18px;
                    
                    .mat-icon {
                        margin-right: 9px;
                    }
                }
            }
        }
    }
}