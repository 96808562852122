// --------------------------------------------------
// MY-PROFILE CHILD SECTION LAYOUT
// --------------------------------------------------

/**
 * This shared layout mixin is used by my-profile component child sections (open-badges-list & reward-items components & .my-profile__achievements element).
*/

@mixin my-profile__child-section(
    $my-profile__child-title,
    $my-profile__child-list,
    $my-profile__child-card,
    $my-profile__child-card--locked,
    $my-profile__child-locked-card-icon,
    ) {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    #{$my-profile__child-title} {
        margin: unset;
        font-size: 1.8rem;
        font-weight: 700;
    }

    #{$my-profile__child-list} {
        display: grid;
        justify-items: center;
        padding: unset; // Disable browser default list style
        margin: unset; // Disable browser default list style
        list-style: none; // Disable browser default list style

        #{$my-profile__child-card} {
            overflow: hidden;
            width: 100%;
            height: auto;
            cursor: pointer;

            &#{$my-profile__child-card--locked} {
                position: relative;

                &::before {
                    position: absolute;
                    z-index: 0;
                    inset: 0;
                    content: '';
                    background-color: rgba(0, 0, 0, 0.5);
                    pointer-events: none;
                }

                #{$my-profile__child-locked-card-icon} {
                    position: absolute;
                    z-index: 1;
                    inset: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: auto;
                    aspect-ratio: 1;
                    margin: auto;
                    color: white;
                    background-color: $secondary-c;
                    border-radius: 50%;
                    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);

                    svg {
                        width: 70%;
                        height: auto;
                        aspect-ratio: 1;
                        filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
                    }
                }
            }
        }
    }
}