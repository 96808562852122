app-welcome {
    background: transparent url('/assets/amazon_python/images/backgrounds/onboarding_welcome.png') no-repeat 50% 50%;

    h1 {
        /* <Hello world/> */
        position: relative;
        z-index: 0;
        margin: 0;

        font-weight: 900;
        font-size: 46px;
        line-height: 112px;
        display: flex;
        align-items: center;
        text-align: center;

        /* Orange Amazon */
        color: $accent-c;

        @include media-breakpoint-up('sm') {
            font-size: 96px;
        }

        /* Cartridge under title */
        &:after {
            content: '';
            display: block;
            width: calc(100% - 30px);
            height: calc(100% + -20px);
            margin-left: 20px;
            margin-right: 20px;
            background-color: white;
            position: absolute;
            z-index: -1;
            border: 0.5px solid rgba(198, 204, 215, 0.9);
            box-shadow: 0 9px 13px rgb(0 0 0 / 4%), inset 0 4px 7px #ffffff;
            border-radius: 15px;
            @include media-breakpoint-up('sm') {
                width: calc(100% - 40px);
                height: calc(100% + 40px);
            }
        }
    }

    p {
        /* Rectangle description */
        position: relative;
        min-height: 100px;
        padding: 35px 16%;
        margin-left: 70px;
        margin-right: 70px;
        /* Bleu Amazon */
        background: $primary-c;
        /* Orange Amazon */
        border: 3px solid $accent-c;
        border-radius: 5px;
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-align: center;

        @include media-breakpoint-up('sm') {
            font-size: 24px;
        }

        /* Blanc */
        color: #ffffff;
        min-height: 100px;

        &:before,
        &:after {
            position: absolute;
            content: '';
            display: block;
        }

        &:before {
            width: 199px;
            height: 242px;
            background: url(/assets/amazon_python/icons/onboarding_symboles_left.svg) no-repeat;
            background-size: 100px 130px;
            background-position: -1px 37px;
            top: -61px;
            left: -55px;
            @include media-breakpoint-up('sm') {
                background-size: 199px 242px;
                background-position: 0 0;
            }
        }

        &:after {
            width: 161px;
            height: 168px;
            background: url('/assets/amazon_python/icons/onboarding_symboles_right.svg') no-repeat;
            background-size: 101px 128px;
            background-position: 100% 100%;
            right: -64px;
            bottom: -35px;
            @include media-breakpoint-up('sm') {
                background-size: 161px 168px;
                position: absolute;
                right: -62px;
                bottom: 8px;
            }
        }
    }

    button {
        /* lancer l'experience */
        box-sizing: border-box;

        /* Orange Amazon */
        background: $accent-c;
        /* Noir 10% */
        border: 1px solid rgba(0, 0, 0, 0.1);
        /* ombre_bouton */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 15px 20px;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
    }
}