app-my-profile {

    .my-profile {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
        gap: 6rem;

        .my-profile__achievements {
            @include my-profile__child-section(
                $my-profile__child-title: '.my-profile__achievement-title',
                $my-profile__child-list: '.my-profile__achievement-list',
                $my-profile__child-card: '.my-profile__achievement-card',
                $my-profile__child-card--locked: '.my-profile__achievement-card--locked',
                $my-profile__child-locked-card-icon: '.my-profile__locked-achievement-icon',
            );

            .my-profile__achievement-title {}

            .my-profile__achievement-list {
                grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
                gap: 1.5rem;

                .my-profile__achievement-card {
                    max-width: 12rem;
                    aspect-ratio: 1;
                    background-color: $off-white;
                    background-size: cover;
                    background-position: center;
                    border-radius: 6px;

                    &.my-profile__achievement-card--locked {

                        &::before {
                            background-image: image('backgrounds/locked-achievement.svg');
                            background-size: cover;
                            background-position: center;
                            filter: grayscale(100%) blur(0.8px);
                        }

                        .my-profile__locked-achievement-icon {
                            width: 5rem;
                        }
                    }
                }
            }
        }
    }
}