// --------------------------------------------------
// BACKDROP
// --------------------------------------------------

.cdk-overlay-backdrop,
.cdk-overlay-dark-backdrop {
    background-color: rgba(0, 34, 67, 0.7);
    backdrop-filter: blur(3px);
}

// --------------------------------------------------
// GENERIC DIALOG
// --------------------------------------------------

.mat-mdc-dialog-container {

    .mat-mdc-dialog-surface {
        position: relative;
        background-color: $white;
        color: $default-font-color;
        border: none;
        overflow: hidden;
        border-radius: 5px;

        .mat-mdc-dialog-title,
        .activities-dialog-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2rem;
            min-height: unset;
            margin: unset;
            padding: 0.5rem 0.5rem 0.5rem 2rem;
            background-color: $primary-c;
            color: $default-font-contrast-color;

            h1,
            h2,
            h3 {
                margin: 1rem 0;
                font-size: 2rem;
                font-weight: 700;
                line-height: 1.2;
            }

            button {
                @include button(
                    $type: secondary,
                    $button-bordered: true,
                );
            }
        }

        .mat-mdc-dialog-title {

            &::before {
                content: none; // Remove pseudo-element generated by Material
            }
        }

        .mat-mdc-dialog-content {
            min-width: unset; // Unset default theme style
            color: inherit; // Override Material style

            &:is(.mat-mdc-dialog-content) { // Increase specificity to override Material style
                padding: 2rem 3rem;
            }
        }

        .mat-mdc-dialog-actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            max-height: unset;
            min-height: unset;
            padding: 1rem 3rem 2rem 3rem;

            button {
                @include button(
                    $type: tertiary,
                    $button-bordered: true,
                );
            }
        }
    }
}

// --------------------------------------------------
// DIALOG CONTAINING MAT-CARD ELEMENT (e.g. app-mobile-redirection-modal)
// --------------------------------------------------

.mat-mdc-dialog-container {

    .mat-mdc-dialog-surface {        

        .mat-mdc-card {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            padding: 2rem 3rem;
            background-color: $white;
            color: $default-font-color;
            border-radius: unset; // Override Material style

            .mat-mdc-card-content {
                margin: unset; // Override Material style
            }

            .mat-mdc-card-footer {
                margin: unset; // Override Material style

                .mat-mdc-dialog-actions {
                    padding: unset; // Override generic dialog style
                }
            }
        }
    }
}

// --------------------------------------------------
// ONBOARD DIALOG (may contain newsletter-form component)
// --------------------------------------------------

.onboard-dialog {

    .mat-mdc-dialog-surface {

        app-modal-page {

            .mat-mdc-dialog-content {

                app-basic-page {

                    .page-layout {
                        overflow: unset; // Unset Fuse style to allow mat-ripple effect in newsletter-form
                        padding: unset; // Unset basic-page component style

                        .content {

                            p {
                                font-size: 1.4rem; // Override default theme style
                                font-weight: 400; // Override default theme style
                            }

                            app-newsletter-form {

                                .actions {
                                    display: flex;
                                    justify-content: center;
                                    gap: 2rem;

                                    button {
                                        @include button(
                                            $type: tertiary,
                                            $button-bordered: true,
                                        );
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// --------------------------------------------------
// EDIT LESSON DIALOG (deprecated?)
// --------------------------------------------------

app-edit-lesson-dialog {

    .mat-mdc-form-field-infix {

        mat-select-value {
            min-height: 44px;
            font-family: Quicksand;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #000000;
        }
    }
}

// --------------------------------------------------
// ACTIVITY LIST DIALOG (deprecated?)
// --------------------------------------------------

.activities-list-dialog {

    .mat-mdc-dialog-surface {
        height: 95%;
        margin-top: 1%;
        padding: 0;

        .activities-dialog-title {

            button {
                margin-left: auto;
            }
        }

        .mat-mdc-dialog-content {
            flex-direction: row;
        }

        .multi-content {
            flex: 1;

            .mat-mdc-card.activities-card {

                .mat-mdc-card-header {
                    display: none;
                }

                .mat-mdc-card-content {
                    flex: 1;
                    height: 100%;
                }
            }
        }

        .preview-wrapper,
        .next-wrapper {
            min-width: 60px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            align-content: stretch;
            flex: none !important;

            .preview,
            .next {
                padding: 0;
                margin: 0;
                width: 32px;
                height: 32px;
                background-color: $accent-c;

                &[disabled] {
                    display: none;
                }
            }
        }
    }
}

// --------------------------------------------------
// EDIT ASSIGNMENT COMMENT MODAL (deprecated?)
// --------------------------------------------------

.edit_assignment_comment_modal {

    .mat-mdc-dialog-container .mat-mdc-dialog-surface {

        .mat-mdc-dialog-actions {
            flex-direction: row-reverse; // Reverse cancel and validation buttons
        }
    }
}

// --------------------------------------------------
// FORM DIALOG
// --------------------------------------------------

.form-dialog-wrapper {

    .mat-mdc-dialog-surface {
        overflow: visible;
        background-color: transparent;

        app-form-dialog {
            color: $form-dialog--color;
            background-color: $form-dialog--background-color;
            font-size: 1.6rem;
            border-radius: 10px;

            .form-dialog__header {
                min-height: unset;
                margin: unset;
                padding: unset;

                .form-dialog__title {
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: translate(-25px, -50%);
                    display: flex;
                    align-items: center;
                    margin: unset;
                    color: inherit;
                    font-size: 2rem;
                    font-weight: 700;
                    line-height: 1;

                    > span {
                        padding: 10px 20px;
                        background-color: $form-dialog--accent-color;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }

                    &::before {
                        content: '?';
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50px;
                        height: auto;
                        aspect-ratio: 1;
                        background-color: $form-dialog--accent-color;
                        border: 2px solid $form-dialog--color;
                        border-radius: 5px;
                        font-size: 3rem;
                        font-weight: 900;
                    }
                }

                .form-dialog__close-button {
                    @include button(
                        $type: tertiary,
                        $button-bordered: true,
                    );
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translate(50%, -50%);

                    &:active {
                        transform: translate(50%, calc(-50% + 1px));
                    }
                }
            }

            .form-dialog__content,
            .form-dialog__actions {
                background-color: inherit;
            }

            .form-dialog__content {
                min-width: unset;
                padding: 40px 20px 10px;
                border-top: 3px solid $form-dialog--accent-color;
                border-right: 3px solid $form-dialog--accent-color;
                border-left: 3px solid $form-dialog--accent-color;
                border-top-left-radius: inherit;
                border-top-right-radius: inherit;

                * {
                    color: inherit;
                }

                .form-dialog__form {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .form-dialog__fieldset {
                        display: flex;
                        flex-direction: column;
                        margin: unset;
                        padding: unset;

                        .form-dialog__legend {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                            width: 100%;
                            margin: unset;
                            font-weight: 700;

                            pre {
                                align-self: center;
                                width: unset;
                                margin: unset;
                                padding: 10px;
                                border: 1px solid $form-dialog__pre--border-color;
                            }
                        }

                        .form-dialog__field {
                            position: unset;
                            flex: unset;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 10px;

                            .form-dialog__input {
                                position: unset;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 15px;
                                height: auto;
                                aspect-ratio: 1;
                                appearance: none;
                                opacity: unset;
                                background-color: transparent;
                                border: 2px solid $form-dialog--color;
                                border-radius: 3px;
                                transition: background-color 100ms ease-in-out;

                                &:checked {
                                    background-color: $form-dialog--accent-color;
                                }
                            }

                            .form-dialog__label {
                                padding: unset;
                                text-align: unset;

                                &::before,
                                &::after {
                                    content: none;
                                }
                            }
                        }

                        .form-dialog__legend + .form-dialog__field,
                        .form-dialog__field + .form-dialog__field {
                            margin-top: 20px;
                        }
                    }
                }
            }

            .form-dialog__actions {
                padding: 10px 20px 20px;
                border-bottom: 3px solid $form-dialog--accent-color;
                border-right: 3px solid $form-dialog--accent-color;
                border-left: 3px solid $form-dialog--accent-color;
                border-bottom-left-radius: inherit;
                border-bottom-right-radius: inherit;

                .form-dialog__submit-button {
                    @include button(
                        $type: tertiary,
                        $button-bordered: true,
                    );
                }
            }
        }
    }
}

// --------------------------------------------------
// FEEDBACK DIALOG BY STEPS
// --------------------------------------------------

app-feedback-dialog-by-steps {

    .feedback-dialog-by-steps__header {

        h2.feedback-dialog-by-steps__title {}

        .feedback-dialog-by-steps__close-button {}
    }

    .feedback-dialog-by-steps__content {
        padding: 3rem 4rem 2rem !important;
        color: $default-font-color;

        .feedback-dialog-by-steps__form {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .feedback-dialog-by-steps__fieldset-wrapper {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-auto-rows: auto;
                gap: 1rem;

                @include media-breakpoint(gt-sm) { // If the screen is greater than 960px
                    grid-template-columns: repeat(4, 1fr);
                }

                &:empty {
                    display: none;
                }

                .feedback-dialog-by-steps__fieldset {
                    display: contents; // Hide fieldset element (but not its childs)

                    .feedback-dialog-by-steps__legend {
                        grid-column: 1 / -1; // Span legend element from the first column and up to the last column
                        grid-row: 1 / span 1;
                        margin: unset; // Unset default browser style
                        color: inherit; // Override default theme style
                        font-weight: 500;
                    }

                    .feedback-dialog-by-steps__input-wrapper {

                        .feedback-dialog-by-steps__input {

                            &[type="radio"] {

                                + .feedback-dialog-by-steps__label {

                                    &::before {
                                        top: 3px;
                                        width: 14px;
                                        height: auto;
                                        aspect-ratio: 1;
                                        background-image: unset !important; // Unset default theme style
                                        border: 1px solid rgba($black, 0.05);
                                        border-radius: 50%;
                                        box-shadow: 0 0 2px rgba($black, 0.25),
                                                    inset 0 2px 4px rgba($white, 0.25);
                                        transition: background-color 100ms ease-in-out;
                                    }

                                    &:hover::before,
                                    &:focus::before {
                                        background-color: $accent-c;
                                    }

                                    &.feedback-dialog-by-steps__label--emoji {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        gap: 0.5rem;
                                        padding: unset; // Unset default theme style

                                        &::before,
                                        &::after {
                                            content: none;
                                        }

                                        .feedback-dialog-by-steps__emoji-icon {
                                            width: 3rem;
                                            height: auto;
                                            aspect-ratio: 1;
                                            transition: color 100ms ease-in-out;
                                        }

                                        &:hover,
                                        &:focus {

                                            .feedback-dialog-by-steps__emoji-icon {
                                                color: $accent-c;
                                            }
                                        }
                                    }
                                }

                                &:checked {

                                    + .feedback-dialog-by-steps__label {

                                        &::before {
                                            background-color: $accent-c;
                                        }

                                        &.feedback-dialog-by-steps__label--emoji {

                                            .feedback-dialog-by-steps__emoji-icon {
                                                color: $accent-c;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .feedback-dialog-by-steps__label {
                            color: inherit; // Override default theme style
                            cursor: pointer;
                        }
                    }
                }
            }

            h3.feedback-dialog-by-steps__title {
                margin: unset; // Unset default browser style
                font-size: 1.8rem;
                font-weight: 700;
            }

            .feedback-dialog-by-steps__paragraph {
                margin: 0 0 1rem; // Override default browser style
                color: inherit;
                font-size: 1.4rem;
                font-weight: 500;
            }
        }
    }

    .feedback-dialog-by-steps__actions {

        &:empty {
            display: none;
        }
    }
}