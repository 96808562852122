snack-bar-container > div {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 15px;
    color: #ffffff;
    background: $primary-c;
    border: 3px solid $accent-c;
    border-radius: 5px;
    position: relative;

    .header {
        position: absolute;
        top: -31px;
        left: -27px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;

        .box {
            background: $accent-c;
            border: 2px solid #ffffff;
            border-radius: 4.9539px;
            color: white;
            width: 50px;
            height: 50px;
            font-size: 36px;
            font-weight: 900;
            line-height: 42px;
            letter-spacing: 0em;
            text-align: center;
        }

        h1 {
            background: $accent-c;
            padding: 0 5px;
            background: #fbac35;
            padding: 6px 10px 6px 10px;
            border-radius: 0 4.25px 4.25px 0;
            font-size: 20px;
        }
    }

    .body {
        margin: 40px 25px 15px;
    }

    button {
        background: $accent-c;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 3.75px;
        min-height: 30px;
        min-width: 100px;
        color: white;
        padding: 7px;
    }
}

mat-snack-bar-container.mat-mdc-snack-bar-container {
    background-color: unset;
    box-shadow: unset;
}