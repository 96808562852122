app-followed-tabs {

    .followed-tabs {
        overflow: unset;

        .followed-tabs__nav {
            padding: 1.5rem 3rem 0;
        }

        .followed-tabs__panel {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    }
}