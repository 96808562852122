@use '@angular/material' as mat;
@use "sass:map";
@use 'sass:color';
@import "theme";

// --------------------------------------------------
// BUTTONS
// --------------------------------------------------

$button-padding: (
    m: 1rem 1.5rem,
    s: 0.5rem 1rem,
    my: 1rem,
    sy: 0.5rem,
    mx: 1.5rem,
    sx: 1rem,
);

$button-radius: (
    m: 6px,
    s: 6px,
);

$button-height: (
    m: unset, // Unset Material style
    s: unset, // Unset Material style
);

$button-icon: (
    m: 2.5rem,
    s: 2.5rem,
    m-full: 2.5rem,
    s-full: 2.5rem,
);

$button-gap: (
    m: 1rem,
    s: 1rem,
);

$button-border-width: 1px;

$button-primary: (
    background: $primary-c,
    color: $white,
    border: $button-border-width solid rgba($black, 0.1),
    gradient-border: null,
    border-radius: (
        m: map.get($button-radius, m),
        s: map.get($button-radius, s),
    ),
    box-shadow: 0px 4px 10px 0px rgba($black, 0.25),
    font-size: 1.4rem,
    font-weight: 700,
    line-height: 1.2,
    hover: (
        background: mat.get-color-from-palette($primary, darker),
        color: null,
        box-shadow: null,
    ),
    pressed: (
        background: mat.get-color-from-palette($primary, darker),
        color: null,
        box-shadow: null,
    ),
    disabled: (
        background: rgba($primary-c, 0.4),
        color: rgba($white, 0.4),
        box-shadow: none,
    ),
);

$button-secondary: (
    background: $secondary-c,
    color: $white,
    border: $button-border-width solid rgba($black, 0.1),
    gradient-border: null,
    border-radius: (
        m: map.get($button-radius, m),
        s: map.get($button-radius, s),
    ),
    box-shadow: 0px 4px 10px 0px rgba($black, 0.25),
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 1.2,
    hover: (
        background: mat.get-color-from-palette($secondary, darker),
        color: null,
        box-shadow: null,
    ),
    pressed: (
        background: mat.get-color-from-palette($secondary, darker),
        color: null,
        box-shadow: null,
    ),
    disabled: (
        background: rgba($secondary-c, 0.4),
        color: rgba($white, 0.4),
        box-shadow: none,
    ),
);

$button-tertiary: (
    background: $accent-c,
    color: $white,
    border: $button-border-width solid rgba($black, 0.1),
    gradient-border: null,
    border-radius: (
        m: map.get($button-radius, m),
        s: map.get($button-radius, s),
    ),
    box-shadow: 0px 4px 10px 0px rgba($black, 0.25),
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 1.2,
    hover: (
        background: mat.get-color-from-palette($accent, darker),
        color: null,
        box-shadow: null,
    ),
    pressed: (
        background: mat.get-color-from-palette($accent, darker),
        color: null,
        box-shadow: none,
    ),
    disabled: (
        background: rgba($accent-c, 0.4),
        color: rgba($white, 0.4),
        box-shadow: none,
    ),
);

$button-quaternary: (
    background: $white,
    color: $accent-c,
    border: $button-border-width solid $accent-c,
    gradient-border: null,
    border-radius: (
        m: map.get($button-radius, m),
        s: map.get($button-radius, s),
    ),
    box-shadow: 0px 4px 10px 0px rgba($black, 0.25),
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 1.2,
    hover: (
        background: color.scale($white, $lightness: -4%),
        color: null,
        box-shadow: null,
    ),
    pressed: (
        background: color.scale($white, $lightness: -4%),
        color: null,
        box-shadow: none,
    ),
    disabled: (
        background: rgba($white, 0.4),
        color: rgba($accent-c, 0.4),
        box-shadow: none,
    ),
);

$button-quinary: (
    background: $white,
    color: $primary-c,
    border: null,
    gradient-border: null,
    border-radius: (
        m: map.get($button-radius, m),
        s: map.get($button-radius, s),
    ),
    box-shadow: 0px 4px 10px 0px rgba($black, 0.25),
    font-size: 1.6rem,
    font-weight: 700,
    line-height: 1.2,
    hover: (
        background: color.scale($white, $lightness: -4%),
        color: null,
        box-shadow: null,
    ),
    pressed: (
        background: color.scale($white, $lightness: -4%),
        color: null,
        box-shadow: none,
    ),
    disabled: (
        background: rgba($white, 0.4),
        color: rgba($primary-c, 0.4),
        box-shadow: none,
    ),
);

$button-type: (
    primary: $button-primary,
    secondary: $button-secondary,
    tertiary: $button-tertiary,
    quaternary: $button-quaternary,
    quinary: $button-quinary,
);