// --------------------------------------------------
// MIXINS
// --------------------------------------------------

@mixin registration-dialog__group-field-title {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
}

@mixin registration-dialog__field-label {
    color: $contest--font-color;
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-registration-dialog {
    color: $contest--font-color;

    .contest-registration-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding: 20px;

        .contest-registration-dialog__title {
            margin: 0;
        }

        .contest-registration-dialog__close-button {
            @include button(
                $type: secondary,
                $button-bordered: true,
            );
        }
    }

    .contest-registration-dialog__content {

        .contest-registration-dialog__form {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 20px 20px 0 20px;

            .contest-registration-dialog__institution {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .contest-registration-dialog__institution-title {
                    @include registration-dialog__group-field-title;
                }

                .contest-registration-dialog__institution-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;

                    .contest-registration-dialog__postal-code-field {
                        flex-grow: 1;

                        .mat-form-field-label {
                            @include registration-dialog__field-label;
                        }
                    }

                    .contest-registration-dialog__institution-name-field {
                        flex-grow: 3;

                        .mat-form-field-label {
                            @include registration-dialog__field-label;
                        }
                    }
                }
            }

            .contest-registration-dialog__groups {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .contest-registration-dialog__groups-title {
                    @include registration-dialog__group-field-title;
                }

                .contest-registration-dialog__groups-wrapper {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(max-content, 200px));
                    gap: 10px;
                    padding-bottom: 20px;

                    .contest-registration-dialog__group-checkbox {
                        padding: 5px 10px;
                        color: $default-font-contrast-color;
                        background-color: $accent-c;
                        border: 1px solid rgba(0, 0, 0, 0.10);
                        border-radius: 5px;
                        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
                        cursor: pointer;
                        font-size: 1.6rem;
                        font-weight: 700;
                        line-height: normal;

                        .mdc-form-field {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 20px;

                            .contest-registration-dialog__group-label {
                                display: grid;
                                grid: 'avatar name' auto
                                      'avatar learners' auto
                                      / auto auto;
                                align-items: center;
                                column-gap: 10px;

                                .contest-registration-dialog__group-avatar {
                                    grid-area: avatar;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 32px;
                                    height: 32px;
                                    color: $contest--font-color;
                                    background-color: $default-font-contrast-color;
                                    border-radius: 50%;
                                    text-transform: uppercase;
                                }

                                .contest-registration-dialog__group-name {
                                    grid-area: name;
                                }

                                .contest-registration-dialog__group-learners {
                                    grid-area: learners;
                                    display: flex;
                                    align-items: center;
                                    gap: 5px;
                                    font-size: 1.4rem;

                                    mat-icon {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 16px;
                                        min-width: unset;
                                        height: 16px;
                                        min-height: unset;
                                    }
                                }
                            }

                            .mdc-checkbox {
                                width: 20px;
                                height: 20px;
                                margin: 0;

                                .mdc-checkbox__background {
                                    border: 2px solid $default-font-contrast-color;
                                }
                            }
                        }
                    
                        &.mat-mdc-checkbox-checked {

                            .mdc-checkbox__background {
                                background-color: $default-font-contrast-color;

                                svg path {
                                    stroke: $accent-c !important;
                                }
                            }
                        }
                    }
                }
            }

            .contest-registration-dialog__sponsor {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .contest-registration-dialog__sponsor-title {
                    @include registration-dialog__group-field-title;
                }

                .contest-registration-dialog__sponsor-code-field {
                    width: 100%;

                    .mat-form-field-label {
                        @include registration-dialog__field-label;
                    }
                }
            }

            .contest-registration-dialog__consent {
                .mdc-label {
                    white-space: normal;
                }
            }
        }
    }

    .contest-registration-dialog__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding: 10px 20px 20px 20px;

        .contest-registration-dialog__required-fields-info {
            margin: 0;
            font-size: 1.4rem;
        }

        .contest-registration-dialog__validate-button {
            @include button(
                $type: tertiary,
                $button-bordered: true,
            );

            .mat-mdc-progress-spinner circle {
                stroke: $default-font-contrast-color;
            }
        }
    }
}