// --------------------------------------------------
// MIXINS
// --------------------------------------------------

@mixin contest-detail__item-illustration--background($image) {
    background-image: linear-gradient(355deg, rgba(17, 28, 64, 0.95) 45.69%,
                      rgba(16, 26, 64, 0.50) 56.41%),
                      url($image);
    background-size: cover;
}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-contest-detail {
    color: $default-font-contrast-color;

    .contest-detail__wrapper {
        border-radius: 10px;
        box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.25);

        .contest-detail__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 20px;
            padding: 20px;
            background-image: $contest__content--background-image;
            background-position: 0% 30%;
            background-size: 300%;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            &.contest-detail__content--border-radius {
                border-radius: 10px;
            }

            @include media-breakpoint(gt-xs) {
                gap: 40px;
                padding: 40px;
            }

            .contest-detail__title {
                margin: 0;
                font-size: 4rem;
                font-style: italic;
                font-weight: 900;
                text-align: center;
                text-shadow: $contest__generic-element--text-shadow;
            }

            .contest-detail__primary-content {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 20px;

                @include media-breakpoint(gt-xs) {
                    gap: 40px;
                }

                .contest-detail__goals,
                .contest-detail__timer {
                    flex: 1 1 0;
                    width: 50%;
                    min-width: 300px;
                }

                .contest-detail__goals {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 20px;
                    border-left: 3px solid $contest--secondary-accent-color;
                    border-right: 3px solid $contest--secondary-accent-color;
                    border-bottom: 3px solid $contest--secondary-accent-color;
                    border-radius: 10px;
                    box-shadow: $contest__generic-element--box-shadow;

                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        height: 100%;
                        border-top: 3px solid $contest--secondary-accent-color;
                    }

                    &:before {
                        left: -3px;
                        width: calc(10% - 18px);
                        border-top-left-radius: inherit;
                    }

                    &:after {
                        right: -3px;
                        width: calc(90% - 18px);
                        border-top-right-radius: inherit;
                    }

                    mat-icon {
                        position: absolute;
                        top: 0;
                        left: 10%;
                        transform: translate(-50%, -50%);
                        width: 32px;
                        height: 32px;
                        color: $contest--secondary-accent-color;
                        background: inherit;
                    }

                    h3 {
                        margin: 0;
                        color: $contest--secondary-accent-color;
                        font-weight: 700;
                        text-transform: uppercase;
                    }

                    p {
                        margin: 0;
                    }
                }

                .contest-detail__timer {

                    .contest-timer__content {
                        font-size: 6.6rem;
                    }
                    
                    .contest-timer__footer {
                        color: $contest--secondary-accent-color;
                    }
                }
            }

            .contest-detail__secondary-content {
                position: relative;
                z-index: 0;
                display: grid;
                gap: 20px;
                margin: 20px auto;

                @include media-breakpoint(gt-xs) {
                    margin: 40px auto;
                }

                .contest-detail__rewards {
                    grid-column: 1 / span 1;
                    grid-row: 1 / span 1;
                    justify-self: end;
                    align-self: start;
                    min-width: 200px;
                    max-width: 250px;

                    h3 {
                        margin: 0;
                        color: $contest--secondary-accent-color;
                        font-weight: 700;
                        text-transform: uppercase;
                    }

                    p {
                        margin: 0;
                    }
                }

                .contest-detail__progress-count {
                    grid-column: 1 / span 2;
                    grid-row: 2 / span 2;
                    justify-self: end;
                    align-self: end;
                    padding-top: 10px;
                    font-family: $font-family2;
                    font-size: 24rem;
                    line-height: 0.7;
                    text-transform: uppercase;
                    letter-spacing: -1.2rem;
                    text-shadow: $contest__generic-element--text-shadow;
                }

                .contest-detail__progress-wrapper {
                    grid-column: 3 / span 1;
                    grid-row: 3 / span 1;
                    justify-self: start;
                    align-self: end;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .contest-detail__progress-score {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        padding: 10px;
                        color: $contest--secondary-accent-color;
                        background-color: $contest__generic-element--background-color--transparent;
                        border: 3px solid $contest--secondary-accent-color;
                        border-radius: 8px;
                        box-shadow: 0px 14px 23px 0px rgba(0, 0, 0, 0.25),
                                    0px 0px 12px 0px $contest--secondary-accent-color;
                        backdrop-filter: blur(5px);
                        font-size: 2rem;
                        font-weight: 700;
                        line-height: normal;

                        .contest-header__progress-max-score {
                            opacity: 0.5;
                        }

                        mat-icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 16px;
                            min-width: unset;
                            height: 16px;
                            min-height: unset;
                        }
                    }

                    .contest-detail__progress-bar {
                        position: relative;
                        appearance: none;
                        width: 100%;
                        height: 12px;
                        background-color: transparent;
                        border: 3px solid $contest--secondary-accent-color;
                        border-radius: 100px;
                        box-shadow: $contest-detail__progress-bar--box-shadow;
                        backdrop-filter: blur(5px);

                        &::-webkit-progress-bar, { // Chrome & Safari styling (non-standard feature)
                            background-color: transparent;
                            backdrop-filter: blur(5px);
                        }

                        &::-moz-progress-bar { // Firefox styling (non-standard feature)
                            background-color: $contest-detail__progress-bar--color;
                            border-radius: 100px;
                        }

                        &::-webkit-progress-value { // Chrome & Safari styling (non-standard feature)
                            background-color: $contest-detail__progress-bar--color;
                            border-radius: 100px;
                        }
                    }

                    .contest-detail__progress-marker {
                        position: absolute;
                        bottom: -3px;
                        left: 100%;
                        transform: translateX(-50%);
                        width: 12px;
                        height: 18px;
                        background-color: $contest__generic-element--background-color;
                        border: 3px solid $contest--secondary-accent-color;
                        border-radius: 100px;
                        box-shadow: $contest-detail__progress-bar--box-shadow;

                        &.contest-detail__progress-marker--completed {
                            background-color: $contest--tertiary-accent-color;
                        }
                    }

                    .contest-detail__progress-cursor {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transform: translate(-50%, 90%);
                        width: 40px;
                        min-width: unset;
                        height: 40px;
                        min-height: unset;
                        color: $contest--secondary-accent-color;
                    }
                }

                .contest-detail__radio-image {
                    grid-column: 2 / span 2;
                    grid-row: 1 / span 3;
                    justify-self: end;
                    align-self: center;
                    position: relative;
                    z-index: -1;
                    width: 100%;
                    max-width: 250px;
                    filter: hue-rotate(45deg);
                }

                .contest-detail__tickets {
                    grid-column: 1 / span 3;
                    grid-row: 4 / span 1;
                    justify-self: center;
                    align-self: end;
                    display: flex;
                    gap: 20px;
                    margin: 20px 0 0 0;

                    @include media-breakpoint(gt-md) {
                        grid-column: 4 / span 1;
                        grid-row: 3 / span 1;
                        justify-self: start;
                        margin: 0 0 0 20px;
                    }

                    .contest-detail__tickets-count {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 5px 20px;
                        color: $contest--primary-accent-color;
                        background-color: $contest__generic-element--background-color--transparent;
                        border: 2px solid $contest--primary-accent-color;
                        border-radius: 4px;
                        box-shadow: 0px 14px 23px 0px rgba(0, 0, 0, 0.25),
                                    0px 0px 12px 0px $contest--primary-accent-color;
                        backdrop-filter: blur(5px);
                        line-height: normal;
                        text-align: center;

                        div {

                            &:first-child {
                                font-size: 4rem;
                                font-weight: 900;
                                text-shadow: $contest__generic-element--text-shadow;
                            }

                            &:last-child {
                                font-size: 1.2rem;
                                font-weight: 700;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .contest-detail__tickets-content {
                        min-width: 150px;
                        max-width: 350px;

                        h3 {
                            margin: 0;
                            color: $contest--primary-accent-color;
                            font-weight: 700;
                        }

                        p {
                            margin: 0;
                        }
                    }
                }

                &.contest-detail__secondary-content--stretch-goals {
                    gap: 30px;

                    .contest-detail__congratulations,
                    .contest-detail__encouragement {
                        min-width: 200px;
                        max-width: 300px;
                        text-align: center;

                        h3 {
                            width: 100%;
                            min-width: max-content;
                            margin: 0;
                            font-size: 4.8rem;
                            font-weight: 700;
                            text-transform: uppercase;
                            text-shadow: $contest__generic-element--text-shadow;
                        }

                        p {
                            margin: 0;

                            mat-icon {
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                vertical-align: baseline;
                                width: 14px;
                                min-width: unset;
                                height: 14px;
                                min-height: unset;
                            }
                        }
                    }

                    .contest-detail__congratulations {
                        grid-column: 1 / span 2;
                        grid-row: 1 / span 1;
                        justify-self: end;
                        align-self: start;
                    }

                    .contest-detail__encouragement {
                        grid-column: 5 / span 2;
                        grid-row: 1 / span 1;
                        justify-self: start;
                        align-self: start;
                    }

                    .contest-detail__progress-count {
                        grid-column: 2 / span 2;
                        grid-row: 2 / span 1;
                        font-size: 10rem;
                        letter-spacing: -0.3rem;
                    }

                    .contest-detail__progress-wrapper {
                        grid-column: 4 / span 2;
                        grid-row: 2 / span 1;
                        align-self: end;
                    }

                    .contest-detail__radio-image {
                        grid-column: 3 / span 2;
                        grid-row: 1 / span 2;
                        justify-self: center;
                    }

                    .contest-detail__stretch-goals {
                        grid-column: 1 / span 6;
                        grid-row: 3 / span 1;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 60px 40px;
                        margin-top: 60px;

                        .contest-detail__stretch-goal {
                            position: relative;
                            z-index: 0;
                            width: 180px;
                            padding: 40px 10px 10px 10px;
                            border-left: 3px solid $contest--secondary-accent-color;
                            border-right: 3px solid $contest--secondary-accent-color;
                            border-bottom: 3px solid $contest--secondary-accent-color;
                            border-radius: 20px;
                            box-shadow: $contest__generic-element--box-shadow;
                            text-align: center;

                            &:before,
                            &:after {
                                content: "";
                                position: absolute;
                                top: 0;
                                width: 30px;
                                height: 100%;
                                border-top: 3px solid $contest--secondary-accent-color;
                            }

                            &:before {
                                left: -3px;
                                border-top-left-radius: inherit;
                            }

                            &:after {
                                right: -3px;
                                border-top-right-radius: inherit;
                            }

                            .contest-detail__stretch-goal-progress,
                            .contest-detail__achievement-icon,
                            .contest-detail__camera-image {
                                position: absolute;
                                z-index: 1;
                                top: 0;
                                transform: translateY(-50%);
                            }

                            .contest-detail__stretch-goal-progress,
                            .contest-detail__achievement-icon {
                                left: calc(30px - 6px);
                            }

                            .contest-detail__stretch-goal-progress {
                                padding: 5px;
                                color: $contest__generic-element--background-color;
                                background-color: $contest--secondary-accent-color;
                                border-radius: 100px;
                                box-shadow: $contest__generic-element--box-shadow;
                                font-size: 1.6rem;
                                font-weight: 700;
                                line-height: normal;
                            }

                            .contest-detail__achievement-icon {
                                width: 40px;
                                height: 40px;
                                color: $contest--tertiary-accent-color;
                            }

                            .contest-detail__camera-image {
                                right: calc(30px - 6px);
                                width: 64px;
                                height: auto;
                            }

                            h3 {
                                margin: 0;
                                color: $contest--secondary-accent-color;
                                font-weight: 700;
                                text-transform: uppercase;
                                text-shadow: 0px 0px 4px $contest--secondary-accent-color;
                            }

                            p {
                                margin: 0;
                            }

                            &.contest-detail__stretch-goal--completed {
                                border-left: 3px solid $contest--tertiary-accent-color;
                                border-right: 3px solid $contest--tertiary-accent-color;
                                border-bottom: 3px solid $contest--tertiary-accent-color;
                                box-shadow: $contest__generic-element--box-shadow-2;

                                &:before,
                                &:after {
                                    border-top: 3px solid $contest--tertiary-accent-color;
                                }

                                h3 {
                                    color: $contest--tertiary-accent-color;
                                    text-shadow: 0px 0px 4px $contest--tertiary-accent-color;
                                }
                            }
                        }
                    }
                }
            }

            .contest-detail__footer {
                display: grid;
                grid: 'courses-illustration rewards-illustration ranking-illustration' auto
                      'courses-content rewards-content ranking-content' auto
                      / 1fr 1fr 1fr;
                row-gap: 20px;
                text-align: center;

                .contest-detail__courses-illustration,
                .contest-detail__rewards-illustration,
                .contest-detail__ranking-illustration {
                    position: relative;
                    z-index: 0;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 20px 40px;
                    border: 3px solid $contest--secondary-accent-color;
                    box-shadow: $contest__generic-element--box-shadow;

                    &:before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: -50%;
                        left: -50%;
                        width: 200%;
                        height: 200%;
                        transform: rotate(-15deg);
                    }

                    mat-icon {
                        width: 80px;
                        height: 80px;
                    }

                    div {
                        font-size: 1.6rem;
                        font-weight: 700;
                        text-transform: uppercase;
                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                                     0px 0px 25px $contest--secondary-accent-color;

                        span {
                            color: $contest--secondary-accent-color;
                        }
                    }
                }

                .contest-detail__courses-illustration {
                    grid-area: courses-illustration;
                    border-radius: 10px 0 0 10px;

                    &:before {
                        @include contest-detail__item-illustration--background('/assets/amazon_python/images/backgrounds/contest-courses-background.png');
                    }
                }

                .contest-detail__rewards-illustration {
                    grid-area: rewards-illustration;
                    border-left: none;

                    &:before {
                        @include contest-detail__item-illustration--background('/assets/amazon_python/images/backgrounds/contest-rewards-background.png');
                    }
                }

                .contest-detail__ranking-illustration {
                    grid-area: ranking-illustration;
                    border-left: none;
                    border-radius: 0 10px 10px 0;

                    &:before {
                        @include contest-detail__item-illustration--background('/assets/amazon_python/images/backgrounds/contest-ranking-background.png');
                    }
                }

                .contest-detail__courses-content,
                .contest-detail__rewards-content,
                .contest-detail__ranking-content {
                    padding: 0 40px;

                    h3 {
                        margin: 0 0 5px 0;
                        color: $contest--secondary-accent-color;
                        font-weight: 700;
                        text-transform: uppercase;
                    }

                    p {
                        margin: 0;
                    }
                }

                .contest-detail__courses-content {
                    grid-area: courses-content;
                }

                .contest-detail__rewards-content {
                    grid-area: rewards-content;
                }

                .contest-detail__ranking-content {
                    grid-area: ranking-content;
                }
            }
        }
    }
}