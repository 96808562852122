.demo-end-modal-dialog-wrapper {

    .mat-mdc-dialog-surface {
        overflow: visible; // Override amazon_python theme generic dialog style
        background-color: transparent; // Override amazon_python theme generic dialog style
        box-shadow: unset; // Unset Material style

        app-demo-end-modal {
            @include rewards-dialog;
        }

    }
}