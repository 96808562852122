app-contest-card {
    color: $default-font-contrast-color;

    .contest-card__wrapper {

        .contest-card__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;
            padding: 2rem;
            background-image: $contest-card__content--background-image;
            background-position: 50% 40%;
            background-repeat: no-repeat;
            background-size: cover;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;

            @include media-breakpoint(gt-xs) {
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                gap: 4rem;
                padding: 4rem;
            }

            .contest-card__primary-content {
                display: flex;
                flex-direction: column;
                gap: 2rem;

                @include media-breakpoint(gt-xs) {
                    max-width: 60%;
                }

                .contest-card__title {
                    margin: 0;
                    font-size: 2.7rem;
                    font-style: italic;
                    font-weight: 900;
                    text-shadow: $contest__generic-element--text-shadow;
                }

                .contest-card__subtitle {
                    margin: 0;
                    font-size: 1.6rem;
                    font-weight: 700;
                }

                .contest-card__informations {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .contest-card__goals {

                        h3 {
                            margin: 0;
                            font-size: 1.8rem;
                            font-weight: 700;
                            text-transform: uppercase;
                        }

                        p {
                            margin: 0;
                            font-weight: 700;
                        }
                        
                    }

                    .contest-card__rules {

                        a {
                            color: $default-font-contrast-color;
                            text-decoration: underline;
                        }
                    }
                }
            }

            .contest-card__secondary-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 2rem;
                max-width: 40%;
                text-align: center;
                text-transform: uppercase;
                text-shadow: $contest__generic-element--text-shadow;

                .contest-card__rewards {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 0.5rem;
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    font-weight: 700;
                }
            }
        }

        .contest-card__footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            padding: 1rem;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            background-color: $contest-card__footer--background-color;
            font-weight: 700;

            @include media-breakpoint(gt-xs) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 2rem;
                padding: 2rem;
            }

            .contest-card__dates {
                display: flex;
                gap: 2rem;

                .contest-card__start-date,
                .contest-card__end-date {
                    display: flex;
                    align-items: flex-start;
                    gap: 0.5rem;

                    @include media-breakpoint(gt-xs) {
                        align-items: flex-end;
                    }
                }
            }

            .contest-card__inscription {
                display: flex;
                align-items: center;
                gap: 2rem;

                button,
                a {
                    @include button(
                        $type: tertiary,
                        $button-bordered: true,
                    );

                    .mat-mdc-progress-spinner circle {
                        stroke: $default-font-contrast-color;
                    }
                }

                a {
                    text-decoration: unset !important; // Unset browser default style
                }
            }
            
            .contest-card__shareable-code {
                position: relative;
                display: inline-flex;
                height: 44px;
                padding: 12px 20px;
                align-items: center;
                gap: 10px;
                
                border: 3px solid $contest--secondary-accent-color;
                border-radius: 6px;
                
                cursor: pointer;
            }

            .contest-card__shareable-code-tooltip {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 0;
            }
        }
    }
}