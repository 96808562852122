// --------------------------------------------------
// FLEX TABLE MIXIN
// --------------------------------------------------

/**
 * Include on table element to apply generic table style with flex layout.
*/

@mixin flex-table {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-collapse: unset;
    border-spacing: unset !important; // Unset Material style
    background-color: transparent;

    thead,
    tbody {
        display: flex;

        tr.mat-mdc-header-row,
        tr.mat-mdc-row {
            display: flex;
            gap: 2rem;
            height: unset; // Unset Material style
            border-radius: 6px;

            th.mat-mdc-header-cell,
            td.mat-mdc-cell {
                flex: 1;
                display: flex;
                align-items: center;
                padding: unset; // Unset default theme style
                border: unset; // Unset user agent default style
                font-size: $default-font-size;

                &:first-child {
                    justify-content: flex-start;
                }

                &:last-child {
                    justify-content: flex-end;
                }

                &:not(:first-child, :last-child) {
                    justify-content: center;
                }
            }
        }
    }

    thead {

        tr.mat-mdc-header-row {
            flex: 1;
            padding: 1rem 4rem;
            background-color: $primary-c;

            th.mat-mdc-header-cell {
                color: $default-font-contrast-color;
                font-weight: 700;
            }
        }
    }

    tbody {
        flex-direction: column;
        gap: 0.5rem;

        &::before {
            content: none; // Remove pseudo-element declared in default theme
        }

        tr.mat-mdc-row {
            padding: 0.5rem 4rem;
            background-color: $off-white;
            border: 1px solid #CBD1DB;

            td.mat-mdc-cell {
                color: $default-font-color; // Override Material style

                p {
                    margin: unset; // Unset user agent default style
                    font-weight: 400;
                }
            }
        }
    }
}