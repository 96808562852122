@use '@angular/material' as mat;

app-group {
    .mat-column-code {
        p {
            background-color: mat.get-color-from-palette($primary);
            display: inline-block;
            margin: 0;
            padding: 8px 27px;
            color: map_get($mat-white, 500);
            border-radius: 1.5em;
            text-transform: uppercase;
        }

        &.disabled p {
            background: rgba(104, 33, 122, 0.5);
            cursor: not-allowed;
        }
    }
}

app-users-import {
    mat-form-field {
        width: fit-content;
        min-width: 100%;

        .mat-form-field-wrapper {
            padding-bottom: 11px;
        }

        .mat-form-field-label-wrapper {
            overflow: inherit;
        }
    }
}

app-groups-management {
    > .page-layout > content, content.mat-white-bg {
        background: var(--light_grey_2, #E8EDF6) !important;
    }
}

app-graph-group-management {

    .selected-exercice {
        color: map_get(mat.$grey-palette, 500);
        // font-family: $typography-font-name2;
    }

    .statistics-button {
        @extend %mat-stroked-button-primary;
        margin-left: auto !important;
    }

}

.id-groups-form-dialog {
    .dialog-content-wrapper .mat-mdc-dialog-content form .mat-form-field-label {
        font: 500 14px/16px Quicksand, Roboto, sans-serif; // mv theme
        color: $primary-c;
    }

    // for amazon, content div has .mat-white-bg class that sets color to white
    .mat-select-value,
    input[type="text"] {
        color: $default-font-color;
    }

    .mat-mdc-chip.mat-mdc-standard-chip .mat-icon {
        background-color: transparent;
        color: white;
        opacity: 1;
    }

    .dialog-content-wrapper {

        .mat-mdc-dialog-actions {

            button {
                display: flex;
                justify-content: center;
                align-items: center;

                .mat-icon {
                    width: 2.4rem !important;
                    height: auto !important;
                    aspect-ratio: 1;
                }
            }
        }
    }
}

.mat-column-addingLearner {
    background: none !important; // mv theme
}

/*
*   Edit User dialog
*/

app-institution-users {
    display: flex;
    flex-direction: column;
    margin: 0 35px;

    button.add-user {
        background: $secondary-c;
        width: 100%;
        order: 2;
        flex: 1;
        color: white;
        align-items: center;
        display: flex;
        height: 62px;
        border-radius: 10px;
        margin-top: 5px;
        justify-content: center;
        align-content: center;
    }
    .mat-mdc-paginator {
        order: 3;
    }

    table {
        order: 1;
        margin-top: 9px !important;
        thead::after, thead::before {
            width: 100%;
            z-index: -1;
        }
        tbody {
            tr {
                td.icon-custom {
                    mat-icon {
                        width: 42px;
                        height: 30px;

                        path {
                            fill: rgba(104, 33, 122, 0.1);
                        }

                        &:first-child {
                            margin-right: 10px;
                        }

                        &.selected {
                            svg {
                                rect {
                                    fill: #68217A;
                                    fill-opacity: 1;
                                }

                                path {
                                    fill: #fff;
                                }
                            }
                        }
                    }
                }

                td.user-with-none-assigned-class-or-group {
                    color: red;
                }

                button.btn-assign-only {
                    @extend %yellow-button;
                    min-width: inherit;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    text-align: center;
                    letter-spacing: 0.4px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.edit-user-Component-form-dialog {
    mat-dialog-container .mat-mdc-dialog-surface.mat-mdc-dialog-surface {
        width: 100%;
    }
}

app-edit-user {
    .mat-mdc-card {
        background-color: #F8F8F8;

    }
    .mat-mdc-card-footer {
        button {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            /* line-height: 16px; */
            text-align: center;
            letter-spacing: 0.4px;
            color: #383838;
            border-radius: 5px;
        }
    }

    .mat-mdc-dialog-title {
        margin-bottom: 0;
        h2 {
            font-style: normal;
            font-weight: 900;
            font-size: 18px;
            line-height: 16px;
            letter-spacing: 0.4px;
        }
    }

    .mat-mdc-card-header {
        display: none;
    }

    .edit-user-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        place-content: flex-start flex-start;


        .field-container {
            height: 100%;
            flex-direction: row;
            box-sizing: border-box;
            display: flex;
            place-content: center flex-start;
            align-items: center;
            width: 100%;

            mat-label {
                flex: 2;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.4px;
                &.role-selected {
                    color: rgb(104, 33, 122);
                }
            }

            .mat-form-field {
                flex: 5;

                mat-error {
                    width: 100%;
                }

                input {
                    width: 100%;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                }

                mat-chip,
                mat-chip-option,
                mat-chip-row {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                }
            }

            div.mat-form-field {
                display: flex;
                align-items: center;

                [svgicon="educ-role"], [svgicon="admin-role"] {
                    width: 42px;
                    height: 30px;

                    svg {
                        rect {
                            fill: #CEC5D0;
                            fill-opacity: 0.2;
                        }

                        path {
                            fill: #CEC5D0;
                        }
                    }

                    path {
                        fill: rgba(104, 33, 122, 0.1);
                    }

                    &:first-child {
                        margin-right: 10px;
                    }


                    &.role-selected {
                        svg {
                            rect {
                                fill: #68217A;
                                fill-opacity: 1;
                            }

                            path {
                                fill: #fff;
                            }
                        }
                    }
                }

            }

            mat-slide-toggle {
                margin-right: 10px;
            }


        }

        .assign-to-pending-account {
            padding: 10px;
            background: rgba(56, 56, 56, 0.26);
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            text-align: center;
            letter-spacing: 0.4px;
        }
    }

    .mat-mdc-dialog-actions {
        justify-content: flex-end !important;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
    }
    .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 5px 0 15px 0;
    }
    .edit-user-container .field-container mat-label {
        padding-left: 16px;
        flex: 2;
        font-weight: bolder;
    }
}

app-institution-information {
    .container {
        display: flex;
        flex-direction: column;
        padding: 24px;
        height: calc(100% - 49px);
    }

    .header {
        position: inherit;
        min-height: 70px;
        background-color: #68217a;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: white;
        padding: 0 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.4px;
        height: 60px;
        border-top: 1px solid #E5E5E5;
        border-right: 1px solid #E5E5E5;
        border-left: 1px solid #E5E5E5;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
        border-radius: 5px 5px 0 0;

        .date {
            font-weight: 100;
            font-size: 13px;

            span {
                font-size: 13px;
                font-weight: 600;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        overflow: auto;
        background-color: #FFFFFF;
        height: 100%;
        border-bottom: 1px solid #E5E5E5;
        border-right: 1px solid #E5E5E5;
        border-left: 1px solid #E5E5E5;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
        border-radius: 0 0 5px 5px;

        .content-row {
            margin-top: auto;
            margin-bottom: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 24px 10px 13px; // 13 because mat icon have 11.5 px margin so to make 24px padding add only 13px

            span, b {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: 800;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.4px;
                color: #68217A;

                mat-icon {
                    min-width: 40px;
                    min-height: 40px;

                    rect {
                        fill: none;
                    }
                }
            }

            button {
                @extend %yellow-button;
            }

            .multiple-row {
                flex-direction: column;
                width: 100%;
            }

            .info {
                overflow-wrap: break-word;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding-left: 13px;
                flex-wrap: wrap;
                padding-top: 10px;

                span {
                    display: block;
                    font-weight: 700;
                    width: 100%;
                }

                b {
                    display: block;
                }
            }

            .toogle-container {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 15px 0 15px 0;

                .school-code {
                    text-transform: uppercase;
                    background: #68217A;
                    border: 1px solid rgba(0, 0, 0, 0.05);
                    border-radius: 100px;
                    padding: 10px 15px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    span {
                        color: white;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        text-align: center;
                        letter-spacing: 0.4px;
                    }

                    .code {
                        font-weight: 700;
                    }
                }

                .school-code-disabled, .school-code-class-disabled {
                    background: #9e9e9e;
                }
            }

            mat-slide-toggle {
                margin-right: 30px;
                padding-left: 13px;
            }
        }

        .content-row-separator {
            width: calc(100% - 20px);
            height: 1px;
            background: #E5E5E5;
            margin-left: 20px;
            padding-top: 1px;
        }
    }
}

// --------------------------------------------------
// LEARNER
// --------------------------------------------------

app-learner {
    min-height: unset !important; // Unset default theme style (needed for nested table layout)
}

// --------------------------------------------------
// GROUP LISTING
// --------------------------------------------------

module-groups-listing {

    .groups-listing {
        flex: none !important; // Override Bootstrap inline style (needed for nested table layout)
        padding: unset !important; // Unset Bootstrap inline style

        .groups-listing__content-wrapper {

            .groups-listing__content {
                flex: none !important; // Override Bootstrap inline style (needed for nested table layout)
            }
        }
    }
}

// --------------------------------------------------
// GROUP LIST
// --------------------------------------------------

fuse-groups-list {

    .groups-list {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 3rem;

        .groups-list__banner-info {

            &:empty,
            &:has(> *:empty) {
                display: none; // Do not display banner-info component if it is empty or if its first child is empty
            }
        }

        .groups-list__toolbar {
            width: min(35rem, calc(100% - 4rem));
            max-height: unset; // Unset default theme style
            min-height: unset; // Unset default theme style
            margin: 0 2rem; // Override default theme style
            padding: unset; // Unset default theme style
            background-color: $off-white;
            border: 1px solid #CBD1DB;
            border-radius: 8px;
            box-shadow: unset; // Unset amazon_python theme filter style

            .mat-toolbar-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;
                padding: 0.5rem 2rem; // Override Material style

                &:empty {
                    display: none;
                }

                &::after {
                    content: '';
                    flex: none;
                    width: 2rem;
                    height: auto;
                    aspect-ratio: 1;
                    background-color: $black;
                    mask-image: icon('search_custom.svg');
                    mask-size: contain;
                    mask-position: center;
                    mask-repeat: no-repeat;

                }

                .mat-form-field {
                    max-width: unset; // Unset amazon_python theme filter style
                    padding: unset; // Unset amazon_python theme form style


                    .mat-form-field-wrapper {
                        margin: unset; // Unset amazon_python theme filter style
                        padding: unset; // Unset amazon_python theme form style


                        .mat-form-field-flex {
                            margin: unset; // Unset amazon_python theme form style

                            .mat-form-field-infix {
                                padding: unset !important; // Unset amazon_python theme filter style
                                border: unset; // Unset amazon_python theme form style
                                font-size: 1.4rem;
                                font-weight: 600;
                                line-height: 1.2; // Unset amazon_python theme form style

                                .mat-input-element {
                                    font-size: inherit;
                                    font-weight: inherit;
                                    line-height: inherit;
                                    padding: 1rem 0 !important;
                                }

                                .mat-form-field-label-wrapper {
                                    top: 0;
                                    padding: unset; // Unset amazon_python theme form style
                                    display: flex;

                                    .mat-form-field-label {
                                        position: unset; // Unset Material style
                                        padding-left: 0.5rem;
                                        color: rgba($default-font-color, 0.75);
                                        font-size: inherit;
                                        font-weight: inherit;
                                        line-height: inherit;
                                        text-transform: unset;

                                        &.mat-form-field-empty {
                                            display: flex;
                                            align-items: center;
                                        }
                                        &:not(.mat-form-field-empty) {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        .mat-form-field-underline {
                            display: none;
                        }
                    }
                }
            }
        }

        .groups-list__table-wrapper {
            overflow: visible !important;
            flex: none !important;

            .groups-list__table {
                @include flex-table;

                thead,
                tbody {

                    tr.mat-mdc-header-row,
                    tr.mat-mdc-row {

                        th.mat-mdc-header-cell,
                        td.mat-mdc-cell {

                            &.mat-column-color {
                                flex: 0.5;
                            }

                            &.mat-column-groups {
                                flex: 2;
                            }

                            &.mat-column-expandRow,
                            &.mat-column-assignation_tab_results,
                            &.mat-column-buttons {
                                flex: none;
                                width: 3.2rem; // Override default theme style
                            }
                        }
                    }
                }

                tbody {
                    overflow-y: visible; // Needed for nested table layout

                    tr.mat-mdc-row {
                        transition: border 50ms ease-in-out,
                                    box-shadow 50ms ease-in-out;

                        td.mat-mdc-cell {

                            &.mat-column-expandRow,
                            &.mat-column-assignation_tab_results {

                                .mat-icon {
                                    @include button(
                                        $type: secondary,
                                        $size: s,
                                        $button-bordered: true,
                                        $text-hide: true,
                                        $icon-full: true
                                    );

                                    svg {
                                        width: 2rem;
                                        height: auto;
                                        aspect-ratio: 1;
                                    }
                                }
                            }

                            &.mat-column-expandRow {

                                .mat-icon {

                                    &.hide {
                                        display: none;
                                    }
                                }
                            }

                            &.mat-column-buttons {

                                button {
                                    @include button(
                                        $type: secondary,
                                        $size: s,
                                        $button-bordered: true,
                                        $icon-full: true,
                                    );

                                    mat-icon.mat-icon { // Increase specificity to override generic button style
                                        width: 2rem;
                                        height: auto;
                                        aspect-ratio: 1;
                                    }
                                }
                            }
                        }

                        &.mat-row-editing {
                            min-height: unset; // Unset default theme style
                            padding: unset;
                            background-color: transparent;
                            border: unset;

                            td.mat-column-editing {}

                            td.mat-column-addingLearner {
                                position: relative;
                                bottom: 1rem;
                                flex-direction: column;
                                margin-bottom: -1rem;
                                padding: 0.5rem 2rem;

                                .groups-list { // Nested table
                                    padding: 1rem !important; // Override default theme style
                                    background-color: rgba($primary-c, 0.1);
                                    border-radius: 0 0 6px 6px;

                                    .groups-list__table {

                                        thead {
                                            display: none;
                                        }

                                        tbody {}
                                    }
                                }
                            }
                        }

                        @supports selector(:has(+ *)) { // Check browser support for :has() pseudo-class

                            &:has(+ .mat-row-editing app-learner) { // Generic table row followed by .mat-row-editing containing app-learner component
                                z-index: 1;
                                border: 3px solid $primary-c;
                                box-shadow: 0px 4px 10px 0px rgba($black, 0.25); 
                            }
                        }
                    }
                }
            }
        }
    }
}

// --------------------------------------------------
// GROUP INLINE MODAL
// --------------------------------------------------

app-groups-inline-modal {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-height: unset !important; // Unset default theme style
    margin-inline: 2rem;

    .groups-inline-modal__actions {

        button {

            &.groups-inline-modal__add-button,
            &.groups-inline-modal__add-learner-button { // Increase specificity to override default theme style

                &:is(.groups-inline-modal__add-button,
                     .groups-inline-modal__add-learner-button) { // Increase specificity to override default theme style
                    @include button(
                        $type: tertiary,
                        $button-bordered: true,
                        $fullwidth: true,
                    );
                    flex: unset; // Unset default theme style
                    gap: 2rem;
                    height: unset; // Unset default theme style
                    padding: 1.5rem 2rem;
                }
            }
        }
    }
}

// --------------------------------------------------
// GROUP FORM DIALOG
// --------------------------------------------------

fuse-groups-form-dialog {
    margin: unset !important; // Unset default theme style
    background-color: $off-white !important; // Override default theme style
    border-radius: 8px !important; // Override default theme style
    box-shadow: 0px 4px 10px 0px rgba($black, 0.25);

    .groups-form-dialog {

        .groups-form-dialog__container {

            .groups-form-dialog__header {}

            .groups-form-dialog__content {

                .groups-form-dialog__color-button {
                    @include button(
                        $type: quinary,
                        $size: s,
                        $button-bordered: true,
                    );
                    margin-right: 1rem;

                    .groups-form-dialog__color-button-label {
                        padding: unset; // Unset default theme style
                    }
                }
            }

            .groups-form-dialog__actions {
                padding: 0 1rem !important; // Override default theme

                button {

                    &.groups-form-dialog__save-button,
                    &.groups-form-dialog__close-button { // Increase specificity to override default theme style
                        @include button(
                            $type: tertiary,
                            $size: s,
                            $button-bordered: true,
                            $icon-full: true,
                        );
                    }                                
                }
            }
        }
    }
}

// Color menu displayed outside fuse-groups-form-dialog
.groups-form-dialog__color-menu {
    min-height: unset !important; // Override Material style
    padding: 1rem 0;
    background-color: $white;
    border: 1px solid $primary-c;
}