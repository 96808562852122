app-contest-header {
    color: $default-font-contrast-color;        

    .contest-header__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 40px;
        padding: 40px;
        background-image: $contest-header--background-image;
        background-position: 50% 40%;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        .contest-header__actions {
            display: flex;
            gap: 40px;

            .contest-header__play-button {
                @include button(
                    $type: secondary,
                    $button-bordered: true,
                );
                justify-content: space-between;
                min-width: 250px;
                max-height: 80px;
                padding: 10px 20px;
                border-radius: 8px;
                font-size: 2.4rem;
                text-transform: uppercase;
                
                .mat-mdc-progress-spinner circle {
                    stroke: $default-font-contrast-color;
                }
            }

            .contest-header__actions-data {
                display: flex;
                flex-direction: column;
                gap: 5px;
                font-size: 1.6rem;

                .contest-header__remaining-time {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    color: $contest--secondary-accent-color;
                }

                .contest-header__best-player {
                    margin: 0;
                    font-weight: 700;

                    .contest-header__best-player-name {
                        color: $contest--secondary-accent-color;
                    }
                }
            }
        }

        .contest-header__score {
            display: flex;
            flex-direction: column;
            color: $contest--secondary-accent-color;
            background-color: $contest__generic-element--background-color--transparent;
            box-shadow: 0px 14px 23px 0px rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(5px);
            border: 2px solid $contest--secondary-accent-color;
            border-radius: 8px;
            font-weight: 700;

            .contest-header__user {
                border-bottom: 2px solid $contest--secondary-accent-color;
            }

            .contest-header__user,
            .contest-header__community {
                flex-grow: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                padding: 5px 10px;

                .contest-header__user-label,
                .contest-header__user-score,
                .contest-header__community-label,
                .contest-header__community-score {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .contest-header__user-max-score,
                    .contest-header__community-max-score {
                        opacity: 0.5;
                    }

                    mat-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 16px;
                        min-width: unset;
                        height: 16px;
                        min-height: unset;
                    }
                }
            }
        }
    }
}