@use '@angular/material' as mat;

// --------------------------------------------------
// BASE (to do: clean up button base style)
// --------------------------------------------------

button,
a {
    border-width: 0;

    &.mat-mdc-raised-button.mat-accent,
    &.mat-mdc-raised-button.accent {
        border-radius: 5px;
        font-weight: 700;
        @extend %mat-stroked-button-accent;
    }

    &.mat-mdc-fab,
    &.mat-mdc-mini-fab {
        width: auto;
        height: auto;

        &.mat-accent {
            background-color: inherit;

            svg path {
                fill: transparent;
            }
        }
    }
}

button.tooltip-info {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    box-shadow: none !important;

    &.mat-mdc-unelevated-button, &.mat-mdc-raised-button, &.mat-mdc-fab, &.mat-mdc-mini-fab {
        background-color: #F8F8F8;
    }

}

.btn-webapp.mat-mdc-raised-button {
    overflow: hidden;
    display: flex;
    height: 48px;
    margin: 0 40px;
    padding: 0 30px 0 10px;
    color: white;
    background: linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);
    border-radius: 1.5em;

    img {
        width: 45px;
        margin-right: 15px;
        align-self: flex-end;
    }

    span {
        font-weight: 800;
        white-space: normal;
        display: block;
        width: 100px;
        line-height: 1.5rem;
    }

    &:hover {
        background: rgb(131, 207, 255);
        background: linear-gradient(180deg, rgba(131, 207, 255, 1) 0%, rgba(0, 175, 236, 1) 50%);
    }
}

// --------------------------------------------------
// PLACEHOLDERS (to do: replace with button mixin from css-library)
// --------------------------------------------------

%mat-stroked-button {
    border-radius: 2em;
    font-weight: 700;
    box-sizing: border-box;
    margin-right: 12px;
    padding: 0 50px;
    border: 1px solid currentColor;
    @include mat.elevation(1);

    text-decoration-line: none !important;
    display: flex;
    align-items: center;

    text-transform: uppercase;

    > * {
        text-transform: uppercase;
    }
}

%mat-stroked-button-primary {
    @extend %mat-stroked-button;
    border: 1px solid $primary-c;
    color: $primary-c;
    background-color: $white;
}
%mat-stroked-button-accent {
    border: 1px solid $accent-c;
    color: $white;
    background-color: $accent-c;
}

%svgFullContent {
    justify-content: center;
    align-items: center;
    box-shadow: none;

    .mat-icon {
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
    }

    svg {
        transform: scale(1.15); // remove svg inner margin
    }
}

// base of basic button
%main-button {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    min-height: 40px;
    min-width: 230px;
    color: white;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-sizing: border-box !important;
    box-shadow: 0px 1px 0px #E3A52C, 0px 2px 2px rgba(0, 0, 0, 0.1) !important;
    border-radius: 3px !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.4px;

    mat-icon {
        min-width: 20px !important;
        min-height: 20px !important;
        margin-left: 17px;
    }
}

%yellow-button {
    @extend %main-button;
    background: #FCB731 !important;
}

%red-button {
    @extend %main-button;
    background: #FF6862 !important;
}