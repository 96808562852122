@use '@angular/material' as mat;

.count-entities {
    font: $form-field-input-font;
}

// --------------------------------------------------
// GENERIC FILTERS
// --------------------------------------------------

.filters {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    box-shadow: 0 12px 40px -25px rgba(0, 0, 0, 0.2);
    border-radius: 12px;

    .filter-date {
        flex: 2;
    }

    @extend %mat-form-field-label-on-top;

    mat-form-field.mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-input-element {
        font-size: 16px !important;
        padding: 0 !important;
    }

    input.mat-mdc-chip-input {
        width: 50px;
        flex: 1 0 110px;
    }

    mat-form-field.mat-mdc-form-field {
        flex: 1;
        max-width: 50%;
        margin: 0 !important;

        .mat-mdc-form-field-infix {
            padding: 0 0 7px 0 !important;

            .mat-mdc-input-element {
                padding: 0 8px;
            }
        }
    }

    .mat-mdc-form-field.mat-mdc-form-field-should-float,
    .mat-mdc-form-field.mat-mdc-form-field-can-float,
    .mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-wrapper {
        margin-right: 10px;

        &.field-school {
            min-width: 190px;
        }
    }

    &.isCollapsed {

        .mat-toolbar-row:first-child,
        .mat-toolbar-single-row:first-child {

            button.mat-mdc-raised-button {
                opacity: 0;
                transition: 1s opacity ease;
            }
        }
    }

    .mat-toolbar-row,
    .mat-toolbar-single-row {
        font-family: #{$font-family};

        > * {
            flex: 1;
        }

        .mat-mdc-form-field-underline::before {
            background-color: rgba(0, 0, 0, 0.4);
        }

        &:first-child {
            gap: 3%;
            justify-content: space-between;

            @include media-breakpoint(md) {
                justify-content: space-between;
            }
        }

        &:last-child {
            gap: 3%;

            app-learner &  {
                padding-right: calc( 186px + 3%); // size of above button and gap
            }
        }
    }

    .btn-collapse {
        color: $default-font-color;

        .mat-icon {
            @include sizeFix(32px);
            border: 1px solid $default-font-color;
        }

        &:not(.mat-mdc-outlined-button) {
            color: $default-font-color;
            background-color: white;
            padding: 0 0 0 20px;

        }
    }

    // TODO remove :not selector :(
    button:not(.mat-mdc-outlined-button) svg.mat-datepicker-toggle-default-icon > path:first-child {
        fill: mat.get-color-from-palette($mat-white, 500);
    }
}

// --------------------------------------------------
// SHARED-FILTERS
// --------------------------------------------------

app-shared-filters {

    .filters {        

        .mat-toolbar-row,
        .mat-toolbar-single-row {
            gap: 0 !important;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 0;
            grid-row-gap: 0;

            mat-form-field.mat-mdc-form-field {
                max-width: 100%;
            }
        }
    }
}

// --------------------------------------------------
// SEARCH-FILTERS
// --------------------------------------------------

app-search-filters {

    .search-filters__form {

        .search-filters__container {

            .search-filters__chapter-toggle {

                .search-filters__toggle-group {
                    overflow: visible;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 2rem;
                    padding-inline: 1rem;
                    border: unset; // Unset Material style
                    border-radius: unset; // Unset Material style

                    .search-filters__toggle-button-wrapper {
                        flex: none;
                        background-color: unset; // Unset Material style
                        border: unset; // Unset Material style
                        border-radius: 8px;
                        transform: skewX(-15deg);

                        .mat-button-toggle-button {
                            width: max-content;
                            margin: unset; // Unset amazon_python theme filter style
                            padding: 0.5rem 2.5rem;
                            background-color: $secondary-c;
                            color: $default-font-contrast-color;
                            border: 1px solid rgba($black, 0.1);
                            border-radius: inherit;
                            box-shadow: 0px 4px 9px rgba($black, 0.25);
                            font-size: 2.4rem;
                            font-style: italic;
                            font-weight: 900;
                            text-transform: uppercase;
                            transition: background-color 50ms ease-in-out,
                                        box-shadow 50ms ease-in-out;

                            .mat-button-toggle-label-content {
                                padding: unset; // Unset Material style
                                line-height: 1.2; // Override Material style
                                transform: skewX(15deg);
                            }
                        }

                        .mat-button-toggle-ripple {
                            border-radius: inherit;
                        }

                        &.search-filters__toggle-button-wrapper--selected {

                            .mat-button-toggle-button {
                                background-color: $primary-c;
                                box-shadow: 0 0 0 4px $secondary-c,
                                            0px 4px 4px 0px rgba($black, 0.25) inset;
                            }
                        }
                    }
                }
            }

            .search-filters__search-button {
                display: none;
            }
        }
    }
}

// --------------------------------------------------
// FOLLOWED-FILTER
// --------------------------------------------------

app-followed-filter {

    .followed-filter {

        .followed-filter__toolbar {
            margin: unset; // Unset default theme style
            padding: 2rem; // Override default theme style
            background-color: $off-white;
            color: $default-font-color; // Override Material style
            border: 1px solid #CBD1DB;
            border-radius: 8px;
            box-shadow: 0px 12px 40px -25px rgba($black, 0.5);

            .followed-filter__toolbar-row {
                padding: unset; // Unset Material style

                .followed-filter__search-button {
                    @include button(
                        $type: tertiary,
                        $button-bordered: true,
                    );
                    flex: none;
                    margin: unset !important; // Unset Bootstrap inline style
                }
            }
        }
    }
}