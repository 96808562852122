// --------------------------------------------------
// MIXINS
// --------------------------------------------------

@mixin contest-ranking__cell--layout {
    padding: 5px 10px;
    text-align: center;

    &:first-child {
        padding-left: 20px;
        text-align: left;
    }

    &:last-child {
        padding-right: 20px;
        text-align: right;
    }

    &.contest-ranking__rank-header,
    &.contest-ranking__rank-cell,
    &.contest-ranking__progress-header,
    &.contest-ranking__progress-cell {
        width: 25%;
    }

    &.contest-ranking__label-header,
    &.contest-ranking__label-cell {
        width: 50%;
    }
}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-contest-ranking {

    .contest-ranking__wrapper {
        border-radius: 10px;
        box-shadow: $contest__wrapper--box-shadow;

        .contest-ranking__content {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 10px 20px 20px 20px;
            background-image: $contest__content--background-image;
            background-position: 0% 30%;
            background-size: 300%;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            &.contest-ranking__content--border-radius {
                border-radius: 10px;
            }

            @include media-breakpoint(gt-xs) {
                padding: 30px 40px 40px 40px;
            }

            .contest-ranking__table {
                width: 100%;
                max-width: 1000px;
                margin: 0 auto;
                background-color: transparent;
                box-shadow: none;
                line-height: normal;

                thead {
                    background-color: transparent;
                    border-radius: unset;

                    .contest-ranking__table-header {

                        .contest-ranking__header-cell {
                            @include contest-ranking__cell--layout;
                            color: $contest--primary-accent-color;
                            border-bottom: unset; // Unset Material style
                            font-size: 1.6rem;
                            font-weight: 700;
                        }
                    }
                }

                tbody {
                    border-radius: 8px;
                    box-shadow: $contest-ranking__table--box-shadow;

                    &:before {
                        content: none;
                    }

                    .contest-ranking__table-row {

                        .contest-ranking__table-cell {
                            @include contest-ranking__cell--layout;
                            color: $default-font-contrast-color;
                            background-color: $contest-ranking__table-cell--background-color;
                            border-bottom: unset; // Unset Material style
                            font-weight: 700;

                            &:first-child {
                                border-left: 2px solid $contest--primary-accent-color;
                            }

                            &:last-child {
                                border-right: 2px solid $contest--primary-accent-color;
                            }

                            &.contest-ranking__rank-cell,
                            &.contest-ranking__progress-cell {
                                color: $contest--secondary-accent-color;
                                font-size: 1.6rem;
                            }

                            &.contest-ranking__label-cell {
                                font-size: 2rem;

                                .contest-ranking__label-content-wrapper {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    
                                    mat-icon {
                                        display: none;
                                    }
                                }
                            }

                            &.contest-ranking__progress-cell {

                                .contest-ranking__progress-content-wrapper {
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                    
                                    mat-icon {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        min-width: unset;
                                        width: 16px;
                                        min-height: unset;
                                        height: 16px;
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }

                        &:first-child {

                            .contest-ranking__table-cell {
                                border-top: 2px solid $contest--primary-accent-color;

                                &:first-child {
                                    border-top-left-radius: 8px;
                                }
                                
                                &:last-child {
                                    border-top-right-radius: 8px;
                                }
                            }
                        }

                        &:last-child {

                            .contest-ranking__table-cell {
                                border-bottom: 2px solid $contest--primary-accent-color;

                                &:first-child {
                                    border-bottom-left-radius: 8px;
                                }

                                &:last-child {
                                    border-bottom-right-radius: 8px;
                                }
                            }
                        }

                        &.contest-ranking__table-row--selected {
                            text-shadow: $contest__generic-element--text-shadow;
                            transform: scale(1.03);

                            &:after {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                border: 2px solid $contest--secondary-accent-color;
                                border-radius: 4px;
                                box-shadow: $contest__generic-element--box-shadow;
                            }

                            .contest-ranking__table-cell {
                                color: $default-font-contrast-color !important;
                                background-color: $contest-ranking__table-cell--background-color--selected;

                                &:first-child {
                                    padding-left: calc(20px + 1.5%);
                                    border-top-left-radius: 4px;
                                    border-bottom-left-radius: 4px;
                                }

                                &:last-child {
                                    padding-right: calc(20px + 1.5%);
                                    border-top-right-radius: 4px;
                                    border-bottom-right-radius: 4px;
                                }

                                &.contest-ranking__label-cell {
                                    width: 40%;
                                    font-size: 2rem;

                                    .contest-ranking__label-content-wrapper {
                                        justify-content: space-between;
                                        
                                        mat-icon {
                                            display: block;
                                            min-width: unset;
                                            width: auto;
                                            min-height: unset;
                                            height: 20px;
                                            color: $contest--secondary-accent-color;

                                            &:not(:first-of-type) {
                                                transform: rotate(180deg);
                                            }
                                        }
                                    }
                                }

                                &.contest-ranking__progress-cell {

                                    mat-icon {
                                        filter: drop-shadow(0px 0px 3px $default-font-contrast-color);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .contest-ranking__select-wrapper {
                display: flex;
                justify-content: center;

                .contest-ranking__select {
                    width: auto;

                    .mat-select-trigger {
                        @include button(
                            $type: tertiary,
                            $button-bordered: true,
                        );
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        height: auto;

                        .mat-select-value {
                            color: $default-font-contrast-color;
                        }

                        .mat-select-arrow-wrapper {
                            width: 24px;
                            height: 24px;
                            background-color: $default-font-contrast-color;
                            mask-image: url(/assets/amazon_python/icons/keyboard_arrow_up.svg);
                            mask-size: contain;
                            mask-repeat: no-repeat;

                            .mat-select-arrow {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}