app-collection {
    overflow: hidden !important;
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    gap: 4rem 2rem;
    padding: 2rem 3rem;
    color: $default-font-color;

    .header,
    app-presentation-general,
    app-tag-block,
    app-full-width-cta,
    app-faq-block {
        grid-column: span 4;
    }

    app-presentation-general,
    app-key-figures,
    app-half-width-cta,
    app-full-width-cta,
    app-faq-block {

        .custom-block {
            height: 100%;
            padding: 2rem 3rem;
            border: 1px solid #CBD1DB;
            background-color: #F9FBFF;
            border-radius: 5px;
            box-shadow: 0 12px 40px -25px rgba(0, 0, 0, 0.5);
        }
    }

    app-half-width-cta,
    app-full-width-cta,
    app-faq-block {

        .custom-block {

            h2 {
                margin: unset;
                font-size: 1.8rem;
                font-weight: 700;
            }
        }
    }

    .header {

        button.inverted { // Increase specificity to override Material style
            @include button(
                $type: quaternary,
                $button-bordered: true,
            );
        }
    }
    .content {
        padding-right: 2rem;
    }

    app-presentation-general{

        .custom-block {
            display: grid;
            grid-auto-columns: 3fr 1fr;
            grid-column-gap: 3rem;

            h1 {
                grid-row: 1 / span 1;
                grid-column: 1 / span 1;
                font-size: 2.4rem;
                font-weight: 700;
                margin: unset;
            }

            div {
                grid-row: 2 / span 1;
                grid-column: 1 / span 1;
            }

            img {
                grid-row: 2 / span 1;
                grid-column: 2 / span 1;
                align-self: center;
                border-radius: 5px;
            }
        }
    }

    app-tag-block {

        .custom-block {

            .mat-mdc-chip-set {

                .mdc-evolution-chip-set__chips {
                    justify-content: center;
                    gap: 1.5rem 1rem;

                    .mat-mdc-chip {
                        height: unset; // Unset Material style
                        min-height: unset; // Unset Material style
                        margin: unset; // Unset Material style
                        padding: 1rem 1.5rem;
                        background-color: $secondary-c;
                        color: $default-font-contrast-color;
                        border-radius: 20px;
                        pointer-events: none;
                    }
                }
            }
        }
    }

    app-key-figures {
        grid-column: span 4;

        @include media-breakpoint(gt-xs) {
            grid-column: span 2;
        }

        @include media-breakpoint(gt-sm) {
            grid-column: span 1;
        }

        .custom-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 3rem;
            padding: 5rem 3rem;

            span {
                display: block;
                text-align: center;
                text-transform: uppercase;

                &.key {
                    color: $secondary-c;
                    font-size: set-fluid-scale(60px, 90px);
                    font-weight: 900;
                    line-height: 1;
                }

                &:not(.key) {
                    font-weight: 700;

                    p {
                        margin: unset; // Unset default browser style
                    }
                }
            }
        }
    }

    app-half-width-cta,
    app-full-width-cta {

        .custom-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .mat-mdc-raised-button {
                @include button(
                    $type: tertiary,
                    $button-bordered: true,
                );
            }
        }
    }

    app-half-width-cta {
        grid-column: span 4;

        @include media-breakpoint(gt-sm) {
            grid-column: span 2;
        }
    }

    app-full-width-cta {

        .custom-block {}
    }

    app-faq-block {

        .custom-block {

            .mat-accordion {

                .mat-expansion-panel {
                    box-shadow: none !important;
                    background: none !important;
                    padding: 0 5px;

                    .mat-expansion-panel-header {

                        .mat-content {

                            .mat-expansion-panel-header-title {
                                color: $default-font-color; // Override Material style
                                font-weight: 700;

                                .mat-icon {
                                    display: inline-flex; // Override amazon_python theme accordion style
                                    color: $accent-c;
                                }
                            }
                        }
                    }
                }
            }
        }

        mat-expansion-panel.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header .mat-icon, mat-expansion-panel.mat-expansion-panel.mat-expanded .mat-expansion-panel-header .mat-icon {
            transform: none;
            margin-right: 0;
        }

        mat-expansion-panel.mat-expansion-panel.mat-expanded > .mat-expansion-panel-header .mat-expansion-panel-header-title > .mat-icon {
            transform: rotate(180deg);
        }

        .mat-expansion-panel-header-title,
        .mat-expansion-panel-header-description {
            display: flex;
            flex-grow: 1;
            margin-right: 0;
            align-items: center;
        }

        .mat-accordion .mat-expansion-panel {
            border-radius: 5px;
            margin: 13px 3px;
        }

        mat-panel-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header,
        mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header:hover {
            background-color: #F9FBFF !important;
            border: 1px solid $accent-c;
            border-radius: 5px;
        }

        mat-expansion-panel.mat-expansion-panel.mat-expanded .mat-expansion-panel-header {
            box-shadow: 0px 12px 13px -12px rgba(0, 0, 0, 0.25);
        }

        dl dt,
        dl dd {
            display: block;
        }

        dl dt::after {
            content: "";
        }

        .mat-expansion-panel-body {
            background: #F9FBFF;
            border: 1px solid #CBD1DB;
            border-radius: 0 0 5px 5px;
            padding: 0 1rem;
            color: $default-font-color;

            p {
                padding: 0 15px;
            }
        }

        .mat-accordion > .mat-expansion-panel-spacing:last-child, .mat-accordion > :last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
            margin-bottom: 13px;
        }

        .mat-accordion > .mat-expansion-panel-spacing:first-child, .mat-accordion > :first-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
            margin-top: 13px;
        }
    }
}