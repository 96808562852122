app-reward-items {
    display: contents;

    .reward-items {
        @include my-profile__child-section(
            $my-profile__child-title: '.reward-items__title',
            $my-profile__child-list: '.reward-items__list',
            $my-profile__child-card: '.reward-items__card',
            $my-profile__child-card--locked: '.reward-items__card--locked',
            $my-profile__child-locked-card-icon: 'null',
        );

        .reward-items__title {}

        .reward-items__list {
            grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
            gap: 2rem;

            .reward-items__card {
                max-width: 36rem;
                background-color: $off-white;
                border: 3px solid $secondary-c;
                border-radius: 12px;

                .reward-items__card-image {
                    width: 100%;
                    height: auto;
                    aspect-ratio: 1;
                    background-color: white;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-bottom-right-radius: inherit;
                    border-bottom-left-radius: inherit;
                    box-shadow: 0px 19px 27px 0px rgba(0, 0, 0, 0.04);
                }

                .reward-items__card-content {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    padding: 0.5rem 2rem;
                    text-align: center;

                    .reward-items__card-title,
                    .reward-items__card-label {
                        margin: unset;
                    }

                    .reward-items__card-title {
                        font-size: 1.6rem;
                        font-weight: 700;
                    }

                    .reward-items__card-label {}
                }

                &.reward-items__card--locked {

                    &::before {
                        backdrop-filter: blur(1.5px);
                    }

                    .reward-items__locked-card-content {
                        position: absolute;
                        z-index: 1;
                        inset: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 2rem;
                        color: white;
                        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.5));


                        .reward-items__locked-card-icon {
                            position: relative;
                            z-index: 1;
                            overflow: visible;
                            display: flex;
                            justify-content: center;
                            align-items: flex-end;
                            width: 7rem;
                            height: auto;
                            aspect-ratio: 1;

                            &::before {
                                content: '';
                                position: absolute;
                                inset: 0;
                                background-color: $secondary-c;
                                clip-path: circle(50% at 50% 75%);
                            }

                            svg {
                                width: 70%;
                                height: auto;
                                aspect-ratio: 1;
                                filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
                                transform: translateY(6px);
                            }
                        }

                        .reward-items__locked-card-label {
                            position: relative;
                            z-index: 0;
                            margin: unset;
                            padding: 1rem;
                            background-color: #364F6B;
                            border: 2px solid $secondary-c;
                            border-radius: 6px;
                            text-align: center;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}