app-root-filter {

    .root-filter {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;

        .root-filter__checkbox-container {
            @include gradient-border(
                $border-width: 1px,
                $angle: to bottom,
                $starting-color: transparent,
                $starting-color-stop: 90%,
                $ending-color: rgba(198, 204, 215, 0.9),
                $ending-color-stop: 100%,
            );
            flex: none;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2rem;
            width: 20rem;
            padding: 1rem 2rem;
            background-color: #F9FBFF;
            border-radius: 8px;
            box-shadow: 0px 11px 17px 0px rgba(0, 0, 0, 0.04),
                        0px 5px 9px 0px #FFF inset;
            cursor: pointer;

            &:has(:focus-visible) {
                @include a11y-focus-visible;
            }

            .root-filter__checkbox-label {
                @include visually-hidden;
            }

            .root-filter__checkbox-icon {
                flex: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                width: unset;
                min-width: unset;
                height: unset;
                min-height: unset;
            }

            .root-filter__checkbox-input {
                flex: none;
                display: flex;
                justify-content: center;
                align-items: center;
                appearance: none;
                width: 3rem;
                height: auto;
                aspect-ratio: 1;
                margin: unset;
                background-color: #E8EDF6;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, transparent 50%);
                background-origin: border-box;
                border: 2px solid rgba(0, 0, 0, 0.05);
                border-radius: 4px;
                cursor: inherit;
                transition: background-color 100ms ease-in-out;

                &::after {
                    content: '';
                    width: 55%;
                    height: auto;
                    aspect-ratio: 1;
                    background-color: #F9FBFF;
                    border: inherit;
                    border-radius: 2px;
                    transform: scale(0);
                    transition: transform 200ms ease-in-out;
                }

                &:checked {
                    background-color: #FC5185;

                    &::after {
                        transform: scale(1);
                    }
                }
            }
        }
    }

    app-only-model-lessons-list &,
    app-assignation-groups-detail & {
        padding: 1.5rem 3rem 0;
    }

    app-followed-tabs & {
        padding: 1.5rem 3rem;
    }
}