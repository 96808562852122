app-demo-external {

    mat-card.mat-mdc-card {
        display: flex;
        flex-direction: column;
        flex: 1;
        .mat-mdc-card-content {
            flex: 1;
        }
    }
}