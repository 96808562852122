fuse-app-lessons-tab {
    display: contents !important;

    .lessons-tab {
        flex: 1;
        overflow: unset !important; // Unset default theme style
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding: 3rem;
        color: $default-font-color;

        .lessons-tab__banner-info {

            &:empty,
            &:has(> *:empty) {
                display: none; // Do not display banner-info component if it is empty or if its first child is empty
            }
        }

        .lessons-tab__toolbar {
            overflow: visible;
            width: unset; // Unset Material style
            min-height: unset; // Unset Material style
            margin: unset; // Unset default theme style
            padding: unset; // Unset default theme style
            background-color: unset; // Unset amazon_python theme filter style
            border: unset; // Unset amazon_python theme filter style
            border-radius: unset; // Unset amazon_python theme filter style
            box-shadow: unset; // Unset amazon_python theme filter style

            &:empty {
                display: none; // Do not display toolbar element if it is empty
            }

            .lessons-tab__toolbar-row {
                padding: unset; // Unset Material style

                .lessons-tab__search-filters {}
            }
        }

        .lessons-tab__header {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 1.5rem 2rem;
            background-color: $off-white;
            border-radius: 8px;

            .lessons-tab__header-title,
            .lessons-tab__header-description {
                margin: unset; // Unset default browser style
            }

            .lessons-tab__header-title {
                font-size: 2.4rem;
                font-weight: 900;
                font-style: italic;
            }

            .lessons-tab__header-description {
                font-weight: 700;
            }
        }

        .lessons-tab__cards {
            display: grid;
            grid-template-rows: unset !important; // Unset default theme style
            grid-auto-rows: unset; // Unset default theme style
            height: unset; // Unset default theme style

            &:empty {
                display: none;
            }

            &.lessons-tab__cards--learner {
                grid-template-columns: minmax(50rem, 1fr);
                justify-items: center;
                align-items: start; // Override default theme style
                gap: 1.5rem;
                padding: 1rem max(3rem, 5%) 3rem;
                background-image: linear-gradient(to bottom, transparent 25%, $primary-c 105%),
                                  linear-gradient(to bottom, transparent 25%, #05E3AA 100%);
                border-radius: 28px;
            }

            &:not(.lessons-tab__cards--learner) {
                grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
                align-content: start;
                align-items: stretch; // Override default theme style
                gap: 2rem;
                padding: unset; // Unset default theme style

                @include media-breakpoint(gt-xs) { // Greater than 600px
                    grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));
                }

                @include media-breakpoint(gt-sm) { // Greater than 960px
                    grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
                }

                @include media-breakpoint(gt-md) { // Greater than 1280px
                    grid-template-columns: repeat(auto-fill, minmax(45rem, 1fr));
                }

                @include media-breakpoint(gt-md) { // Greater than 1920px
                    grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr));
                }
            }
        }
    }

    fuse-app-lessons-list & {

        .lessons-tab {
            padding: 3rem;

            .lessons-tab__toolbar {
                display: none !important; // Temporary (?) style to hide filters in fuse-app-lessons-list
            }
        }
    }

    app-assignation-groups-detail & {

        .lessons-tab {

            .lessons-tab__header {
                display: none;
            }

            .lessons-tab__cards {

                &.lessons-tab__cards--learner {

                    &.lessons-tab__cards--junior,
                    &.lessons-tab__cards--college,
                    &.lessons-tab__cards--python {
                        padding-top: 2rem;

                        .lessons-tab__card {

                            @include media-breakpoint(gt-lg) {                                

                                &:first-of-type {
                                    position: relative;

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        z-index: 1;
                                        top: 0;
                                        bottom: 0;
                                        left: 0;
                                        aspect-ratio: 1;
                                        background-size: auto 100%;
                                        background-position: right;
                                        background-repeat: no-repeat;
                                        pointer-events: none;
                                    }
                                }
                            }
                        }
                    }

                    &.lessons-tab__cards--junior {
                        background-image: linear-gradient(to bottom, #1798FE 9%, #68E0CF 100%);

                        .lessons-tab__card {

                            @include media-breakpoint(gt-lg) {                                

                                &:first-of-type {

                                    &::before {
                                        background-image: image('backgrounds/assignation-junior.svg');
                                        transform: translate(-80%, 15%);
                                    }
                                }
                            }
                        }
                    }

                    &.lessons-tab__cards--college {
                        background-image: linear-gradient(to bottom, #FE7964 38%, #542948 100%);

                        .lessons-tab__card {

                            @include media-breakpoint(gt-lg) {                                

                                &:first-of-type {

                                    &::before {
                                        background-image: image('backgrounds/assignation-college.svg');
                                        transform: scale(1.1) translate(-82%, 5%);
                                    }
                                }
                            }
                        }
                    }

                    &.lessons-tab__cards--python {
                        background-image: radial-gradient(65% 65% at 50% 50%, #39E07C 0%, #58A5CF 100%);
                        border: 5px solid #05A8AA;
                        box-shadow: 0px 0px 52px 0px rgba($black, 0.5) inset;

                        .lessons-tab__card {

                            @include media-breakpoint(gt-lg) {                                

                                &:first-of-type {

                                    &::before {
                                        background-image: image('backgrounds/assignation-python.svg');
                                        transform: translate(-83%, 10%);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}