app-upload-exercice {
    #header-upload-exercice {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
    }

    .content {
        border: solid;
        padding: 3rem;
        border-radius: 15px;
        color: #939598;

        .ngx-file-drop__drop-zone {
            border: 2px dotted #fcb731;
        }


    }

    mat-progress-bar {
        margin-top: 20px;
    }

    .error-upload {
        .mdc-linear-progress__bar::after {
            background: #DF5D5D;
        }

    }

    .progress-upload {
        .mdc-linear-progress__bar::after {
            background: #5DDF7A;
        }
    }
}