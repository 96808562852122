@use '@angular/material' as mat;

// --------------------------------------------------
// COLORS
// --------------------------------------------------

$primary-c: mat.get-color-from-palette($primary, default);
$secondary-c: mat.get-color-from-palette($secondary, default);
$accent-c: mat.get-color-from-palette($accent, default);
$black: #000;
$white: #FFF;
$off-white: #F9FBFF;

// --------------------------------------------------
// TYPOGRAPHY
// --------------------------------------------------

$typography-font-name: 'roboto';
$typography-font-name2: 'staatliches';
$font-family: #{$typography-font-name}, Arial, sans-serif;
$font-family2: #{$typography-font-name2}, Arial, sans-serif;
$root-em: 10px; // Root element font-size
$default-font-size: mat.get-theme-typography($theme, body-1, font-size);
$default-font-weight: mat.get-theme-typography($theme, body-1, font-weight);
$default-line-height: mat.get-theme-typography($theme, body-1, line-height);
$default-font-color: $primary-c;
$default-font-contrast-color: $white;

// --------------------------------------------------
// a11y Accessibility outline on focus when use tab
// --------------------------------------------------
$ay11-outline-focus-map-default-color: black;
$ay11-outline-focus-map-default: (
    color: $ay11-outline-focus-map-default-color,
    offset: 0.4rem,
    style: solid,
    width: 0.3rem,
    transition: 0.3s ease,
);
// --------------------------------------------------
// a11y Accessibility fields on focus when use tab
// --------------------------------------------------
$ay11-outline-field-focus-map-default-color: black;
$ay11-outline-field-focus-map-default: (
    color: $ay11-outline-field-focus-map-default-color,
    offset-inner: -0.3rem,
    style: solid,
    width: 0.3rem,
    transition: 0.3s ease,
);

$a11y-field-focus-foreground-color: black;
$a11y-field-focus-background-color: white;
$a11y-field-focus-off-foreground-color: black;
$a11y-field-focus-off-background-color: white;
$a11y-field-focus-off-font-weight: bold;

$a11y-field-focus-off-option-selected-foreground-color: black;
$a11y-field-focus-off-option-selected-background-color: map_get($mat-fusedark, 50);

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------

// ========== LINKS ==========

$link--color: $accent-c;
$link--text-decoration: none;

// ========== SCROLLBAR ==========

$perfectscrollbar-default-background: $secondary-c;
$perfectscrollbar-hover-background: $perfectscrollbar-default-background;
$perfectscrollbar-hover-width: 10px;

// ========== FORM DIALOG ==========

$form-dialog--background-color: $primary-c;
$form-dialog--color: $default-font-contrast-color;
$form-dialog--accent-color: $secondary-c;
$form-dialog__pre--border-color: #D9D9D9;

// ========== FORM FIELDS ==========
$form-field-flex-background-color: white;
$form-field-label-font-size: '14px';
$form-field-input-color: $default-font-color;
$form-field-input-label-color: $default-font-color;
$form-field-input-label-color-focused: $default-font-contrast-color;
$default-input-height: 30px;
$form-field-input-font: 500 12px/36px "Roboto", sans-serif;
$default-input-line-height: $default-input-height;
$form-field-label-font: 500 #{$form-field-label-font-size}/16px "Roboto", sans-serif;
$form-field-label-text-transform: "uppercase";
$form-field-label-font-weight: 700;
$form-field-label-top: 0;
$form-field-background-color: rgba(0, 0, 0, 0.12);
$form-field-underline-background-color: rgba(0, 0, 0, 0.4);
$form-field-underline-bottom: 10px;

$form-field-flex-border: 1px solid $default-font-color;
$form-field-flex-border-top: none;
$form-field-flex-border-bottom: none;
$form-field-flex-border-left: none;
$form-field-flex-border-right: none;
$form-field-ripple-background: mat.get-color-from-palette($accent);
$form-field-infix-padding: '0 5px 5px 0';
$form-field-infix-line-height: 2em;
$form-field-margin-bottom: 0.5em;
$form-field-select-panel-background-color: map_get($mat-white, 500);

$mat-form-field-checkbox-color: rgba(0, 0, 0, 0.5);
$mat-form-field-checkbox-background-color: map_get($mat-white, 500);
$mat-form-field-checkbox-border-radius: 4px;
$mat-form-field-checkbox-color-checked: mat.get-color-from-palette($accent);;
$mat-form-field-checkbox-background-color-checked: mat.get-color-from-palette($accent);

$mat-form-field-option-color: rgba(0, 0, 0, 0.5);

// ========== NAVBAR SIDEBAR ==========

// Navbar header
$navbar-header-icon-dimensions: 32px;
$navbar-header-icon-svg-color: map_get($mat-white, 500);

// Dimension du logo
$logo-icon-svg-color: mat.get-color-from-palette($mat-white, 500);
$logo-icon-svg-width: 142px;
$logo-icon-svg-height: 78px;

// Couleurs des élements textuels et icones
$nav-font-color: $default-font-contrast-color;
$nav-link-title-font-size: 1.6rem;
$nav-link-title-color: map_get($mat-white, 500);
$nav-link-title-color-active: map_get($mat-white, 500);
$nav-icon-color: map_get($mat-white, 500);
$nav-link-icon-color-active: map_get($mat-white, 500);
$nav-link-icon-color-hover: $nav-link-icon-color-active;

// ========== BANNER INFO ==========

$banner-info__mat-toolbar--line-height: $default-line-height;
$banner-info__mat-toolbar__paragraph--font-weight: $default-font-weight;
$app-banner-info--background-color: $off-white;

// --------------------------------------------------
// MODULES
// --------------------------------------------------

// ========== AUTHENTICATION ==========

//fuse-login and register
$fuse-authentication-padding: '32px 32px 68px 32px ';
$fuse-authentication-color: $default-font-contrast-color;
$fuse-authentication-field-input-font: 500 14px/32px "Roboto", sans-serif;
$fuse-authentication-field-input-color: $default-font-color;
$form-field-label-height: 1em;
$fuse-authentication-border-radius: 20px;
$fuse-authentication-field-label-color: $default-font-contrast-color;
$fuse-authentication-field-infix-line-height: 32px;//'2em';
$fuse-authentication-field-infix-padding: '0 15px 0 15px';
$fuse-authentication-field-flex-border: none;
$fuse-authentication-field-infix-border-radius: 5px;
$fuse-authentication-background: linear-gradient(180deg, #83DFFF 0%, #00AFEC 100%);

$fuse-authentication__label--color: white;
$fuse-authentication__label--float--color: white;

$fuse-authentication-link-decoration: "underline";
$fuse-authentication-title-font: 700 24px/28px "Roboto", sans-serif;
$fuse-authentication-title-letter-spacing: "0.25px";

$fuse-authentication-button-width: 'auto';
$fuse-authentication-button-border-radius: '1.5em';
$fuse-authentication-button-margin: '0';
$fuse-authentication-button-padding: '14px 16px 11px 16px';
$fuse-authentication-button-radius: '1.5em';
$fuse-authentication-button-background: map-get($mat-white, 500);
$fuse-authentication-button-color: $default-font-color;
$fuse-authentication-button-font: 700 14px/16px "Roboto", sans-serif;
$fuse-authentication-button-text-transform: 'uppercase';

// login-form
$fuse-authentication-login-width: 483px;
$fuse-authentication-logo-width: 100%;
$fuse-authentication-logo-margin: '0';
$fuse-authentication-title-margin: '60px 0 40px 0';

// ========== CONTEST ==========

$contest--background-color: rgba(232, 237, 246, 1);
$contest--font-color: rgba(58, 67, 84, 1);
$contest--primary-color: $primary-c;
$contest--primary-accent-color: $secondary-c;
$contest--secondary-accent-color: rgba(159, 227, 247, 1);
$contest--tertiary-accent-color: rgba(255, 78, 188, 1);
$contest__content--background-image: linear-gradient(180deg, rgba(15, 28, 61, 0.9) 65%,
                                     rgba(25, 35, 111, 0.9) 100%),
                                     url(/assets/amazon_python/images/backgrounds/contest-detail-header-background.jpg);
$contest__generic-element--background-color: rgba(25, 36, 75, 1);        
$contest__generic-element--background-color--transparent: rgba(16, 26, 67, 0.72);
$contest__generic-element--box-shadow: 0 0 20px 1px rgba(159, 227, 247, 0.4),
                                       inset 0 0 20px 1px rgba(159, 227, 247, 0.4);
$contest__generic-element--box-shadow-2: 0 0 20px 1px rgba(255, 78, 188, 0.4),
                                         inset 0 0 20px 1px rgba(255, 78, 188, 0.4);
$contest__generic-element--text-shadow: 0px 0px 3px $default-font-contrast-color,
                                        0px 5px 25px rgba(14, 15, 27, 1);
$contest__wrapper--box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.25);
$contest__wrapper--max-width: 2000px;

// CONTEST-CARD
$contest-card__content--background-image: linear-gradient(180deg, rgba(14, 15, 27, 0.4) 0%,
                                          rgba(14, 15, 27, 0.8) 86.86%),
                                          url(/assets/amazon_python/images/backgrounds/contest-card-background.jpg);
$contest-card__footer--background-color: $black;

// CONTEST-DETAIL
$contest-detail__progress-bar--color: rgba(10, 180, 182, 1);
$contest-detail__progress-bar--box-shadow: 0px 0px 10px 0px $contest-detail__progress-bar--color;

// CONTEST-HEADER
$contest-header--background-image: linear-gradient(180deg, rgba(15, 28, 61, 0.8) 20%,
                                   rgba(25, 35, 111, 0.9) 100%),
                                   url(/assets/amazon_python/images/backgrounds/contest-detail-header-background.jpg);

// CONTEST-RANKING
$contest-ranking__table--box-shadow: 0 0 20px 1px rgba(251, 172, 53, 0.4);
$contest-ranking__table-cell--background-color: rgba(35, 37, 51, 1);
$contest-ranking__table-cell--background-color--selected: rgba(12, 22, 50, 1);

// CONTEST-TABS
$contest-tabs__link--background-color: $secondary-c;
$contest-tabs__link--background-color--active: $primary-c;
$contest-tabs__link--border-color--active: $secondary-c;

// ========== GROUPS MANAGEMENT ==========

$groups-inline__button-add__disabled--color: rgba(0, 0, 0, 0.26);
$groups-inline__button-add__disabled--background-color: rgba(0, 0, 0, 0.12);