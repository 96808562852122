

@mixin rewards-dialog {
    // Variables
    $reward-dialog-padding-top: 6rem;
    $reward-dialog-padding-bottom: 4rem;
    $reward-dialog-padding-inline: 4rem;
    $reward-dialog-thumbnail-size: set-fluid-scale(100px, 200px);
    // Style
    display: contents;

    .rewards-dialog {
        position: relative;
        width: min(70rem, 80vw);
        min-width: min-content;
        border-radius: 20px;
        box-shadow: 20px 20px 0px 0px $secondary-c;

        &::before { // Partial border
            content: '';
            position: absolute;
            inset: -2px 50% 50% -2px;
            border-top: 4px solid $secondary-c;
            border-left: 4px solid $secondary-c;
            border-top-left-radius: inherit;
            pointer-events: none;
        }

        &::after { // Partial border bottom end
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 2.5rem;
            height: auto;
            aspect-ratio: 1;
            background-color: $secondary-c;
            border-radius: 50%;
            transform: translate(-50%, calc(-50% - ($reward-dialog-thumbnail-size / 2)));
        }

        .rewards-dialog__header {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 50%;
            padding: unset; // Unset user agent style
            background-color: unset; // Unset Material style
            transform: translate(-50%, -50%);

            .rewards-dialog__title {
                position: relative;
                min-width: max-content;
                margin: unset; // Unset amazon_python theme generic dialog style
                padding: 1rem 6rem;
                background-color: $primary-c;
                border-radius: 40px;
                box-shadow: 8px 8px 0px 0px $secondary-c;
                font-size: set-fluid-scale(26px, 30px);
                font-style: italic;
                font-weight: 700;
                text-transform: uppercase;

                &::before { // Header decoration
                    content: image('backgrounds/reward-dialog-header.svg');
                    position: absolute;
                    z-index: -1;
                    top: 50%;
                    transform: translate(45%, calc(-50% + 2px));
                }
            }
        }

        .rewards-dialog__content {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            min-height: calc($reward-dialog-padding-top + $reward-dialog-thumbnail-size + $reward-dialog-padding-bottom);
            padding: $reward-dialog-padding-top $reward-dialog-padding-inline $reward-dialog-padding-bottom calc(($reward-dialog-thumbnail-size / 2) + $reward-dialog-padding-inline);
            background-image: image('backgrounds/reward-dialog-content.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            color: $white;
            border-radius: inherit;

            &::before { // Thumbnail
                content: '';
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 0;
                width: $reward-dialog-thumbnail-size;
                height: auto;
                aspect-ratio: 1;
                padding: 1.5rem;
                background-color: $primary-c;
                background-image: image('thumbs/reward-dialog.png');
                background-size: auto 110%;
                background-position: center;
                background-repeat: no-repeat;
                border: 4px solid $secondary-c;
                border-radius: inherit;
                box-shadow: 5px 5px 20px rgba($black, 0.5);
                transform: translate(-50%, -50%);
                pointer-events: none;
            }

            .rewards-dialog__message {
                margin: unset; // Unset user agent style
                padding: unset; // Unset default theme style
                font-size: set-fluid-scale(16px, 20px);
                font-weight: 500;
            }

            .rewards-dialog__coins {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 3rem;
                min-width: max-content;
                padding: 1rem 3rem;
                background-color: $white;
                color: $primary-c;
                border-radius: 38px;
                box-shadow: 0px 0px 18px -10px rgba($black, 0.5);
                font-size: set-fluid-scale(28px, 32px);
                font-style: italic;
                font-weight: 900;

                .rewards-dialog__coin-name {}

                .rewards-dialog__number-of-coins {
                    padding: 0 4rem 0 1.5rem;
                    background-color: $secondary-c;
                    color: $white;
                    border: 1px solid rgba($black, 0.04);
                    border-radius: 29px;
                }

                .rewards-dialog__coin-icon {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    width: auto; // Override amazon_python theme generic icon style
                    height: auto; // Override amazon_python theme generic icon style
                    aspect-ratio: 1;
                }
            }

            .rewards-dialog__achievement-list {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
                justify-items: center;
                gap: 2rem;
                margin: 3rem 0 0; // Override browser default list style
                padding: unset; // Disable browser default list style
                list-style: none; // Disable browser default list style

                .rewards-dialog__achievement-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 1rem;
                    min-width: max-content;

                    .rewards-dialog__achievement-image {
                        width: set-fluid-scale(80px, 100px);
                        height: auto;
                        aspect-ratio: 1;
                        margin: unset; // Unset default theme style
                        padding: 1rem;
                        object-fit: contain;
                        background-color: $white;
                        border-radius: 6px;
                    }

                    .rewards-dialog__achievement-name {
                        font-size: set-fluid-scale(14px, 18px);
                        font-weight: 500;
                        text-align: center;
                    }
                }
            }
        }

        .rewards-dialog__actions {
            position: absolute;
            right: 0;
            bottom: 0;
            flex-wrap: nowrap;
            justify-content: flex-end;
            align-items: stretch;
            min-width: max-content;
            padding: unset; // Unset amazon_python theme generic dialog style
            background-color: transparent;
            transform: translate(3rem, 66%);

            .rewards-dialog__exit-button {
                @include button(
                    $type: quaternary,
                    $button-bordered: true,
                );
            }

            .rewards-dialog__exit-button,
            .rewards-dialog__next-button {
                gap: 2rem;
                padding: 0.5rem 3rem;
                border-radius: 44px;
                font-size: set-fluid-scale(20px, 24px);

                mat-icon.mat-icon { // Increase specificty
                    width: 3.5rem;
                    height: auto;
                    aspect-ratio: 1;
                }
            }
        }
    }
}


.rewards-dialog-wrapper {

    .mat-mdc-dialog-surface { 
        overflow: visible; // Override amazon_python theme generic dialog style
        background-color: transparent; // Override amazon_python theme generic dialog style
        box-shadow: unset; // Unset Material style

        rewards-dialog {
            @include rewards-dialog;
        }
    }
}