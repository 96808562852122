mat-tooltip-component {

    .mdc-tooltip {

        .mdc-tooltip__surface {
            padding: 0.5rem 1rem;
            color: $white;
            background-color: $primary-c;
            border: 2px solid $secondary-c;
            border-radius: 8px;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
            font-size: 1.6rem;
        }
    }
}