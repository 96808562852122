// --------------------------------------------------
// ABSTRACTS
// --------------------------------------------------

/**
 * The abstracts/ folder gathers all Sass tools and helpers used across the project. Every global variable, function, mixin and placeholder should be put in here. The rule of thumb for this folder is that it should not output a single line of CSS when compiled on its own. These are nothing but Sass helpers.
*/

@import "abstracts/layouts";

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------

/**
 * For smaller components, there is the components/ folder. While layout/ is macro (defining the global wireframe), components/ is more focused on widgets. It contains all kind of specific modules like a slider, a loader, a widget, and basically anything along those lines. There are usually a lot of files in components/ since the whole site/application should be mostly composed of tiny modules.
*/

@import "components/achievement";
@import "components/beginning-pop-up";
@import "components/dialog";
@import "components/my-city";
@import "components/my-profile";
@import "components/my-ranking";
@import "components/open-badges-list";
@import "components/reward-items";
@import "components/welcome";