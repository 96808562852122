.contest-timer__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 20px;
    border-left: 3px solid $contest--secondary-accent-color;
    border-right: 3px solid $contest--secondary-accent-color;
    border-bottom: 3px solid $contest--secondary-accent-color;
    border-radius: 10px;
    box-shadow: $contest__generic-element--box-shadow;
    text-align: center;
    text-transform: uppercase;
    text-shadow: $contest__generic-element--text-shadow;

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0;
        width: calc(50% - 18px);
        height: 100%;
        border-top: 3px solid $contest--secondary-accent-color;
    }

    &:before {
        left: -3px;
        border-top-left-radius: inherit;
    }

    &:after {
        right: -3px;
        border-top-right-radius: inherit;
    }

    mat-icon {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 32px;
        color: $contest--secondary-accent-color;
        background: inherit;
    }

    &.icon-left {

        &:before {
            width: calc(10% - 18px);
        }

        &:after {
            width: calc(90% - 18px);
        }
        
        mat-icon {
            left: 10%;
        }
    }

    &.icon-right {
        
        &:before {
            width: calc(90% - 18px);
        }

        &:after {
            width: calc(10% - 18px);
        }

        mat-icon {
            left: 90%;
        }
    }
  
    .contest-timer__header {
        font-size: 1.2rem;
        font-weight: 700;
    }
  
    .contest-timer__content {
        font-size: 4.2rem;
        font-weight: 900;
    }
  
    .contest-timer__footer {
        display: flex;
        justify-content: center;
        gap: 10px;
        font-size: 1.2rem;
    }
}