@use '@angular/material' as mat;

// --------------------------------------------------
// VARIABLES
// --------------------------------------------------

$mat-form-field__mat-error--background-color: #F5E2E2 !default;

// --------------------------------------------------
// PLACEHOLDERS
// --------------------------------------------------

// ----- TOP LABEL

%mat-form-field-label-on-top { // TODO should be replace by simple material field settings :/
    .mat-mdc-form-field {
        max-width: 100%;
        //padding-top: $form-field-label-height;
        margin-bottom: $form-field-margin-bottom;

        .mat-mdc-input-element {
            font: #{$form-field-input-font};
            color: rgba($form-field-input-label-color, 1);
        }

        .mat-mdc-select-value {
            color: rgba($form-field-input-label-color, 1);
        }

        // voir theme $input: mat-typography-level
        //&.mat-mdc-form-field-can-float
        //&.mat-mdc-form-field-should-float
        //&.mat-focused

        &.mat-mdc-form-field-should-float,
        &.mat-mdc-form-field-can-float,
        &.mat-form-field-appearance-fill .mat-mdc-form-field-wrapper {
            padding-bottom: 0.25em;
            font-size: $default-font-size;

            .mat-mdc-form-field-flex {
                margin-top: $form-field-label-height;
                border-top: $form-field-flex-border-top;
                border-bottom: $form-field-flex-border-bottom;
                border-left: $form-field-flex-border-left;
                border-right: $form-field-flex-border-right;

                .mat-mdc-form-field-infix {
                    padding: #{$form-field-infix-padding};
                    line-height: #{$form-field-infix-line-height};
                    border-top: 0 solid transparent;

                    .mat-mdc-input-element {
                        color: $form-field-input-color;

                        &textarea {
                            padding: 18px 12px 10px 10px;
                        }
                    }

                    .mat-mdc-form-field-label-wrapper {
                        padding-bottom: 0.5em;
                    }
                    .mat-mdc-form-field-label { /*voir au premiere niveau*/
                        font: #{$form-field-label-font};
                        color: rgba($form-field-input-label-color, 1);
                        text-transform: #{$form-field-label-text-transform};
                        font-weight: 700;
                    }
                }
            }

            .mat-mdc-form-field-ripple, .mat-focused .mat-mdc-form-field-ripple {
                background-color: $form-field-ripple-background;
            }

            .mat-mdc-form-field-underline::before {
                background-color: $form-field-underline-background-color;
                bottom: $form-field-underline-bottom;
            }

            .mat-mdc-form-field-underline::before,
            .mat-mdc-form-field-ripple,
            .mat-focused .mat-mdc-form-field-ripple {
                transform: scaleY(1);
                background-color: $form-field-underline-background-color;
            }
        }

        /*
        * **************** label ****************
         */
        .mat-mdc-form-field-label-wrapper {
            top: -#{$form-field-label-height};
            padding-top: $form-field-label-height;
        }

        //.mat-mdc-form-field
        &.mat-mdc-form-field-can-float,
        &.mat-mdc-form-field-should-float,
        &.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float {
            // avec ou non le style special donc selecteur lambda
            &.mat-form-field-appearance-fill, .mat-mdc-form-field-wrapper {
                .mat-mdc-form-field-flex, //selecteur lambda pour arriver aux attibuts
                .mat-mdc-input-server:focus {
                    .mat-mdc-form-field-label {
                        top: #{$form-field-label-top};
                        width: 100%;
                        transform: translateY(0) scale(1) perspective(100px) translateZ(0.00103px);
                        -webkit-transform: translateY(0) scale(1) perspective(100px) translateZ(0.00103px);
                        -ms-transform: translateY(0) scale(1);
                    }

                    /* Change Autocomplete styles in Chrome*/
                    //.mat-mdc-form-field-autofill-control=input
                    .mat-mdc-form-field-autofill-control:-webkit-autofill,
                    .mat-mdc-form-field-autofill-control:-webkit-autofill:hover,
                    .mat-mdc-form-field-autofill-control:-webkit-autofill:focus {
                        .mat-mdc-form-field-label {
                            top: 0;
                            width: 100%;
                            transform: translateY(0) scale(1) perspective(100px) translateZ(0.00103px);
                            -webkit-transform: translateY(0) scale(1) perspective(100px) translateZ(0.00103px);
                            -ms-transform: translateY(0) scale(1);
                        }
                    }
                }
            }
        }

        &.mat-focused .mat-mdc-form-field-label {
            color: $form-field-input-label-color-focused;
        }

        /* * **************** fin label **************** */

        &.field-full-height {
            height: 100%;
            margin: 0px;
            min-height: 100%;

            .mat-mdc-form-field-wrapper {
                height: 100%;

                .mat-mdc-form-field-flex {
                    height: calc(100% - 1.8em);

                    .mat-mdc-form-field-infix {
                        height: 100%;

                        textarea {
                            height: 100%;
                        }
                    }
                }
            }
        }


    }

    .mat-mdc-select-panel {

        .mat-option {

            .mat-pseudo-checkbox {
                background-color: $mat-form-field-checkbox-background-color;
                border-radius: $mat-form-field-checkbox-border-radius;
                color: $mat-form-field-checkbox-color; // cadre

                &.mat-pseudo-checkbox-checked, &.mat-pseudo-checkbox-indeterminate {
                    background-color: $mat-form-field-checkbox-background-color-checked;
                    color: $mat-form-field-checkbox-color-checked; // cadre
                }
            }

            .mat-option-text {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    height: 1px;
                    width: 100%;
                    background-color: black;
                    position: absolute;
                    bottom: 9px;
                }
            }

            &.mat-mdc-selected:not(.mat-option-disabled) {
                color: $mat-form-field-option-color;
            }
        }

    }

}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

// ----- MAT-SELECT-PANEL

.mat-mdc-select-panel {

    .mat-option {
        color: $default-font-color;
    }
}

div.mat-mdc-select-panel.mat-primary .mat-mdc-select-content,
div.mat-mdc-select-panel.mat-primary .mat-mdc-select-panel-done-animating {
    background-color: $form-field-select-panel-background-color;
    color: white;
}

// ----- MAT-SELECT-ARROW

.mat-mdc-form-field {
    .mat-mdc-form-field-flex {
        background-color: $form-field-flex-background-color;
    }

    .mat-mdc-select-arrow {
        width: 3rem;
        height: auto;
        aspect-ratio: 1;
        background-color: $accent-c;
        border: none;
        mask-image: icon('keyboard_arrow_up.svg');
    }

    .mat-mdc-select.mat-focused .mat-mdc-select-arrow {
        background-color: mat.get-color-from-palette($accent);
    }

    .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
        background-color: mat.get-color-from-palette($warn);
    }

    .mat-mdc-form-field-label {
        font-family: #{$font-family};
    }

    .mat-mdc-slide-toggle-checked .mdc-switch__handle,
    .mat-mdc-slide-toggle-checked .mdc-switch__track {
        background-color: rgb(104, 33, 122);
    }

    .mat-mdc-chip {
        background-color: $primary-c;
        color: $default-font-contrast-color;

        .mat-mdc-chip-remove.mat-icon {
            width: 22px;
            height: 22px;

            svg {
                transform: scale(1);
                fill: rgba(255, 255, 255, 0.25);
                stroke: none;

                path {
                    fill: none;

                    &.svg-background {
                        stroke: none;
                    }
                }
            }
        }
    }

    .mat-mdc-chip .mat-icon {
        background-color: transparent;
        opacity: 1;
        color: white;
    }

}

// ----- MAT-FORM-FIELD-OUTLINE

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
    background-color: white !important;
    opacity: 1 !important;
    border-radius: 4px;
}
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-start,
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-end {
    border-color: rgba(0, 0, 0, 0.05) !important;
}

// ----- MAT-SLIDE-TOGGLE

mat-slide-toggle {
    display: flex!important;
    min-height: 46px!important;
    align-items: center!important;
    width: 50px!important; // to avoid click on entire row

    .mdc-switch__track {
        height: 32px !important;
        width: 50px !important;
        border-radius: 20px !important;
    }

    .mdc-switch__handle-track {
        transform: translate3d(5px, 0, 0) !important;

        .mdc-switch__handle {
            width: 24px !important;
            height: 24px !important;
            margin-top: 7px!important;
            background: #FFFFFF !important;
        }
    }
}

// ----- MAT-ERROR

.mat-mdc-form-field {

    .mat-mdc-form-field-wrapper {

        .mat-mdc-form-field-subscript-wrapper {
            display: contents !important; // Hide this Material element but not its children

            .mat-error {
                margin-top: 0.6rem;
                padding: 0.6rem 1.2rem;
                background-color: $mat-form-field__mat-error--background-color;
                border-radius: 6px;
                font-size: 1.4rem;
                font-weight: 500;
            }
        }
    }
}

#login, #register, #forgot-password-form {
    .mat-error {
        margin-top: 0.6rem;
        padding: 0.6rem 1.2rem;
        background-color: $mat-form-field__mat-error--background-color;
        border-radius: 6px;
        font-size: 1.4rem;
        font-weight: 500;
    }
}