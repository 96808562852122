.achievement {
    flex: auto;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 1.5rem 3rem;
    color: $primary-c;

    .achievement__header {
        display: flex;
        flex-direction: column;
        gap: 3rem;

        .achievement__root-filter {}

        .achievement__tab-nav {}
    }

    .achievement__main {
        flex: auto;
        display: flex;
        flex-direction: column;
    }
}